import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Booking, BookingArticle} from "@knust/api-interfaces";

export const loadBooking = createAction('[Booking] Load Booking');

export const loadBookingSuccess = createAction(
  '[Booking/API] Load Booking Success',
  props<{ bookings: Booking[], total: number }>()
);

export const loadBookingFailure = createAction(
  '[Booking/API] Load Booking Failure',
  props<{ error: any }>()
);

export const loadBookingArticles = createAction('[Booking] Load BookingArticles');

export const loadBookingArticlesSuccess = createAction(
  '[Booking/API] Load Booking Articles Success',
  props<{ bookingsArticles: BookingArticle[], total: number }>()
);

export const loadBookingArticlesFailure = createAction(
  '[Booking/API] Load Booking Articles Failure',
  props<{ error: any }>()
);

export const loadBookingReports = createAction(
  '[Booking] Load Booking Reports',
  (payload: { download: boolean } = { download: false }) => payload
);

export const loadBookingReportsSuccess = createAction(
  '[Booking/API] Load Booking Reports Success',
  props<{ bookingsReports: Booking[], total: number }>()
);

export const loadBookingReportsFailure = createAction(
  '[Booking/API] Load Booking Reports Failure',
  props<{ error: any }>()
);

export const downloadBookingReport = createAction(
  '[Booking/API] Download Booking Reports',
  props<{ url: string }>()
)

export const changeCompletedFilter = createAction(
  '[Booking/API] Set Completed Filter',
)

export const changeReportsCompletedFilter = createAction(
  '[Booking/API] Set Reports Completed Filter',
)

export const setReportsUserFilter = createAction(
  '[Booking/API] Set Reports User Filter',
  props<{ userId: number }>()
)

export const setReportsArticleFilter = createAction(
  '[Booking/API] Set Reports Article Filter',
  props<{ articleId: number }>()
)

export const setReportsVendorFilter = createAction(
  '[Booking/API] Set Reports Vendor Filter',
  props<{ vendorId: number }>()
)

export const setReportsDateFilter = createAction(
  '[Booking/API] Set Reports Date Filter',
  props<{ dateStart: Date | undefined, dateEnd: Date | undefined }>()
)

export const resetReportFilters = createAction(
  '[Booking/API] Reset Report Filters',
);

export const setSelectedBooking = createAction(
  '[Booking] Set selected Booking',
  props<{ id: number }>()
);

export const unsetSelectedBooking = createAction(
  '[Booking] Unset selected Booking'
);

export const loadBookingDetailUnneeded = createAction(
  '[Booking/API] Load Booking Detail Unneeded'
);

export const loadBookingDetailSuccess = createAction(
  '[Booking/API] Load Booking Detail Success',
  props<{ bookings: Booking }>()
);

export const loadBookingDetailFailure = createAction(
  '[Booking/API] Load Booking Detail Failure',
  props<{ error: any }>()
);

export const setBookingPage = createAction(
  '[Booking/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setBookingTerm = createAction(
  '[Booking/API] Set Term',
  props<{ term: string }>()
);

export const setBookingSort = createAction(
  '[Booking/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const setBookingArticlesPage = createAction(
  '[Booking/API] Set Articles Page',
  props<{ page: PageEvent }>()
);

export const setBookingArticlesTerm = createAction(
  '[Booking/API] Set Articles Term',
  props<{ term: string }>()
);

export const setBookingArticlesSort = createAction(
  '[Booking/API] Set Articles Sort',
  props<{ sort: Sort | null }>()
);

export const setBookingReportsPage = createAction(
  '[Booking/API] Set Reports Page',
  props<{ page: PageEvent }>()
);

export const setBookingReportsTerm = createAction(
  '[Booking/API] Set Reports Term',
  props<{ term: string }>()
);

export const setBookingReportsSort = createAction(
  '[Booking/API] Set Reports Sort',
  props<{ sort: Sort | null }>()
);

export const clearBooking = createAction(
  '[Booking/API] Clear'
);

export const createBooking = createAction(
  '[Booking/API] Create Booking',
  props<{ bookings: Omit<Booking, 'id'> }>()
);

export const updateBooking = createAction(
  '[Booking/API] Update Booking',
  props<{ bookings: Booking | Omit<Booking, 'id'> }>()
);

export const saveBookingSuccess = createAction(
  '[Booking/API] Save Booking Success',
  props<{ bookings: Booking, insert: boolean }>()
);

export const saveBookingFailure = createAction(
  '[Booking/API] Save Booking Failure',
  props<{ error: any }>()
);

export const saveBookingNote = createAction(
  '[Booking/API] Save Booking note',
  props<{ note: string | null }>()
)

export const saveBookingNoteSuccess = createAction(
  '[Booking/API] Save Booking note Success',
  props<{ note: string | null }>()
)

export const saveBookingNoteFailure = createAction(
  '[Booking/API] Save Booking note Failure',
  props<{ error: any }>()
);

export const deleteBooking = createAction(
  '[Booking/API] Delete Booking',
  props<{ bookings: Booking }>()
);

export const deleteBookingSuccess = createAction(
  '[Booking/API] Delete Booking Success',
  props<{ id: number | string }>()
);

export const deleteBookingFailure = createAction(
  '[Booking/API] Delete Booking Failure',
  props<{ error: any }>()
);

export const addBookingArticle = createAction(
  '[Booking/API] Create BookingArticle',
  props<{ bookingsArticle: BookingArticle | Partial<BookingArticle> }>()
)

export const addBookingArticleSuccess = createAction(
  '[Booking/API] Add BookingArticle Success',
  props<{ bookingsArticle: BookingArticle }>()
);

export const addBookingArticleFailure = createAction(
  '[Booking/API] Add BookingArticle Failure',
  props<{ error: any }>()
);

export const deleteBookingArticle = createAction(
  '[Booking/API] Delete BookingArticle',
  props<{ bookingsArticle: BookingArticle }>()
)

export const deleteBookingArticleSuccess = createAction(
  '[Booking/API] Delete BookingArticle Success',
  props<{ bookingsArticle: BookingArticle }>()
);

export const deleteBookingArticleFailure = createAction(
  '[Booking/API] Delete BookingArticle Failure',
  props<{ error: any }>()
);

export const updateBookingArticles = createAction(
  '[Booking/API] Update BookingArticles',
  props<{ bookingsArticles: BookingArticle[], bookingId: number }>()
);

export const closeBooking = createAction(
  '[Booking/API] Close Booking',
  props<{ bookingId: number, articles: { storageLocationArticleId: number, amount: number, note?: string, receivingStorageLocation?: number }[] }>()
);

export const closeBookingSuccess = createAction(
  '[Booking/API] Close Booking Success',
  props<{ bookingId: number }>()
);
