<div class="form-box">
  <knust-data-table (entityRowClick)="openVendorArticleDialog($event)"
                    (addEntityClick)="openVendorArticleDialog()"
                    (deleteEntityClick)="deleteVendorArticle($event)"
                    newButtonText="Neue Lieferanten-Artikel-Beziehung erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>
