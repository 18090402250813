import {Component, OnInit} from "@angular/core";
import {NotificationService} from "../../utility/services/notification.service";

@Component({
  selector: 'knust-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {

  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {}
}
