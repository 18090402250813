import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Article} from "@knust/api-interfaces";

export const loadArticle = createAction('[Article] Load Article');

export const loadArticleSuccess = createAction(
  '[Article/API] Load Article Success',
  props<{ article: Article[], total: number }>()
);

export const loadArticleFailure = createAction(
  '[Article/API] Load Article Failure',
  props<{ error: any }>()
);

export const setCategoryFilter = createAction(
  '[Article/API] Set Article Category Filter',
  props<{ categoryId: number }>()
)

export const setSelectedArticle = createAction(
  '[Article] Set selected Article',
  props<{ id: number }>()
);

export const unsetSelectedArticle = createAction(
  '[Article] Unset selected Article',
);

export const loadArticleDetailUnneeded = createAction(
  '[Article/API] Load Article Detail Unneeded'
);

export const loadArticleDetailSuccess = createAction(
  '[Article/API] Load Article Detail Success',
  props<{ article: Article }>()
);

export const loadArticleDetailFailure = createAction(
  '[Article/API] Load Article Detail Failure',
  props<{ error: any }>()
);

export const setArticlePage = createAction(
  '[Article/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setArticleTerm = createAction(
  '[Article/API] Set Term',
  props<{ term: string }>()
);

export const setArticleSort = createAction(
  '[Article/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearArticle = createAction(
  '[Article/API] Clear'
);

export const createArticle = createAction(
  '[Article/API] Create Article',
  props<{ article: Omit<Article, 'id'> }>()
);

export const updateArticle = createAction(
  '[Article/API] Update Article',
  props<{ article: Article }>()
);

export const saveArticleSuccess = createAction(
  '[Article/API] Save Article Success',
  props<{ article: Article, insert: boolean }>()
);

export const saveArticleFailure = createAction(
  '[Article/API] Save Article Failure',
  props<{ error: any }>()
);

export const deleteArticle = createAction(
  '[Article/API] Delete Article'
);

export const deleteArticleSuccess = createAction(
  '[Article/API] Delete Article Success',
  props<{ id: number | string }>()
);

export const deleteArticleFailure = createAction(
  '[Article/API] Delete Article Failure',
  props<{ error: any }>()
);

export const restoreArticle = createAction(
  '[Article/API] Restore Article',
  props<{ element: Article }>()
);

export const restoreArticleSuccess = createAction(
  '[Article/API] Restore Article Success',
  props<{ article: Article }>()
);

export const restoreArticleFailure = createAction(
  '[Article/API] Restore Article Failure',
  props<{ error: any }>()
);
