import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as StorageLocationArticleActions from "./storage-location-article.actions";
import {
  getStorageLocationArticleQuery,
  getEmptyFilter,
  getSelected,
  getFilterId, getBookingFilter
} from "./storage-location-article.selectors";
import {StorageLocationArticlePartialState} from "./storage-location-article.reducer";
import {NEW_ENTITY} from "../../../utility/constants/new-entity.constants";
import {onNavigation} from "../../../+state/on-navigation.operator";
import {StorageLocationArticlesComponent} from "../storage-locations-article.component";
import {StorageLocationArticleService} from "../../../utility/services/storage-location-article.service";

@Injectable()
export class StorageLocationArticleEffects {
  storageLocationArticleIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(StorageLocationArticlesComponent),
      map( () => StorageLocationArticleActions.loadStorageLocationArticles())
    )
  );

  storageLocationArticleView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(StorageLocationArticlesComponent),
      filter( action => action.payload.routerState.params['storageLocationArticleId'] !== NEW_ENTITY),
      map( (action) => {
        const storageLocationArticleId = parseInt(action.payload.routerState.params['storageLocationArticleId'], 10);

        if (!storageLocationArticleId) {
          throw new Error('storageLocationArticle id is missing');
        }

        return StorageLocationArticleActions.setSelectedStorageLocationArticle({ id: storageLocationArticleId });
      })
    )
  );

  selectStorageLocationArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StorageLocationArticleActions.setSelectedStorageLocationArticle),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.storageLocationArticleService.loadStorageLocationArticles().pipe(
              map(res => StorageLocationArticleActions.loadStorageLocationArticleDetailSuccess({ storageLocationArticle: res.items[0] }))
            );
          }

          return of(StorageLocationArticleActions.loadStorageLocationArticleDetailUnneeded());
        }),
        catchError((error) => {
          return of(StorageLocationArticleActions.loadStorageLocationArticlesFailure({ error }));
        })
      )
  );

  loadStorageLocationArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StorageLocationArticleActions.loadStorageLocationArticles),
        concatLatestFrom(() => [
          this.store.select(getStorageLocationArticleQuery),
          this.store.select(getEmptyFilter),
          this.store.select(getFilterId),
          this.store.select(getBookingFilter),
        ]),
        switchMap(([action, query, emptyFilter, filterId, bookingFilter]) => {
          let completeQuery = query;
          if (action.filterId || filterId) {
            completeQuery += '&storageLocation=' + (action.filterId ?? filterId);
          }
          if ((action.bookingId && action.completed) || (bookingFilter.bookingId && bookingFilter.completed)) {
            completeQuery += '&booked=' + action.bookingId ?? bookingFilter.bookingId;
          }
          completeQuery += '&show-empty=' + (emptyFilter ? 1 : 0);

          console.log('Loading Storage Location Articles - Query:', action.filterId, filterId, completeQuery);

          return this.storageLocationArticleService
            .loadStorageLocationArticles(completeQuery)
            .pipe(
              tap(() => console.log('Store Load Complete')),
              map(res => StorageLocationArticleActions.loadStorageLocationArticlesSuccess({ storageLocationArticle: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
            console.log('ERROR BEIM LADEN:', error);
          return of(StorageLocationArticleActions.loadStorageLocationArticlesFailure({ error }));
        })
      )
  );

  setStorageLocationArticleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageLocationArticleActions.setStorageLocationArticlePage,
        StorageLocationArticleActions.setStorageLocationArticleTerm,
        StorageLocationArticleActions.setStorageLocationArticleSort,
        StorageLocationArticleActions.changeEmptyFilter,
      ),
      map(() => StorageLocationArticleActions.loadStorageLocationArticles())
    )
  );

  createStorageLocationArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationArticleActions.createStorageLocationArticle),
      switchMap((action) => {
        return this.storageLocationArticleService.saveLocation(action.storageLocationArticle).pipe(
          map(created =>
            StorageLocationArticleActions.saveStorageLocationArticleSuccess( { storageLocationArticle: created, insert: true })
          ),
          catchError(error => {
            return of(StorageLocationArticleActions.saveStorageLocationArticleFailure({ error }));
          })
        )
      })
    )
  );

  updateStorageLocationArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationArticleActions.updateStorageLocationArticle),
      switchMap((action) => {
        return this.storageLocationArticleService.saveLocation(action.storageLocationArticle).pipe(
          map(updated =>
            StorageLocationArticleActions.saveStorageLocationArticleSuccess( { storageLocationArticle: updated, insert: false })
          ),
          catchError(error => {
            return of(StorageLocationArticleActions.saveStorageLocationArticleFailure({ error }));
          })
        )
      })
    )
  );

  deleteStorageLocationArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationArticleActions.deleteStorageLocationArticle),
      switchMap((action) => {
        return this.storageLocationArticleService.deleteLocation(action.storageLocationArticle).pipe(
          map(() =>
            StorageLocationArticleActions.deleteStorageLocationArticleSuccess( { id: action.storageLocationArticle.id ?? 0 })
          ),
          catchError(error => {
            return of(StorageLocationArticleActions.deleteStorageLocationArticleFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private storageLocationArticleService: StorageLocationArticleService,
              private store: Store<StorageLocationArticlePartialState>) {
  }
}
