import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {DeliveryRoutingModule} from "./delivery-routing.module";
import {DeliveryIndexComponent} from "./delivery-index/delivery-index.component";
import {DeliveryDetailComponent} from "./delivery-detail/delivery-detail.component";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {StoreModule} from "@ngrx/store";
import * as fromDelivery from "./+state/delivery.reducer";
import {EffectsModule} from "@ngrx/effects";
import {DeliveryEffects} from "./+state/delivery.effects";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {EditDeliveryDialogComponent} from "./edit-delivery-dialog/edit-delivery-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSelectModule} from "@angular/material/select";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatMenuModule} from "@angular/material/menu";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  declarations: [
    DeliveryIndexComponent,
    DeliveryDetailComponent,
    EditDeliveryDialogComponent,
  ],
    imports: [
        CommonModule,
        DeliveryRoutingModule,
        DataTableModule,
        StoreModule.forFeature(
            fromDelivery.DELIVERY_FEATURE_KEY,
            fromDelivery.reducer
        ),
        EffectsModule.forFeature([DeliveryEffects]),
        MatTableModule,
        MatSortModule,
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatSelectModule,
        MatToolbarModule,
        MatMenuModule,
        MatSlideToggleModule,
        FormsModule,
        NgxMatSelectSearchModule
    ],
})
export class DeliveryModule {}
