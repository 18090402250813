import {Vendor} from "./vendor.dto";
import {Article} from "./article.dto";
import {CoreProperties} from "./core-properties.dto";

export interface VendorArticle extends CoreProperties {
  article: Article | { id: number };
  vendorArticleNumber: string;
  vendor: Vendor;
  packagingUnit: number;
  price: number | string;
  isMainVendorArticle: boolean;
  manufacturerName?: string;
}
