<h1 mat-dialog-title>Neuen Artikel zum Lager hinzufügen</h1>

<mat-dialog-content>
  <form [formGroup]="deliveryArticleForm" (keydown.enter)="save()">
    <mat-form-field appearance="fill" formGroupName="article">
      <mat-label>Artikel</mat-label>
      <mat-select formControlName="id">
        <mat-option>
          <ngx-mat-select-search [formControl]="articleFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let article of filteredArticles | async" [value]="article.id">
          {{article.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>

  <button mat-flat-button color="primary" [disabled]="deliveryArticleForm.invalid" (click)="save()">Speichern</button>
</mat-dialog-actions>
