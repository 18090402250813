import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  changeEmptyFilter,
  createStorageLocationArticle,
  deleteStorageLocationArticle,
  loadStorageLocationArticles, resetStorageLocationArticles, setBookingFilter, setLocationFilter,
  setStorageLocationArticlePage,
  setStorageLocationArticleSort,
  setStorageLocationArticleTerm,
  updateStorageLocationArticle
} from "./+state/storage-location-article.actions";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  Article,
  BookingArticle,
  BookingTypeEnum,
  StorageLocation,
  StorageLocationArticle,
  UserEnum
} from "@knust/api-interfaces";
import {
  getAllStorageLocationArticle,
  getStorageLocationArticlePage,
  getStorageLocationArticleSort,
  getStorageLocationArticleTerm,
  getStorageLocationArticleTotal,
  getEmptyFilter
} from "./+state/storage-location-article.selectors";
import {AuthService} from "../../utility/services/auth.service";
import {BehaviorSubject, combineLatestWith, filter, Observable, take} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {
  AddStorageLocationArticleDialogComponent
} from "./add-storage-location-article-dialog/add-storage-location-article-dialog.component";
import {loadBookingArticles} from "../../bookings/+state/bookings.actions";

@UntilDestroy()
@Component({
  selector: 'knust-storage-location-articles',
  templateUrl: './storage-locations-article.component.html',
  styleUrls: ['./storage-locations-article.component.scss'],
})
export class StorageLocationArticlesComponent implements OnInit, OnDestroy {
  protected readonly BookingTypeEnum = BookingTypeEnum;

  @Input() filterForId!: BehaviorSubject<number | null>;
  @Input() storageLocations!: Observable<StorageLocation[] | null>;
  @Input() bookingType!: Observable<BookingTypeEnum | null>;
  @Input() selectedArticles!: BehaviorSubject<BookingArticle[] | null>;
  @Input() bookingId!: string | number | undefined;
  @Input() completed!: boolean;

  @Output() submitBookingsClick = new EventEmitter<any>();

  commandMap: AbstractIndexCommands<StorageLocationArticle> = {
    getAll: getAllStorageLocationArticle,
    getTotal: getStorageLocationArticleTotal,
    getPage: getStorageLocationArticlePage,
    setPage: setStorageLocationArticlePage,
    getTerm: getStorageLocationArticleTerm,
    setTerm: setStorageLocationArticleTerm,
    getSort: getStorageLocationArticleSort,
    setSort: setStorageLocationArticleSort,
    load: loadStorageLocationArticles,
    createElement: createStorageLocationArticle,
    deleteElement: deleteStorageLocationArticle,
  }

  displayedColumns = ['article.photo', 'article.title', 'article.internalId', 'actualStock', 'reservedStock', 'bookings'];
  tableConfig = [
    {
      name: 'ID',
      refName: 'id'
    },
    {
      name: 'Artikelbild',
      refName: 'article',
      nestedName: 'photo',
      useNestedName: true,
      type: 'image',
      disableSort: true,
    },
    {
      name: 'Artikelname',
      refName: 'article',
      nestedName: 'title',
      useNestedName: true
    },
    {
      name: 'Artikelnummer',
      refName: 'article',
      nestedName: 'internalId',
      useNestedName: true
    },
    {
      name: 'Verfügbar',
      refName: 'actualStock'
    },
    {
      name: 'Reserviert',
      refName: 'reservedStock'
    },
  ];

  showEmpty = false;

  constructor(private store: Store,
              private dialog: MatDialog,
              private authService: AuthService) {
    this.store.select(getEmptyFilter)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(emptyFilter => this.showEmpty = emptyFilter);

    const userType = this.authService.currentUser.value.type;
  }

  ngOnInit() {
    this.filterForId
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(filterId => {
      if (filterId) {
        this.store.dispatch(setBookingFilter({ bookingId: this.bookingId, completed: this.completed }));
        this.store.dispatch(setLocationFilter({ filterId }));

        this.store.dispatch(loadStorageLocationArticles({ filterId, bookingId: this.bookingId, completed: this.completed }));
      }
    });
  }

  ngOnDestroy() {
    this.store.dispatch(resetStorageLocationArticles());
  }

  addStorageLocationArticle() {
    this.dialog.open(
      AddStorageLocationArticleDialogComponent,
      {
        data: this.filterForId.value,
      }
    )
      .afterClosed()
      .pipe(
        combineLatestWith(
          this.filterForId.pipe(
            filter(filterId => !!filterId)
          )
        ),
        take(1)
      )
      .subscribe(([result, filterId]) => {
        if (result && filterId) {
          const storageLocationArticle: any = {
            article: {
              id: result.article.id,
            },
            storageLocation: {
              id: filterId
            },
            actualStock: result.amount
          };

          this.store.dispatch(createStorageLocationArticle({ storageLocationArticle }));
        }
      });
  }
}
