import {ColumnConfig} from "../reusable-components/data-table/column-config";
import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Delivery} from "@knust/api-interfaces";
import {
  getAllDelivery,
  getDeliveryPage,
  getDeliverySort,
  getDeliveryTerm,
  getDeliveryTotal
} from "./+state/delivery.selectors";
import {
  createDelivery,
  deleteDelivery,
  loadDelivery,
  setDeliveryPage,
  setDeliverySort,
  setDeliveryTerm
} from "./+state/delivery.actions";

export const deliveryDisplayedColumns = ['id', 'label', 'createdDate', 'createdByUser', 'updatedDate', 'updatedByUser', 'completed'];
export const deliveryColumnConfig: ColumnConfig[] = [
  {
    name: 'ID',
    refName: 'id',
  },
  {
    name: 'Bezeichnung',
    refName: 'label',
    fallback: 'Nicht gesetzt'
  },
  {
    name: 'Erstellt am',
    refName: 'createdDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Erstellt von',
    refName: 'createdByUser',
    type: 'user'
  },
  {
    name: 'Zuletzt aktualisiert',
    refName: 'updatedDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Aktualisiert von',
    refName: 'updatedByUser',
    type: 'user'
  },
  {
    name: 'Status',
    refName: 'completed',
    type: 'boolean',
    trueText: 'Abgeschlossen',
    falseText: 'Aktiv',
    disableSort: true
  }
];

export const deliveryCommandMap: AbstractIndexCommands<Delivery> = {
  getAll: getAllDelivery,
  getTotal: getDeliveryTotal,
  getPage: getDeliveryPage,
  setPage: setDeliveryPage,
  getTerm: getDeliveryTerm,
  setTerm: setDeliveryTerm,
  getSort: getDeliverySort,
  setSort: setDeliverySort,
  load: loadDelivery,
  createElement: createDelivery,
  deleteElement: deleteDelivery,
};
