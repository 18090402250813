import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";

import * as EmailActions from "./email.actions";
import { EmailTemplate } from '@knust/api-interfaces';

export const EMAIL_FEATURE_KEY = 'email-templates';

export interface State extends EntityState<EmailTemplate> {
  selectedId?: number; // which Email record has been selected
  loaded: boolean; // has the email list been loaded
  error?: string | null; // last known error (if any)
}

export interface EmailPartialState {
  readonly [EMAIL_FEATURE_KEY]: State;
}

export const emailAdapter: EntityAdapter<EmailTemplate> =
  createEntityAdapter<EmailTemplate>();


export const initialState: State = emailAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

const emailReducer = createReducer(
  initialState,
  on(EmailActions.loadEmails, (state) => ({ ...state, loaded: false, error: null })),
  on(EmailActions.loadEmailsSuccess, (state, { email }) =>
    emailAdapter.setAll(email ?? [], { ...state, loaded: true })
  ),
  on(EmailActions.loadEmailsFailure, (state, { error }) => ({ ...state, error })),
  on(EmailActions.setSelectedEmail, (state, { id }) => ({
    ...state,
    selectedId: id ?? null,
  })),
  on(EmailActions.updateEmailSuccess, (state, { email }) =>
    emailAdapter.updateOne(
      { id: email.id, changes: email },
      state
    )
  ),
  on(EmailActions.updateEmailFailure, (state, { error }) => ({ ...state, error}))
  );

export function reducer(state: State | undefined, action: Action) {
  return emailReducer(state, action);
}
