import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {DeliveryIndexComponent} from "./delivery-index/delivery-index.component";
import {DeliveryDetailComponent} from "./delivery-detail/delivery-detail.component";

const routes: Routes = [
  {
    path: '',
    component: DeliveryIndexComponent
  },
  {
    path: ':deliveryId',
    component: DeliveryDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DeliveryRoutingModule { }
