import {DefaultProjectorFn, MemoizedSelector, select, Store} from "@ngrx/store";
import {BehaviorSubject} from "rxjs";
import {MatTableDataSource} from "@angular/material/table";


/**
 * Data source for the Index view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class IndexDataSource<T> extends MatTableDataSource<T> {

  constructor(private store: Store,
              private selector: MemoizedSelector<object, T[], DefaultProjectorFn<T[]>>) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  override connect(): BehaviorSubject<T[]> {
    const subject = new BehaviorSubject<any>(null);

    this.store.pipe(
      select(this.selector)
    ).subscribe(subject);

    return subject;
  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  // <override disconnect(): void {
  // }
}
