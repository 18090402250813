import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {BaseDataComponent} from "./base-data/base-data.component";
import {BaseDataResolver} from "./base-data/base-data.resolver";

const routes: Routes = [
  {
    path: '',
    component: BaseDataComponent,
    resolve: {
      baseData: BaseDataResolver,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BaseDataRoutingModule { }
