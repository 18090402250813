<div class="details-header">
  <div class="header-row">
    <h1>Lagerbuchung</h1>
  </div>

  <a (click)="location.back()" class="back-link">
    <mat-icon>arrow_back</mat-icon>
    Zurück
  </a>
</div>

<ng-container *ngIf="(booking | async) as booking">
  <div class="form-box" [formGroup]="bookingForm">
    <h3>Allgemein</h3>

    <div class="input-columns-container">
      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Buchungstyp</mat-label>
          <mat-select formControlName="bookingType">
            <mat-option *ngFor="let bookingType of bookingTypes" [value]="bookingType.type">
              {{bookingType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" formGroupName="storageLocation">
          <mat-label>Lagerort / Sendendes Lager</mat-label>
          <mat-select formControlName="id">
            <mat-option>
              <ngx-mat-select-search [formControl]="storageLocationFilterCtrl"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let storageLocation of (filteredStorageLocations | async) ?? []" [value]="storageLocation.id">
              {{storageLocation.label}} - {{storageLocation.address.street}}, {{storageLocation.address.zip + ' ' + storageLocation.address.city}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Notiz</mat-label>
          <textarea matInput formControlName="note"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="save-label-row">
      <button mat-flat-button color="primary" (click)="saveBooking()" [disabled]="bookingForm.pristine || bookingForm.invalid">
        Speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>

  <div class="form-box">
    <knust-storage-location-articles [filterForId]="selectedStorageLocation"
                                     [bookingType]="selectedBookingType"
                                     [selectedArticles]="selectedArticles"
                                     [bookingId]="booking?.id ?? undefined"
                                     [completed]="booking.completed ?? false"
                                     (submitBookingsClick)="closeBooking($event)"
                                     [storageLocations]="allStorageLocations">
    </knust-storage-location-articles>
  </div>
</ng-container>
