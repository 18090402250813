<h1>Entnahmen</h1>

<div class="form-box">
  <knust-data-table (entityRowClick)="navigateToTrunk($event)"
                    [disableNewButton]="true"
                    [commandMap]="commandMap"
                    [columnConfig]="tableConfig"
                    [displayedColumns]="displayedColumns">
    <mat-slide-toggle color="primary" [checked]="showCompleted" (toggleChange)="setCompletedFilter()">
      Abgeschlossene Entnahmen anzeigen
    </mat-slide-toggle>
  </knust-data-table>
</div>
