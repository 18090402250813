<div class="details-header">
  <div class="header-row">
    <h1>Wareneingangsbuchung</h1>
  </div>

  <a (click)="location.back()" class="back-link">
    <mat-icon>arrow_back</mat-icon>
    Zurück
  </a>
</div>

<ng-container *ngIf="(delivery | async) as delivery">
  <div class="form-box">
    <h3>Allgemein</h3>

    <div class="input-columns-container">
      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Bezeichnung der Wareneingangsbuchung</mat-label>
          <input matInput placeholder="AS-20220129" [formControl]="labelControl" (keydown.enter)="saveLabel()">
          <mat-hint>Zum Hinzufügen von Artikeln muss eine Bezeichnung gesetzt sein</mat-hint>
        </mat-form-field>
      </div>
    </div>

    <div class="save-label-row">
      <button mat-flat-button color="primary" (click)="saveLabel()" [disabled]="labelControl.pristine || labelControl.invalid">
        Bezeichnung speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>

  <div class="form-box">
    <knust-data-table newButtonText="Neuen Artikel hinzufügen"
                      (addEntityClick)="editDeliveryArticle()"
                      (entityRowClick)="editDeliveryArticle($event)"
                      (deleteEntityClick)="deleteDeliveryArticle($event)"
                      (duplicateEntityClick)="copyDeliveryArticle($event)"
                      [disableEditing]="(delivery?.completed ?? false)"
                      [disableNewButton]="!label || (delivery?.completed ?? false)"
                      [commandMap]="commandMap"
                      [columnConfig]="columnConfig"
                      [displayedColumns]="displayedColumns">
    </knust-data-table>

    <div class="form-action-row">
      <button mat-flat-button (click)="closeDelivery()" color="primary" class="save-btn"
              [disabled]="!label || delivery?.completed"
              *ngIf="!newEntity">
        Wareneingang {{delivery?.completed ? 'verarbeitet' : 'verarbeiten'}}
        <mat-icon>{{delivery?.completed ? 'done_all' : 'check'}}</mat-icon>
      </button>
    </div>
  </div>
</ng-container>
