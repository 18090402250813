import {createFeatureSelector, createSelector} from "@ngrx/store";

import {State, TRUNK_FEATURE_KEY, trunkAdapter} from "./trunk.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'Trunk' feature state managed by NgRx
export const getTrunkState = createFeatureSelector<State>(TRUNK_FEATURE_KEY);

const { selectAll, selectEntities } = trunkAdapter.getSelectors();

export const getTrunkLoaded = createSelector(
  getTrunkState,
  (state: State) => state.loaded
);

export const getTrunkError = createSelector(
  getTrunkState,
  (state: State) => state.error
);

export const getCompletedFilter = createSelector(
  getTrunkState,
  (state: State) => state.showCompleted
)

export const getReportsCompletedFilter = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.showCompleted
)

export const getReportsUserFilter = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.userId
)

export const getReportsArticleFilter = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.articleId
)

export const getReportsDateStartFilter = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.dateStart
)

export const getReportsDateEndFilter = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.dateEnd
)

export const getTrunkTotal = createSelector(
  getTrunkState,
  (state: State) => state.total
);

export const getTrunkArticlesTotal = createSelector(
  getTrunkState,
  (state: State) => state.articleQuery.total
);

export const getTrunkBookingsTotal = createSelector(
  getTrunkState,
  (state: State) => state.bookingsQuery.total
);

export const getTrunkReportsTotal = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.total
);

export const getTrunkPage = createSelector(
  getTrunkState,
  (state: State) => state.page
);

export const getTrunkTerm = createSelector(
  getTrunkState,
  (state: State) => state.term
);

export const getTrunkSort = createSelector(
  getTrunkState,
  (state: State) => state.sort
);

export const getTrunkArticlesPage = createSelector(
  getTrunkState,
  (state: State) => state.articleQuery.page
);

export const getTrunkArticlesTerm = createSelector(
  getTrunkState,
  (state: State) => state.articleQuery.term
);

export const getTrunkArticlesSort = createSelector(
  getTrunkState,
  (state: State) => state.articleQuery.sort
);

export const getTrunkBookingsPage = createSelector(
  getTrunkState,
  (state: State) => state.bookingsQuery.page
);

export const getTrunkBookingsTerm = createSelector(
  getTrunkState,
  (state: State) => state.bookingsQuery.term
);

export const getTrunkBookingsSort = createSelector(
  getTrunkState,
  (state: State) => state.bookingsQuery.sort
);

export const getTrunkReportsPage = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.page
);

export const getTrunkReportsTerm = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.term
);

export const getTrunkReportsSort = createSelector(
  getTrunkState,
  (state: State) => state.reportsQuery.sort
);

export const getTrunkQuery = createSelector(
  getTrunkState,
  (state: State) => StateToQuery(state)
);

export const getTrunkArticlesQuery = createSelector(
  getTrunkState,
  (state: State) => StateToQuery(state.articleQuery)
);

export const getTrunkBookingsQuery = createSelector(
  getTrunkState,
  (state: State) => StateToQuery(state.bookingsQuery)
);

export const getTrunkReportsQuery = createSelector(
  getTrunkState,
  (state: State) => StateToQuery(state.reportsQuery)
);

export const getAllTrunk = createSelector(
  getTrunkState,
  (state: State) => selectAll(state)
);

export const getAllTrunkArticles = createSelector(
  getTrunkState,
  (state: State) => state.articles
);

export const getAllTrunkBookings = createSelector(
  getTrunkState,
  (state: State) => state.bookings
);

export const getAllTrunkReports = createSelector(
  getTrunkState,
  (state: State) => state.reports
);

export const getTrunkEntities = createSelector(
  getTrunkState,
  (state: State) => selectEntities(state)
);

export const getCart = createSelector(
  getTrunkState,
  (state: State) => state.cart
);

export const getSelectedId = createSelector(
  getTrunkState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getTrunkEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
