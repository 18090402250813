import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {Delivery, DeliveryArticle} from "@knust/api-interfaces";
import {DELIVERY_URL, REPORTS_URL} from "../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

  constructor(private http: HttpClient) { }

  loadDeliveries(query?: string): Observable<PaginatedResponse<Delivery>> {
    return this.http.get<PaginatedResponse<Delivery>>(DELIVERY_URL + (query ?? ''));
  }

  loadDeliveryDetails(id: number | string): Observable<Delivery> {
    return this.http.get<Delivery>(DELIVERY_URL + '/details/' + id);
  }

  loadDeliveryReports(query?: string): Observable<PaginatedResponse<Delivery>> {
    return this.http.get<PaginatedResponse<Delivery>>(REPORTS_URL + '/deliveries' + (query ?? ''));
  }

  downloadDeliveryReport(filePath: string) {
    return REPORTS_URL + '/download/' + filePath;
  }

  loadDeliveryArticles(id: number | string, query?: string): Observable<PaginatedResponse<DeliveryArticle>> {
    return this.http.get<PaginatedResponse<DeliveryArticle>>(DELIVERY_URL + '/articles/' + id + query)
  }

  addDeliveryArticle(id: number, article: DeliveryArticle | Partial<DeliveryArticle>): Observable<DeliveryArticle> {
    return this.http.post<DeliveryArticle>(DELIVERY_URL + '/articles/' + id, article);
  }

  deleteDeliveryArticle(id: number, article: DeliveryArticle | Partial<DeliveryArticle>) {
    return this.http.delete(DELIVERY_URL + '/articles/' + id + '?articleId=' + article.id);
  }

  saveDelivery(article: Delivery | Omit<Delivery, 'id'>): Observable<Delivery> {
    return this.http.post<Delivery>(DELIVERY_URL, article);
  }

  deleteDelivery(article: Delivery) {
    return this.http.delete(DELIVERY_URL + '/' + article.id);
  }

  closeDelivery(deliveryId: number): Observable<{deliveryId: number}> {
    return this.http.patch<{deliveryId: number}>(DELIVERY_URL + '/close/' + deliveryId, {});
  }

  saveLabel(label: string | null, id: number) {
    return this.http.post<{ label: string }>(DELIVERY_URL + '/label/' + id, { label });
  }
}
