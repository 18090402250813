<div class="login-box">
  <img class="logo" alt="Firmenlogo" [src]="logoPath">

  <h1 class="login-header">Passwort zurücksetzen</h1>
  <p class="login-hint">Bitte geben Sie ihren Benutzernamen ein.</p>

  <form [formGroup]="pwResetForm" (ngSubmit)="nextStep()" class="login-form">
    <mat-form-field appearance="outline">
      <mat-label>Schritt 1 - Benutzername</mat-label>
      <input matInput formControlName="username">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Schritt 2 - Verifizierungscode</mat-label>
      <input matInput formControlName="verificationCode">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Schritt 2 - Neues Passwort</mat-label>
      <input matInput formControlName="newPassword">
    </mat-form-field>

    <button mat-raised-button color="primary" [disabled]="pwResetForm.invalid" type="submit" class="login-button">
      <span>{{currentButtonText}}</span>
      <mat-icon>{{currentButtonIcon}}</mat-icon>
    </button>

    <a class="back-to-login-link" routerLink="/login">
      Zurück zum Login
    </a>
  </form>
</div>
