import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {TrunksDetailComponent} from "./trunks-detail/trunks-detail.component";
import {TrunksIndexComponent} from "./trunks-index/trunks-index.component";
import {CartComponent} from "./cart/cart.component";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {StoreModule} from "@ngrx/store";
import * as fromTrunk from "./+state/trunk.reducer";
import {EffectsModule} from "@ngrx/effects";
import {TrunkEffects} from "./+state/trunk.effects";
import {MatTableModule} from "@angular/material/table";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatSortModule} from "@angular/material/sort";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatExpansionModule} from "@angular/material/expansion";
import {PipesModule} from "../utility/pipes/pipes.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {TrunksRoutingModule} from "./trunks-routing.module";

@NgModule({
  declarations: [TrunksDetailComponent, TrunksIndexComponent, CartComponent],
    imports: [
        CommonModule,
        TrunksRoutingModule,
        DataTableModule,
        StoreModule.forFeature(fromTrunk.TRUNK_FEATURE_KEY, fromTrunk.reducer),
        EffectsModule.forFeature([TrunkEffects]),
        MatTableModule,
        MatIconModule,
        MatButtonModule,
        MatSortModule,
        MatInputModule,
        FormsModule,
        MatExpansionModule,
        PipesModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ],
})
export class TrunksModule {}
