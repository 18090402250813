import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {first, Observable} from "rxjs";
import {BaseDataService} from "../../utility/services/base-data.service";
import {BaseData} from "@knust/api-interfaces";

@Injectable({
  providedIn: 'root'
})
export class BaseDataResolver implements Resolve<BaseData> {
  constructor(private baseDataService: BaseDataService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<BaseData> {
    return this.baseDataService.loadDetails().pipe(
      first()
    );
  }
}
