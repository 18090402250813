import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {Article} from "@knust/api-interfaces";
import {ARTICLES_URL} from "../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class ArticleService {

  constructor(private http: HttpClient) { }

  loadArticles(query?: string): Observable<PaginatedResponse<Article>> {
    return this.http.get<PaginatedResponse<Article>>(ARTICLES_URL + (query ?? ''));
  }

  loadArticleDetails(id: number | string): Observable<Article> {
    return this.http.get<Article>(ARTICLES_URL + '/' + id);
  }

  uploadArticleImage(formData: FormData) {
    return this.http.post<{ path: string }>(ARTICLES_URL + '/upload', formData);
  }

  getUnitsOfMeasure(): Observable<string[]> {
    return this.http.get<string[]>(ARTICLES_URL + '/measurements');
  }

  saveArticle(article: Article | Omit<Article, 'id'>): Observable<Article> {
    return this.http.post<Article>(ARTICLES_URL, article);
  }

  deleteArticle(articleId: number) {
    return this.http.delete(ARTICLES_URL + '/' + articleId);
  }

  restoreArticle(article: Article): Observable<Article> {
    return this.http.patch<Article>(ARTICLES_URL + '/' + article.id, {});
  }
}
