import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Delivery, DeliveryArticle} from "@knust/api-interfaces";

export const loadDelivery = createAction('[Delivery] Load Delivery');

export const loadDeliverySuccess = createAction(
  '[Delivery/API] Load Delivery Success',
  props<{ delivery: Delivery[], total: number }>()
);

export const loadDeliveryFailure = createAction(
  '[Delivery/API] Load Delivery Failure',
  props<{ error: any }>()
);

export const loadDeliveryArticles = createAction('[Delivery] Load DeliveryArticles');

export const loadDeliveryArticlesSuccess = createAction(
  '[Delivery/API] Load Delivery Articles Success',
  props<{ deliveryArticles: DeliveryArticle[], total: number }>()
);

export const loadDeliveryArticlesFailure = createAction(
  '[Delivery/API] Load Delivery Articles Failure',
  props<{ error: any }>()
);

export const loadDeliveryReports = createAction(
  '[Delivery] Load Delivery Reports',
  (payload: { download: boolean } = { download: false }) => payload
);

export const loadDeliveryReportsSuccess = createAction(
  '[Delivery/API] Load Delivery Reports Success',
  props<{ deliveryReports: Delivery[], total: number }>()
);

export const loadDeliveryReportsFailure = createAction(
  '[Delivery/API] Load Delivery Reports Failure',
  props<{ error: any }>()
);

export const downloadDeliveryReport = createAction(
  '[Delivery/API] Download Delivery Reports',
  props<{ url: string }>()
)

export const changeCompletedFilter = createAction(
  '[Delivery/API] Set Completed Filter',
)

export const changeReportsCompletedFilter = createAction(
  '[Delivery/API] Set Reports Completed Filter',
)

export const setReportsUserFilter = createAction(
  '[Delivery/API] Set Reports User Filter',
  props<{ userId: number }>()
)

export const setReportsArticleFilter = createAction(
  '[Delivery/API] Set Reports Article Filter',
  props<{ articleId: number }>()
)

export const setReportsVendorFilter = createAction(
  '[Delivery/API] Set Reports Vendor Filter',
  props<{ vendorId: number }>()
)

export const setReportsDateFilter = createAction(
  '[Delivery/API] Set Reports Date Filter',
  props<{ dateStart: Date | undefined, dateEnd: Date | undefined }>()
)

export const resetReportFilters = createAction(
  '[Delivery/API] Reset Report Filters',
);

export const setSelectedDelivery = createAction(
  '[Delivery] Set selected Delivery',
  props<{ id: number }>()
);

export const unsetSelectedDelivery = createAction(
  '[Delivery] Unset selected Delivery'
);

export const loadDeliveryDetailUnneeded = createAction(
  '[Delivery/API] Load Delivery Detail Unneeded'
);

export const loadDeliveryDetailSuccess = createAction(
  '[Delivery/API] Load Delivery Detail Success',
  props<{ delivery: Delivery }>()
);

export const loadDeliveryDetailFailure = createAction(
  '[Delivery/API] Load Delivery Detail Failure',
  props<{ error: any }>()
);

export const setDeliveryPage = createAction(
  '[Delivery/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setDeliveryTerm = createAction(
  '[Delivery/API] Set Term',
  props<{ term: string }>()
);

export const setDeliverySort = createAction(
  '[Delivery/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const setDeliveryArticlesPage = createAction(
  '[Delivery/API] Set Articles Page',
  props<{ page: PageEvent }>()
);

export const setDeliveryArticlesTerm = createAction(
  '[Delivery/API] Set Articles Term',
  props<{ term: string }>()
);

export const setDeliveryArticlesSort = createAction(
  '[Delivery/API] Set Articles Sort',
  props<{ sort: Sort | null }>()
);

export const setDeliveryReportsPage = createAction(
  '[Delivery/API] Set Reports Page',
  props<{ page: PageEvent }>()
);

export const setDeliveryReportsTerm = createAction(
  '[Delivery/API] Set Reports Term',
  props<{ term: string }>()
);

export const setDeliveryReportsSort = createAction(
  '[Delivery/API] Set Reports Sort',
  props<{ sort: Sort | null }>()
);

export const clearDelivery = createAction(
  '[Delivery/API] Clear'
);

export const createDelivery = createAction(
  '[Delivery/API] Create Delivery',
  props<{ delivery: Omit<Delivery, 'id'> }>()
);

export const updateDelivery = createAction(
  '[Delivery/API] Update Delivery',
  props<{ delivery: Delivery | Omit<Delivery, 'id'> }>()
);

export const saveDeliverySuccess = createAction(
  '[Delivery/API] Save Delivery Success',
  props<{ delivery: Delivery, insert: boolean }>()
);

export const saveDeliveryFailure = createAction(
  '[Delivery/API] Save Delivery Failure',
  props<{ error: any }>()
);

export const saveDeliveryLabel = createAction(
  '[Delivery/API] Save Delivery Label',
  props<{ label: string | null }>()
)

export const saveDeliveryLabelSuccess = createAction(
  '[Delivery/API] Save Delivery Label Success',
  props<{ label: string | null }>()
)

export const saveDeliveryLabelFailure = createAction(
  '[Delivery/API] Save Delivery Label Failure',
  props<{ error: any }>()
);

export const deleteDelivery = createAction(
  '[Delivery/API] Delete Delivery',
  props<{ delivery: Delivery }>()
);

export const deleteDeliverySuccess = createAction(
  '[Delivery/API] Delete Delivery Success',
  props<{ id: number | string }>()
);

export const deleteDeliveryFailure = createAction(
  '[Delivery/API] Delete Delivery Failure',
  props<{ error: any }>()
);

export const addDeliveryArticle = createAction(
  '[Delivery/API] Create DeliveryArticle',
  props<{ deliveryArticle: DeliveryArticle | Partial<DeliveryArticle> }>()
)

export const addDeliveryArticleSuccess = createAction(
  '[Delivery/API] Add DeliveryArticle Success',
  props<{ deliveryArticle: DeliveryArticle }>()
);

export const addDeliveryArticleFailure = createAction(
  '[Delivery/API] Add DeliveryArticle Failure',
  props<{ error: any }>()
);

export const deleteDeliveryArticle = createAction(
  '[Delivery/API] Delete DeliveryArticle',
  props<{ deliveryArticle: DeliveryArticle }>()
)

export const deleteDeliveryArticleSuccess = createAction(
  '[Delivery/API] Delete DeliveryArticle Success',
  props<{ deliveryArticle: DeliveryArticle }>()
);

export const deleteDeliveryArticleFailure = createAction(
  '[Delivery/API] Delete DeliveryArticle Failure',
  props<{ error: any }>()
);

export const updateDeliveryArticles = createAction(
  '[Delivery/API] Update DeliveryArticles',
  props<{ deliveryArticles: DeliveryArticle[], deliveryId: number }>()
);

export const closeDelivery = createAction(
  '[Delivery/API] Close Delivery',
  props<{ deliveryId: number }>()
);

export const closeDeliverySuccess = createAction(
  '[Delivery/API] Close Delivery Success',
  props<{ deliveryId: number }>()
);
