import {StorageLocationArticle} from "@knust/api-interfaces";

export interface TrunkArticle {
  id: number;
  storageLocationArticle: StorageLocationArticle;
  withdrawnStock: number;
  usedStock: number;
  availableStock: number;
  note?: string;
}
