import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, Validators} from "@angular/forms";
import {ColumnConfig} from "../../reusable-components/data-table/column-config";
import {Store} from "@ngrx/store";
import {BehaviorSubject, combineLatest, filter, map, merge, take, tap} from "rxjs";
import {getRouteParams} from "../../+state/root.selectors";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {
  getAllDeliveryArticles,
  getDeliveryArticlesPage,
  getDeliveryArticlesSort,
  getDeliveryArticlesTerm,
  getDeliveryArticlesTotal,
  getSelected
} from "../+state/delivery.selectors";
import {StoreRootState} from "../../+state/root.reducer";
import {UntilDestroy} from "@ngneat/until-destroy";
import {Article, Delivery, DeliveryArticle, StorageLocation, VendorArticle} from "@knust/api-interfaces";
import {
  addDeliveryArticle,
  closeDelivery,
  createDelivery,
  deleteDeliveryArticle,
  loadDeliveryArticles,
  saveDeliveryLabel,
  setDeliveryArticlesPage,
  setDeliveryArticlesSort,
  setDeliveryArticlesTerm
} from "../+state/delivery.actions";
import {MatDialog} from "@angular/material/dialog";
import {EditDeliveryDialogComponent} from "../edit-delivery-dialog/edit-delivery-dialog.component";
import {ArticleService} from "../../utility/services/article.service";
import {StorageLocationService} from "../../utility/services/storage-location.service";
import {VendorArticleService} from "../../utility/services/vendor-article.service";
import {HttpClient} from "@angular/common/http";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {Location} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'knust-delivery-detail',
  templateUrl: './delivery-detail.component.html',
  styleUrls: ['./delivery-detail.component.scss'],
})
export class DeliveryDetailComponent implements OnInit {
  labelControl = new FormControl('', [Validators.required, Validators.minLength(1)]);
  label: string | null | undefined = undefined;

  commandMap: AbstractIndexCommands<DeliveryArticle> = {
    getAll: getAllDeliveryArticles,
    getTotal: getDeliveryArticlesTotal,
    getPage: getDeliveryArticlesPage,
    setPage: setDeliveryArticlesPage,
    getTerm: getDeliveryArticlesTerm,
    setTerm: setDeliveryArticlesTerm,
    getSort: getDeliveryArticlesSort,
    setSort: setDeliveryArticlesSort,
    load: loadDeliveryArticles,
  }
  displayedColumns = [
    'mainArticle.title',
    'mainArticle.internalId',
    'vendorArticle.vendor.organization.name',
    'vendorArticle.vendorArticleNumber',
    'storageLocation.label',
    'count',
    'note',
    'deliveryActions'
  ];
  columnConfig: ColumnConfig[] = [
    {
      name: 'Artikelname',
      refName: 'mainArticle',
      nestedName: 'title',
      useNestedName: true
    },
    {
      name: 'Artikelnummer',
      refName: 'mainArticle',
      nestedName: 'internalId',
      useNestedName: true
    },
    {
      name: 'Lieferant',
      refName: 'vendorArticle',
      nestedName: ['vendor', 'organization', 'name'],
      useNestedName: true
    },
    {
      name: 'Lieferanten-Artikelnr.',
      refName: 'vendorArticle',
      nestedName: ['vendorArticleNumber'],
      useNestedName: true
    },
    {
      name: 'Lagerort',
      refName: 'storageLocation',
      nestedName: ['label'],
      useNestedName: true
    },
    {
      name: 'Anzahl',
      refName: 'count'
    },
    {
      name: 'Notiz',
      refName: 'note'
    }
  ];

  newEntity = false;
  delivery = merge(
    this.store.select(getRouteParams).pipe(
      filter(params => params['deliveryId'] && params['deliveryId'] === NEW_ENTITY),
      tap(() => {
        console.log('New Entity');
        this.newEntity = true;
      }),
      map(() => ({
        createdByUser: 0,
        updatedByUser: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
        completed: false,
        label: null,
        articles: []
      }) as Omit<Delivery, 'id'>)
    ),
    this.store.select(getSelected).pipe(
      filter(selected => !!selected),
      tap((selected) => {
        if (selected) {
          this.labelControl.patchValue(selected.label ?? null);
          this.label = selected.label;
        }
        console.log('Selected Entity')
        this.newEntity = false;
      })
    )
  );

  editDialogData = new BehaviorSubject<[Article[], VendorArticle[], StorageLocation[]]>([[], [], []]);

  constructor(private fb: FormBuilder,
              private matDialog: MatDialog,
              private http: HttpClient,
              private dialog: MatDialog,
              public location: Location,
              private articleService: ArticleService,
              private storageLocationService: StorageLocationService,
              private vendorArticleService: VendorArticleService,
              private store: Store<StoreRootState>) {
  }

  ngOnInit() {
    this.loadData();
    console.log(this.label);
  }

  loadData() {
    combineLatest([
      this.articleService.loadArticles('?page=1&limit=100').pipe(map(articleResponse => articleResponse.items)),
      this.vendorArticleService.loadVendorArticles('?page=1&limit=100').pipe(map(vendorArticleResponse => vendorArticleResponse.items)),
      this.storageLocationService.loadLocations('?page=1&limit=100').pipe(map(locationsResponse => locationsResponse.items))
    ]).subscribe(combinedData => {
      console.log(combinedData);
      this.editDialogData.next(combinedData);
    });
  }

  copyDeliveryArticle(row: DeliveryArticle): void {
    const newArticle: Partial<DeliveryArticle> = {...row};
    delete newArticle.id;

    this.store.dispatch(addDeliveryArticle( { deliveryArticle: newArticle }));
  }

  deleteDeliveryArticle(row: DeliveryArticle): void {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Warenbuchungsartikel wirklich löschen?',
        text: 'Bei Bestätigung wird dieser Warenbuchungsartikel endgültig gelöscht.',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteDeliveryArticle( { deliveryArticle: row }));
        }
      })
  }

  editDeliveryArticle(row?: DeliveryArticle) {
    this.delivery.pipe(take(1)).subscribe(delivery => {
      if (delivery && !delivery.completed) {
        this.matDialog.open(
          EditDeliveryDialogComponent,
          {
            data: {deliveryArticle: row ?? undefined, editData: this.editDialogData}
          }
        )
          .afterClosed()
          .subscribe(deliveryArticle => {
            if (deliveryArticle && !this.newEntity) {
              this.store.dispatch(addDeliveryArticle( { deliveryArticle }));
            } else if (deliveryArticle && this.newEntity) {
              this.store.dispatch(createDelivery({
                delivery: {
                  ...delivery,
                  articles: [...delivery.articles, deliveryArticle],
                },
              }));
            }
        });
      }

      console.log(delivery);
    });
  }

  /*
  updateDeliveryArticles(updateArticle: DeliveryArticle | Partial<DeliveryArticle>, deleteArticle?: boolean) {
    this.delivery.pipe(take(1)).subscribe(delivery => {
      if (delivery) {
        if (!updateArticle.id) {
          console.log('Creating New DeliveryArticle', delivery, {
            ...delivery,
            articles: [...delivery.articles, updateArticle],
          });

          this.store.dispatch(createDelivery({
            delivery: {
              ...delivery,
              articles: [...delivery.articles, updateArticle],
            },
          }));
        } else {
          const updateIndex = delivery.articles.findIndex((deliveryArticle: DeliveryArticle) => deliveryArticle.id === updateArticle.id);
          const updatedDeliveryArticles = [...delivery.articles];

          if (!deleteArticle) {
            updatedDeliveryArticles[updateIndex] = updateArticle;
          } else {
            updatedDeliveryArticles.splice(updateIndex, 1);
          }

          this.store.dispatch(updateDelivery({
            delivery: {...delivery, articles: updatedDeliveryArticles}
          }));
        }
      }
    });
  }*/

  closeDelivery() {
    this.delivery
      .pipe(take(1))
      .subscribe(delivery => {
        if (!this.newEntity && delivery) {
          this.store.dispatch(closeDelivery({deliveryId: (delivery as Delivery).id}))
        }
      });
  }

  saveLabel() {
    if (this.labelControl.value && this.labelControl.value.length > 0) {
      if (this.newEntity) {
        this.store.dispatch(createDelivery({
          delivery: {
            completed: false,
            label: this.labelControl.value,
            articles: [],
          },
        }));
      } else {
        this.store.dispatch(saveDeliveryLabel({ label: this.labelControl.value }));
      }

      this.labelControl.markAsPristine();
      this.label = this.labelControl.value;
    }
  }
}
