<div class="form-box">
  <knust-data-table [disableNewButton]="true"
                    [noDataText]="(!!filterForId && filterForId !== 0)
                    ? 'Der Lagerort konnte nicht gefunden werden. Falschen Code gescannt?'
                    : 'Es wurden keine Lagerorte gefunden.'"
                    [disableFilterblock]="(!!filterForId && filterForId !== 0)"
                    (entityRowClick)="editArticleStorageLocation($event)"
                    [commandMap]="commandMap"
                    [columnConfig]="tableConfig"
                    [displayedColumns]="displayedColumns">
    <mat-slide-toggle color="primary" [checked]="showEmpty" (toggleChange)="setEmptyFilter()">
      Lagerorte ohne Bestand anzeigen
    </mat-slide-toggle>
  </knust-data-table>
</div>
