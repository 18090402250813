import { IsString, MaxLength, MinLength } from "class-validator"

export class TodoDto {
    @IsString()
    @MinLength(4)
    @MaxLength(30)
    title!: string

    @IsString()
    @MinLength(4)
    @MaxLength(150)
    description!: string
}
