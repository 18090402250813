import {Component} from "@angular/core";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {BaseDataService} from "../../utility/services/base-data.service";
import {ActivatedRoute} from "@angular/router";
import {Store} from "@ngrx/store";
import {loadStorageLocation} from "../storage-location/+state/storage-location.actions";
import {BaseData} from "@knust/api-interfaces";
import {AuthService} from "../../utility/services/auth.service";

@Component({
  selector: 'knust-base-data',
  templateUrl: './base-data.component.html',
  styleUrls: ['./base-data.component.scss'],
})
export class BaseDataComponent {
  selectedFile = undefined;

  baseDataForm: UntypedFormGroup = this.fb.group({
    name:  [null, Validators.required],
    notificationMail:  [null, [Validators.required, Validators.email]],
    notificationTime:  [null],
    orderImmediately:  [true],
    orderCCMail:  [null, [Validators.required, Validators.email]],
    address: this.fb.group({
      id: [null],
      street: [null, Validators.required],
      addressSupplement: [null],
      zip: [null, Validators.required],
      city: [null, Validators.required],
      country: ['Deutschland', Validators.required],
      latitude: [null],
      longitude: [null],
    }),
    phone: [null],
    fax: [null],
    web: [null],
    note: [null],
  });
  initialBaseData!: BaseData;

  editMode = false;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private store: Store,
              public authService: AuthService,
              private baseDataService: BaseDataService) {
    this.route.data.subscribe((routeData: any) => {
      this.initialBaseData = routeData.baseData;
      this.baseDataForm.patchValue(routeData.baseData);
      this.baseDataForm.disable();
    });

    this.store.dispatch(loadStorageLocation());
  }

  saveBaseData() {
    if (this.baseDataForm.valid) {
      this.baseDataService.saveDetails(this.baseDataForm.value)
        .subscribe(baseData => this.initialBaseData = baseData);

      this.switchEditMode(false, false);
    }
  }

  switchEditMode(resetData?: boolean, editMode?: boolean) {
    this.editMode = editMode ?? !this.editMode;

    if (this.editMode) {
      this.baseDataForm.enable();
    } else {
      this.baseDataForm.disable();

      if (resetData) {
        this.baseDataForm.reset();
        this.baseDataForm.patchValue(this.initialBaseData, { emitEvent: false });
      }
    }
  }

  selectFile(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (this.selectedFile) {
      const fd = new FormData();
      fd.append('file', this.selectedFile);

      this.baseDataService.uploadLogo(fd).subscribe(response => {

      })
    }
  }
}
