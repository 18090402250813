<h1>Benutzer</h1>

<div class="form-box">
  <knust-edit-user-form [user]="authService.currentUser | async"></knust-edit-user-form>
</div>

<ng-container *ngIf="authService.isAdmin()">
  <div class="user-roles-box">
    <p>
      Es stehen verschiedene Benutzerrollen zur Auswahl:
    </p>
    <ul>
      <li>Käufer: Dieser Nutzer kann Artikel in den Warenkorb legen und damit neue Entnahmen eröffnen.</li>
      <li>Einkäufer: Mit dieser Rolle lassen sich zusätzlich noch Lieferanten und Wareneingangsbuchungen verwalten.</li>
      <li>Administrator: Erlaubt Vollzugriff auf die Anwendung. Alle Funktionen stehen zur Verfügung, inkl. Verwaltung der Stammdaten, Import-Möglichkeit und Berichten.</li>
    </ul>
  </div>

  <div class="form-box">
    <h3>Alle Benutzer</h3>

    <knust-data-table [displayedColumns]="displayedColumns"
                      (entityRowClick)="editUser($event)"
                      (addEntityClick)="editUser()"
                      (deleteEntityClick)="deleteUser($event)"
                      newButtonText="Neuen Benutzer hinzufügen"
                      [columnConfig]="columnConfig"
                      [commandMap]="commandMap">
    </knust-data-table>
  </div>

  <div class="email-test-btn-container">
    <button mat-flat-button color="primary" (click)="testEmail()">Bestellmail auslösen</button>
    <button mat-flat-button color="primary" (click)="testEmail(true)">Benachrichtungsmail auslösen</button>
  </div>
</ng-container>
