import {createFeatureSelector, createSelector} from "@ngrx/store";

import {ARTICLE_FEATURE_KEY, articleAdapter, State} from "./article.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'Article' feature state managed by NgRx
export const getArticleState = createFeatureSelector<State>(ARTICLE_FEATURE_KEY);

const { selectAll, selectEntities } = articleAdapter.getSelectors();

export const getArticleLoaded = createSelector(
  getArticleState,
  (state: State) => state.loaded
);

export const getArticleError = createSelector(
  getArticleState,
  (state: State) => state.error
);

export const getArticleTotal = createSelector(
  getArticleState,
  (state: State) => state.total
);

export const getCategoryFilter = createSelector(
  getArticleState,
  (state: State) => state.category
);

export const getArticlePage = createSelector(
  getArticleState,
  (state: State) => state.page
);

export const getArticleTerm = createSelector(
  getArticleState,
  (state: State) => state.term
);

export const getArticleSort = createSelector(
  getArticleState,
  (state: State) => state.sort
);

export const getArticleQuery = createSelector(
  getArticleState,
  (state: State) => StateToQuery(state)
);

export const getAllArticle = createSelector(
  getArticleState,
  (state: State) => selectAll(state)
);

export const getArticleEntities = createSelector(
  getArticleState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getArticleState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getArticleEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
