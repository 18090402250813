import {Injectable} from "@angular/core";
import {catchError, map, of, switchMap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, createEffect, ofType} from "@ngrx/effects";

import * as EmailActions from "./email.actions";
import {EmailTemplateService} from "../../../utility/services/email-template.service";
import {StoreRootState} from "../../../+state/root.reducer";

@Injectable()
export class EmailEffects {

  loadEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailActions.loadEmails),
        switchMap(() => {
          return this.emailService.loadEmails().pipe(
            map(res => EmailActions.loadEmailsSuccess({ email: Array.isArray(res) ? res : [res] })),
          );
        }),
        catchError((error) => {
          return of(EmailActions.loadEmailsFailure({ error }));
        })
      )
  );

  updateEmail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmailActions.updateEmail),
        switchMap(({ email }) =>
          this.emailService.updateEmail(email.id, email).pipe(
            map((updatedEmail) =>
              EmailActions.updateEmailSuccess({ email: updatedEmail })
            ),
            catchError(error => of(EmailActions.updateEmailFailure({ error })))
          )
        )
      )
  )



  constructor(private readonly actions$: Actions,
              private emailService: EmailTemplateService,) {}
}
