import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingsRoutingModule } from './bookings-routing.module';
import { BookingsIndexComponent } from './bookings-index/bookings-index.component';
import { BookingsDetailComponent } from './bookings-detail/bookings-detail.component';
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {StoreModule} from "@ngrx/store";
import * as fromBooking from "./+state/bookings.reducer";
import {EffectsModule} from "@ngrx/effects";
import {BookingEffects} from "./+state/bookings.effects";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {ArticleEffects} from "../articles/+state/article.effects";
import {
    ArticleStorageLocationsModule
} from "../reusable-components/article-storage-locations/article-storage-locations.module";
import {
    StorageLocationArticlesModule
} from "../reusable-components/storage-locations-articles/storage-locations-article.module";

@NgModule({
  declarations: [BookingsIndexComponent, BookingsDetailComponent],
    imports: [
        CommonModule,
        BookingsRoutingModule,
        DataTableModule,
        StoreModule.forFeature(
            fromBooking.BOOKINGS_FEATURE_KEY,
            fromBooking.reducer
        ),
        EffectsModule.forFeature([BookingEffects]),
        MatSlideToggleModule,
        FormsModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        ArticleStorageLocationsModule,
        StorageLocationArticlesModule
    ],
})
export class BookingsModule {}
