import {createFeatureSelector, createSelector} from "@ngrx/store";

import {DELIVERY_FEATURE_KEY, deliveryAdapter, State} from "./delivery.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'Delivery' feature state managed by NgRx
export const getDeliveryState = createFeatureSelector<State>(DELIVERY_FEATURE_KEY);

const { selectAll, selectEntities } = deliveryAdapter.getSelectors();

export const getCompletedFilter = createSelector(
  getDeliveryState,
  (state: State) => state.showCompleted
)

export const getReportsCompletedFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.showCompleted
)

export const getReportsUserFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.userId
)

export const getReportsArticleFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.articleId
)

export const getReportsVendorFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.vendorId
)

export const getReportsDateStartFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.dateStart
)

export const getReportsDateEndFilter = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.dateEnd
)

export const getDeliveryLoaded = createSelector(
  getDeliveryState,
  (state: State) => state.loaded
);

export const getDeliveryError = createSelector(
  getDeliveryState,
  (state: State) => state.error
);

export const getDeliveryTotal = createSelector(
  getDeliveryState,
  (state: State) => state.total
);

export const getDeliveryArticlesTotal = createSelector(
  getDeliveryState,
  (state: State) => state.articleQuery.total
);

export const getDeliveryReportsTotal = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.total
);

export const getDeliveryPage = createSelector(
  getDeliveryState,
  (state: State) => state.page
);

export const getDeliveryTerm = createSelector(
  getDeliveryState,
  (state: State) => state.term
);

export const getDeliverySort = createSelector(
  getDeliveryState,
  (state: State) => state.sort
);

export const getDeliveryArticlesPage = createSelector(
  getDeliveryState,
  (state: State) => state.articleQuery.page
);

export const getDeliveryArticlesTerm = createSelector(
  getDeliveryState,
  (state: State) => state.articleQuery.term
);

export const getDeliveryArticlesSort = createSelector(
  getDeliveryState,
  (state: State) => state.articleQuery.sort
);

export const getDeliveryReportsPage = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.page
);

export const getDeliveryReportsTerm = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.term
);

export const getDeliveryReportsSort = createSelector(
  getDeliveryState,
  (state: State) => state.reportsQuery.sort
);

export const getDeliveryQuery = createSelector(
  getDeliveryState,
  (state: State) => StateToQuery(state)
);

export const getDeliveryArticlesQuery = createSelector(
  getDeliveryState,
  (state: State) => StateToQuery(state.articleQuery)
);

export const getDeliveryReportsQuery = createSelector(
  getDeliveryState,
  (state: State) => StateToQuery(state.reportsQuery)
);

export const getAllDelivery = createSelector(
  getDeliveryState,
  (state: State) => selectAll(state)
);

export const getAllDeliveryArticles = createSelector(
  getDeliveryState,
  (state: State) => state.articles
);

export const getAllDeliveryReports = createSelector(
  getDeliveryState,
  (state: State) => state.reports
);

export const getDeliveryEntities = createSelector(
  getDeliveryState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getDeliveryState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getDeliveryEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
