export function changeToNextIntervalStep(event: any, samplingStep: number) {
  const targetNumberValue = parseInt(event.target.value, 10);

  if (targetNumberValue % samplingStep !== 0) {
    event.target.value = calculateNextIntervalStep(targetNumberValue, samplingStep);
  }
}

export function calculateNextIntervalStep(inputValue: number, samplingStep: number): number {
  const division = inputValue / samplingStep;
  const multiplicator = inputValue < 0 ? Math.floor(division) : Math.ceil(division);

  return (multiplicator * samplingStep);
}
