import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ArticleStorageLocationsComponent} from "./article-storage-locations.component";
import {StoreModule} from "@ngrx/store";
import * as fromArticleStorageLocation from "./+state/article-storage-location.reducer";
import {EffectsModule} from "@ngrx/effects";
import {ArticleStorageLocationEffects} from "./+state/article-storage-location.effects";
import {DataTableModule} from "../data-table/data-table.module";
import {TrunkEffects} from "../../trunks/+state/trunk.effects";
import {
  EditArticleStorageLocationComponent
} from "./edit-article-storage-location/edit-article-storage-location.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
  declarations: [
    ArticleStorageLocationsComponent,
    EditArticleStorageLocationComponent,
  ],
    imports: [
        CommonModule,
        StoreModule.forFeature(
            fromArticleStorageLocation.ARTICLE_STORAGE_LOCATION_FEATURE_KEY,
            fromArticleStorageLocation.reducer
        ),
        EffectsModule.forFeature([ArticleStorageLocationEffects, TrunkEffects]),
        DataTableModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatButtonModule,
        MatSlideToggleModule,
    ],
  exports: [ArticleStorageLocationsComponent],
})
export class ArticleStorageLocationsModule {}
