import {Address} from "./address.dto";

export interface BaseData {
  name: string;
  notificationMail: string;
  notificationTime: string;
  address: Address;
  phone?: string;
  fax?: string;
  web?: string;
  note?: string;
}
