import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {Category} from "@knust/api-interfaces";
import {
  getAllCategory,
  getCategoryPage,
  getCategorySort,
  getCategoryTerm,
  getCategoryTotal
} from "./+state/category.selectors";
import {
  createCategory,
  deleteCategory,
  loadCategories,
  restoreCategory,
  setCategoryPage,
  setCategorySort,
  setCategoryTerm
} from "./+state/category.actions";

export const categoriesCommandMap: AbstractIndexCommands<Category> = {
  getAll: getAllCategory,
  getTotal: getCategoryTotal,
  getPage: getCategoryPage,
  setPage: setCategoryPage,
  getTerm: getCategoryTerm,
  setTerm: setCategoryTerm,
  getSort: getCategorySort,
  setSort: setCategorySort,
  load: loadCategories,
  createElement: createCategory,
  deleteElement: deleteCategory,
  restoreElement: restoreCategory
}

export const categoriesDisplayedColumns = ['id', 'label', 'deleteAction'];
export const categoriesColumnConfig = [
  {
    name: 'ID',
    refName: 'id'
  },
  {
    name: 'Name',
    refName: 'label'
  }
];
