<div class="details-header">
  <div class="header-row">
    <h1>Artikeldetails</h1>

    <div class="button-row">
      <button mat-flat-button color="warn" *ngIf="editMode && !newEntity" (click)="deleteArticle()">
        Artikel löschen
        <mat-icon>delete_outline</mat-icon>
      </button>

      <button mat-flat-button [color]="editMode ? undefined : 'primary'" (click)="switchEditMode()" *ngIf="!newEntity && (authService.isAdmin() || authService.isPurchasing())">
        <ng-container *ngIf="!editMode; else editButtonCancel">
          Artikel bearbeiten
          <mat-icon>edit</mat-icon>
        </ng-container>

        <ng-template #editButtonCancel>
          Bearbeiten abbrechen
          <mat-icon>close</mat-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <a (click)="location.back()" class="back-link">
    <mat-icon>arrow_back</mat-icon>
    Zurück
  </a>
</div>

<ng-container *ngIf="editMode">
  <form [formGroup]="articleForm" (ngSubmit)="saveArticle()">
    <div class="form-box">
      <section>
        <h3>Allgemein</h3>

        <div class="input-columns-container">
          <div class="input-column">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Beispielprodukt" formControlName="title">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Beschreibung</mat-label>
              <textarea matInput placeholder="Dieses Produkt ist für den Einsatz..." formControlName="description"></textarea>
            </mat-form-field>
          </div>

          <div class="input-column">
            <mat-form-field appearance="fill">
              <mat-label>Artikelnummer</mat-label>
              <input matInput placeholder="133337" formControlName="internalId">
            </mat-form-field>

            <mat-form-field appearance="fill" formGroupName="productCategory">
              <mat-label>Produktkategorie</mat-label>
              <mat-select formControlName="id">
                <mat-option>
                  <ngx-mat-select-search [formControl]="categoryFilterCtrl"></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let category of filteredCategories | async" [value]="category.id">
                  {{category.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-checkbox formControlName="active" color="primary">Aktiv</mat-checkbox>
            <mat-checkbox formControlName="soldOut" color="primary">Ausverkauft</mat-checkbox>
            <mat-checkbox formControlName="autoOrder" color="primary">Automatische Nachbestellung aktivieren</mat-checkbox>
          </div>
        </div>
      </section>

      <section>
        <h3>Bild</h3>

        <div class="input-columns-container">
          <div class="input-column article-image-column">
            <img [src]="articleForm.get('photo')?.value">
          </div>

          <div class="input-column">
            <div class="image-upload-container">
              <input type="file" (change)="selectFile($event)">
              <button mat-flat-button type="button" color="primary"
                      [disabled]="!selectedFile"
                      (click)="upload()">
                Datei hochladen
                <mat-icon>upload</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section>
        <h3>Artikelgrößen</h3>

        <div class="input-columns-container">
          <div class="input-column">
            <mat-form-field appearance="fill">
              <mat-label>Mengeneinheit</mat-label>
              <input matInput placeholder="Kilogramm" [matAutocomplete]="auto" formControlName="unitOfMeasure">

              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let unitOfMeasure of filteredUnits | async" [value]="unitOfMeasure">
                  {{unitOfMeasure}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Entnahmeintervall</mat-label>
              <input matInput placeholder="10" formControlName="samplingStep">
            </mat-form-field>
          </div>

          <div class="input-column">
            <mat-form-field appearance="fill">
              <mat-label>Gewicht (in kg)</mat-label>
              <input matInput placeholder="10" formControlName="weight" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Länge (in cm)</mat-label>
              <input matInput placeholder="10" formControlName="size_length" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Breite (in cm)</mat-label>
              <input matInput placeholder="10" formControlName="size_width" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Höhe (in cm)</mat-label>
              <input matInput placeholder="10" formControlName="size_height" type="number">
            </mat-form-field>
          </div>
        </div>
      </section>

      <div class="form-action-row">
        <button mat-flat-button color="primary" type="submit" class="save-btn" [disabled]="articleForm.invalid">
          Speichern
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>
  </form>
</ng-container>

<ng-container *ngIf="!editMode">
  <div class="form-box" *ngIf="(article | async) as article;">
    <div class="article-details-container">
      <div class="article-image-column">
        <img [src]="article?.photo ?? defaultImageURL" [alt]="">
      </div>

      <div class="article-information-column">
        <div class="main-text-container">
          <h2>{{ article.title }}</h2>
          <p> {{ article.description }} </p>
        </div>

        <div class="more-information-container">
          <p>Interne Artikelnummer: {{ article.internalId }}</p>
          <p>Produktkategorie: {{ article?.productCategory?.label }}</p>
          <p>Maßeinheit: {{ article.unitOfMeasure }}</p>
          <p>Entnahmeschritt: {{ article.samplingStep }}</p>
        </div>

        <div class="sizes-container">
          <p *ngIf="article?.weight">Gewicht: {{ (article?.weight | number) + 'kg'}}</p>
          <p *ngIf="article?.size_length">Länge: {{ (article?.size_length | number) + 'cm' }}</p>
          <p *ngIf="article?.size_width">Breite: {{ (article?.size_width | number) + 'cm' }}</p>
          <p *ngIf="article?.size_height">Höhe: {{ (article?.size_height | number) + 'cm' }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<h2>Lagerorte</h2>

<p class="add-element-notice" *ngIf="editMode">
  Lagerorte können nur durch eine Warenbuchung hinzugefügt werden.
</p>

<knust-article-storage-locations *ngIf="!newEntity"></knust-article-storage-locations>

<ng-container *ngIf="authService.isAdmin() || authService.isPurchasing()">
  <h2>Lieferanten-Artikel-Beziehungen</h2>

  <p class="add-element-notice" *ngIf="newEntity">
    Um eine Lieferanten-Artikel-Beziehung hinzuzufügen, müssen zunächst die Stammdaten abgespeichert werden.
  </p>

  <knust-vendor-articles *ngIf="!newEntity"></knust-vendor-articles>
</ng-container>
