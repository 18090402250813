import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {StorageLocation} from "@knust/api-interfaces";
import {FormBuilder, FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'knust-vendor-article-dialog',
  templateUrl: './storage-location-dialog.component.html',
  styleUrls: ['./storage-location-dialog.component.scss'],
})
export class StorageLocationDialogComponent {
  storageLocationForm = this.fb.group({
    label: ['', Validators.required],
    notificationMail: [null, Validators.email],
    orderCCMail: [null, Validators.email],
    address: this.fb.group({
      street: ['', Validators.required],
      zip: ['', Validators.required],
      city: ['', Validators.required],
      country: ['', Validators.required],
      addressSupplement: [''],
      latitude: new FormControl<number|null>(null),
      longitude: new FormControl<number|null>(null),
    }),
    note: ['']
  });

  constructor(
    public dialogRef: MatDialogRef<StorageLocationDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: StorageLocation,
  ) {
    console.log(data);
    if (data) {
      this.storageLocationForm.patchValue(data);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.storageLocationForm.valid) {
      this.dialogRef.close({
        id: this.data?.id ?? null,
        ...this.storageLocationForm.value,
        address: {
          id: this.data?.address.id ?? null,
          ...this.storageLocationForm.get('address')?.value,
        },
      });
    }
  }
}
