import {Component, HostListener} from "@angular/core";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {Trunk, TrunkArticle, TrunkBooking} from "@knust/api-interfaces";
import {ColumnConfig} from "../../reusable-components/data-table/column-config";
import {filter, map, merge, tap} from "rxjs";
import {getRouteParams} from "../../+state/root.selectors";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {Store} from "@ngrx/store";
import {StoreRootState} from "../../+state/root.reducer";
import {
  getAllTrunkArticles,
  getAllTrunkBookings,
  getSelected,
  getTrunkArticlesPage,
  getTrunkArticlesSort,
  getTrunkArticlesTerm,
  getTrunkArticlesTotal,
  getTrunkBookingsPage,
  getTrunkBookingsSort,
  getTrunkBookingsTerm,
  getTrunkBookingsTotal
} from "../+state/trunk.selectors";
import {
  loadTrunkArticles,
  loadTrunkBookings,
  resetTrunk,
  saveTrunkNote,
  setTrunkArticlesPage,
  setTrunkArticlesSort,
  setTrunkArticlesTerm,
  setTrunkBookingsPage,
  setTrunkBookingsSort,
  setTrunkBookingsTerm
} from "../+state/trunk.actions";
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {FormControl} from "@angular/forms";
import {TrunkService} from "../../utility/services/trunk.service";

@Component({
  selector: 'knust-trunks-detail',
  templateUrl: './trunks-detail.component.html',
  styleUrls: ['./trunks-detail.component.scss'],
})
export class TrunksDetailComponent {
  takeStockInputs: number[] = [];
  takeStockNotes: string[] = [];

  noteControl = new FormControl('');
  noteChange = false;

  closingTrunk = false;

  commandMap: AbstractIndexCommands<TrunkArticle> = {
    getAll: getAllTrunkArticles,
    getTotal: getTrunkArticlesTotal,
    getPage: getTrunkArticlesPage,
    setPage: setTrunkArticlesPage,
    getTerm: getTrunkArticlesTerm,
    setTerm: setTrunkArticlesTerm,
    getSort: getTrunkArticlesSort,
    setSort: setTrunkArticlesSort,
    load: loadTrunkArticles,
  }

  displayedColumns = [
    'storageLocationArticle.article.title',
    'storageLocationArticle.storageLocation.label',
    'withdrawnStock',
    'usedStock',
    'takeFromTrunk'
  ];
  columnConfig: ColumnConfig[] = [
    {
      name: 'Artikelname',
      refName: 'storageLocationArticle',
      nestedName: ['article', 'title'],
      useNestedName: true
    },
    {
      name: 'Lagerort',
      refName: 'storageLocationArticle',
      nestedName: ['storageLocation', 'label'],
      useNestedName: true
    },
    {
      name: 'Reservierte Menge',
      refName: 'withdrawnStock',
    },
    {
      name: 'Entnommene Menge',
      refName: 'usedStock'
    }
  ];

  commandMapBookings: AbstractIndexCommands<TrunkBooking> = {
    getAll: getAllTrunkBookings,
    getTotal: getTrunkBookingsTotal,
    getPage: getTrunkBookingsPage,
    setPage: setTrunkBookingsPage,
    getTerm: getTrunkBookingsTerm,
    setTerm: setTrunkBookingsTerm,
    getSort: getTrunkBookingsSort,
    setSort: setTrunkBookingsSort,
    load: loadTrunkBookings,
  }

  displayedColumnsBookings = [
    'createdDate',
    'trunkArticle.storageLocationArticle.article.title',
    'trunkArticle.storageLocationArticle.storageLocation.label',
    'amount',
    'totalAmount',
    'note'
  ];
  columnConfigBookings: ColumnConfig[] = [
    {
      name: 'Datum',
      refName: 'createdDate',
      type: 'date',
      dateFormat: 'dd.MM.yy - HH:mm',
    },
    {
      name: 'Artikel',
      refName: 'trunkArticle',
      nestedName: ['storageLocationArticle', 'article', 'title'],
      useNestedName: true
    },
    {
      name: 'Lagerort',
      refName: 'trunkArticle',
      nestedName: ['storageLocationArticle', 'storageLocation', 'label'],
      useNestedName: true
    },
    {
      name: 'Entnommene Menge',
      refName: 'amount'
    },
    {
      name: 'Entnommene Menge (gesamt)',
      refName: 'totalAmount'
    },
    {
      name: 'Notiz',
      refName: 'note'
    }
  ];

  newEntity = false;
  trunk = merge(
    this.store.select(getRouteParams).pipe(
      filter(params => params['trunkId'] && params['trunkId'] === NEW_ENTITY),
      tap(() => {
        console.log('New Entity');
        this.newEntity = true;
      }),
      map(() => ({
        owner: 0,
        completed: false,
        isCart: false,
        logMail: '',
        articles: [],
      }) as Omit<Trunk, 'id'>)
    ),
    this.store.select(getSelected).pipe(
      filter(selected => !!selected),
      tap((selected) => {
        if (selected) {
          this.noteControl.patchValue(selected.note ?? null);
          this.noteChange = false;
        }
        this.newEntity = false;
      })
    )
  );

  constructor(private store: Store<StoreRootState>,
              private dialog: MatDialog,
              private trunkService: TrunkService,
              public location: Location) {
    this.trunk.subscribe(trunk => console.log(trunk));
  }

  resetTrunk() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Abbuchungen wirklich stornieren?',
        text: 'Alle Abbuchungen werden storniert und die Entnahme auf den Anfangszustand zurückgesetzt.',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(resetTrunk());
        }
      })
  }

  saveNote() {
    this.store.dispatch(saveTrunkNote({note: this.noteControl.value}));

    this.noteChange = false;
  }

  canDeactivate(): boolean {
    return this.closingTrunk || (this.takeStockInputs.length === 0 && this.takeStockNotes.length === 0 && !this.noteChange);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = "Sie haben noch ungespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?";
    }
  }
}
