import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as DeliveryActions from "./delivery.actions";
import {
  getCompletedFilter,
  getDeliveryArticlesQuery,
  getDeliveryQuery, getDeliveryReportsQuery,
  getReportsArticleFilter,
  getReportsCompletedFilter,
  getReportsDateEndFilter,
  getReportsDateStartFilter,
  getReportsUserFilter,
  getReportsVendorFilter,
  getSelected,
  getSelectedId
} from "./delivery.selectors";
import {DeliveryPartialState} from "./delivery.reducer";
import {DeliveryIndexComponent} from "../delivery-index/delivery-index.component";
import {onNavigation} from "../../+state/on-navigation.operator";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {DeliveryService} from "../../utility/services/delivery.service";
import {DeliveryDetailComponent} from "../delivery-detail/delivery-detail.component";
import {Router} from "@angular/router";
import {ReportsComponent} from "../../reports/reports.component";
import {getTrunkReportsQuery} from "../../trunks/+state/trunk.selectors";

@Injectable()
export class DeliveryEffects {
  deliveryIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(DeliveryIndexComponent),
      map( () => DeliveryActions.loadDelivery())
    )
  );

  deliveryView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(DeliveryDetailComponent),
      tap(() => console.log('Loading Delivery Details')),
      map( (action) => {
        if (action.payload.routerState.params['deliveryId'] === NEW_ENTITY) {
          return DeliveryActions.unsetSelectedDelivery();
        }

        const deliveryId = parseInt(action.payload.routerState.params['deliveryId'], 10);

        if (!deliveryId) {
          throw new Error('delivery id is missing');
        }

        return DeliveryActions.setSelectedDelivery({ id: deliveryId });
      })
    )
  );

  deliveryReports$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ReportsComponent),
      tap(() => DeliveryActions.resetReportFilters()),
      map( () => DeliveryActions.loadDeliveryReports())
    )
  );

  selectDelivery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.setSelectedDelivery),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            console.log('Not selected - loading!')
            return this.deliveryService.loadDeliveryDetails(action.id).pipe(
              map(res => DeliveryActions.loadDeliveryDetailSuccess({ delivery: res }))
            );
          }

          return of(DeliveryActions.loadDeliveryDetailUnneeded());
        }),
        catchError((error) => {
          return of(DeliveryActions.loadDeliveryFailure({ error }));
        })
      )
  );

  loadDelivery$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.loadDelivery),
        concatLatestFrom(() => [
          this.store.select(getDeliveryQuery),
          this.store.select(getCompletedFilter)
        ]),
        switchMap(([action, query, completed]) => {
          return this.deliveryService.loadDeliveries('/' + query + '&completed=' + (completed ? '1' : '0')).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => DeliveryActions.loadDeliverySuccess({ delivery: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
          return of(DeliveryActions.loadDeliveryFailure({ error }));
        })
      )
  );

  loadDeliveryReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.loadDeliveryReports),
        concatLatestFrom(() => [
          this.store.select(getDeliveryReportsQuery),
          this.store.select(getReportsCompletedFilter),
          this.store.select(getReportsUserFilter),
          this.store.select(getReportsArticleFilter),
          this.store.select(getReportsVendorFilter),
          this.store.select(getReportsDateStartFilter),
          this.store.select(getReportsDateEndFilter),
        ]),
        switchMap(([action, query, completed, userId, articleId, vendorId, startDate, endDate]) => {
          let completeQuery = query;

          console.log('LOAD DELIVERY REPORTS', startDate, startDate?.toISOString());
          completeQuery += ('&completed=' + (completed ? '1' : '0'));
          completeQuery += ('&userId=' + (userId ?? ''));
          completeQuery += ('&articleId=' + (articleId ?? ''));
          completeQuery += ('&vendorId=' + (vendorId ?? ''));
          completeQuery += ('&startDate=' + (startDate ? startDate.toISOString().substring(0, 10) :  ''));
          completeQuery += ('&endDate=' + (endDate ? endDate.toISOString().substring(0, 10) : ''));
          completeQuery += ('&exportData=' + (action.download ? '1' : '0'));

          return this.deliveryService.loadDeliveryReports(completeQuery).pipe(
            map(res => {
              if (!action.download) {
                return DeliveryActions.loadDeliveryReportsSuccess({deliveryReports: res.items, total: res.meta.totalItems});
              } else {
                const downloadUrl = this.deliveryService.downloadDeliveryReport((res as any).filePath);

                const a = document.createElement("a");
                a.href = downloadUrl;
                const filename = "export-wareneingangsbuchungen";
                a.setAttribute("download", filename + ".csv");
                a.setAttribute("target", "_blank");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                return DeliveryActions.downloadDeliveryReport({ url: (res as any).filePath });
              }
            }),
          );
        }),
        catchError((error) => {
          return of(DeliveryActions.loadDeliveryFailure({ error }));
        })
      )
  );

  loadDeliveryArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DeliveryActions.setSelectedDelivery,
          DeliveryActions.loadDeliveryArticles
        ),
        concatLatestFrom(() => [
          this.store.select(getDeliveryArticlesQuery),
          this.store.select(getSelectedId)
        ]),
        switchMap(([action, query, deliveryId]) => {
          if (deliveryId) {
            return this.deliveryService.loadDeliveryArticles(deliveryId, query).pipe(
              tap(() => console.log('Load DeliveryArticles Complete')),
              map(res => DeliveryActions.loadDeliveryArticlesSuccess({deliveryArticles: res.items, total: res.meta.totalItems})),
            );
          }

          return of(DeliveryActions.loadDeliveryArticlesFailure({ error: 'Keine Warenbuchung ausgewählt.' }));
        }),
        catchError((error) => {
          return of(DeliveryActions.loadDeliveryArticlesFailure({ error }));
        })
      )
  );

  updateDeliveryArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.saveDeliverySuccess),
        switchMap(() => {
          return of(DeliveryActions.loadDeliveryArticles());
        })
      ),
  )

  addDeliveryArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.addDeliveryArticle),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, selectedId]) => {
          if (selectedId) {
            return this.deliveryService.addDeliveryArticle(selectedId, action.deliveryArticle).pipe(
              map(res => DeliveryActions.addDeliveryArticleSuccess( { deliveryArticle: res }))
            )
          }

          return of(DeliveryActions.addDeliveryArticleFailure({ error: 'Keine Wareneingangsbuchung ausgewählt.' }));
        })
      )
  )

  deleteDeliveryArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeliveryActions.deleteDeliveryArticle),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, selectedId]) => {
          if (selectedId) {
            return this.deliveryService.deleteDeliveryArticle(selectedId, action.deliveryArticle).pipe(
              map(() => DeliveryActions.deleteDeliveryArticleSuccess( { deliveryArticle: action.deliveryArticle }))
            )
          }

          return of(DeliveryActions.deleteDeliveryArticleFailure({ error: 'Keine Wareneingangsbuchung ausgewählt.' }));
        })
      )
  )

  setDeliveryFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryActions.setDeliveryPage,
        DeliveryActions.setDeliveryTerm,
        DeliveryActions.setDeliverySort,
        DeliveryActions.changeCompletedFilter,
      ),
      map(() => DeliveryActions.loadDelivery())
    )
  );

  setDeliveryReportsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryActions.setDeliveryReportsPage,
        DeliveryActions.setDeliveryReportsTerm,
        DeliveryActions.setDeliveryReportsSort,
        DeliveryActions.changeReportsCompletedFilter,
        DeliveryActions.setReportsUserFilter,
        DeliveryActions.setReportsArticleFilter,
        DeliveryActions.setReportsVendorFilter,
        DeliveryActions.setReportsDateFilter,
      ),
      map(() => DeliveryActions.loadDeliveryReports())
    )
  );

  setDeliveryArticlesFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryActions.setDeliveryArticlesPage,
        DeliveryActions.setDeliveryArticlesTerm,
        DeliveryActions.setDeliveryArticlesSort
      ),
      map(() => DeliveryActions.loadDeliveryArticles())
    )
  );

  createDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.createDelivery),
      switchMap((action) => {
        return this.deliveryService.saveDelivery(action.delivery).pipe(
          tap(() => console.log('Save Delivery Effect Subscribe')),
          map(created =>
            DeliveryActions.saveDeliverySuccess( { delivery: created, insert: true })
          ),
          catchError(error => {
            return of(DeliveryActions.saveDeliveryFailure({ error }));
          })
        )
      })
    )
  );

  updateDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.updateDelivery),
      switchMap((action) => {
        return this.deliveryService.saveDelivery(action.delivery).pipe(
          map(updated =>
            DeliveryActions.saveDeliverySuccess( { delivery: updated, insert: false })
          ),
          catchError(error => {
            return of(DeliveryActions.saveDeliveryFailure({ error }));
          })
        )
      })
    )
  );

  saveDeliverySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryActions.saveDeliverySuccess
      ),
      filter(action => action.insert),
      tap( action => {
        console.log('Starting Navigation', action.delivery);
        this.router.navigate(['wareneingangsbuchung', action.delivery.id]);
      })
    ), {dispatch: false}
  );

  deleteDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.deleteDelivery),
      switchMap((action) => {
        return this.deliveryService.deleteDelivery(action.delivery).pipe(
          map(() =>
            DeliveryActions.deleteDeliverySuccess( { id: action.delivery.id ?? 0 })
          ),
          catchError(error => {
            return of(DeliveryActions.deleteDeliveryFailure({ error }));
          })
        )
      })
    )
  );

  closeDelivery$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.closeDelivery),
      switchMap((action) => {
        return this.deliveryService.closeDelivery(action.deliveryId).pipe(
          map(res =>
            DeliveryActions.closeDeliverySuccess({ deliveryId: res.deliveryId })
          )
        )
      })
    )
  )

  closeDeliverySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DeliveryActions.closeDeliverySuccess
      ),
      tap( () => {
        this.router.navigate(['wareneingangsbuchung']);
      })
    ), {dispatch: false}
  );

  saveLabel = createEffect(() =>
    this.actions$.pipe(
      ofType(DeliveryActions.saveDeliveryLabel),
      concatLatestFrom(() => [
        this.store.select(getSelectedId)
      ]),
      switchMap(([action, selectedId]) => {
        if (selectedId) {
          return this.deliveryService.saveLabel(action.label, selectedId).pipe(
            map(res => DeliveryActions.saveDeliveryLabelSuccess( { label: res.label } ))
          )
        }

        return of(DeliveryActions.saveDeliveryLabelFailure( { error: 'Es ist keine Entnahme ausgewählt.' }));
      }),
    )
  )

  constructor(private readonly actions$: Actions,
              private router: Router,
              private deliveryService: DeliveryService,
              private store: Store<DeliveryPartialState>) {
  }
}
