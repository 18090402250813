import {Component} from "@angular/core";
import {FormBuilder, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL, LOGO_URL} from "../../utility/constants/api.constants";
import {Router} from "@angular/router";

@Component({
  selector: 'knust-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
})
export class PasswordResetComponent {
  logoPath = LOGO_URL;

  currentStep = 0;
  currentButtonText = 'Verifizierungscode anfordern';
  currentButtonIcon = 'mail_outline'

  pwResetForm = this.fb.group({
    username: ['', Validators.required],
    verificationCode: [''],
    newPassword: [''],
  });

  constructor(private fb: FormBuilder,
              private router: Router,
              private http: HttpClient) {
    this.pwResetForm.get('verificationCode')?.disable();
    this.pwResetForm.get('newPassword')?.disable();
  }

  nextStep() {
    switch (this.currentStep) {
      case 0: {
        this.http.post(API_BASE_URL + '/auth/reset', {...this.pwResetForm.value})
          .subscribe(() => {
            this.currentStep++;

            this.pwResetForm.get('verificationCode')?.addValidators(Validators.required);
            this.pwResetForm.get('newPassword')?.addValidators(Validators.required);

            this.pwResetForm.get('verificationCode')?.enable();
            this.pwResetForm.get('newPassword')?.enable();

            this.currentButtonText = 'Neues Passwort setzen';
            this.currentButtonIcon = 'send';
        });
        break;
      }

      case 1: {
        this.http.post(API_BASE_URL + '/auth/reset', {...this.pwResetForm.value})
          .subscribe(() => {
            this.router.navigate(['/login']);
          });
        break;
      }
    }
  }
}
