import {Injectable} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {AuthService} from "../services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>,
            next: HttpHandler): Observable<HttpEvent<any>> {
    const currentToken = this.authService.currentToken.value;

    if (currentToken) {
      req.headers.set('Authorization', 'Bearer ' + currentToken);

      const cloned = req.clone({
        headers: req.headers.set("Authorization",
          "Bearer " + currentToken)
      });

      return next.handle(cloned);
    }

    return next.handle(req);
  }
}
