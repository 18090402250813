import {Component, Input, OnInit} from "@angular/core";
import {Store} from "@ngrx/store";
import {
  changeEmptyFilter,
  createArticleStorageLocation,
  deleteArticleStorageLocation,
  loadArticleStorageLocations,
  setArticleStorageLocationPage,
  setArticleStorageLocationSort,
  setArticleStorageLocationTerm,
  updateArticleStorageLocation
} from "./+state/article-storage-location.actions";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {StorageLocationArticle, UserEnum} from "@knust/api-interfaces";
import {
  getAllArticleStorageLocation,
  getArticleStorageLocationPage,
  getArticleStorageLocationSort,
  getArticleStorageLocationTerm,
  getArticleStorageLocationTotal,
  getEmptyFilter
} from "./+state/article-storage-location.selectors";
import {AuthService} from "../../utility/services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {
  EditArticleStorageLocationComponent
} from "./edit-article-storage-location/edit-article-storage-location.component";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'knust-article-storage-locations',
  templateUrl: './article-storage-locations.component.html',
  styleUrls: ['./article-storage-locations.component.scss'],
})
export class ArticleStorageLocationsComponent implements OnInit {
  @Input() filterForId = 0;

  commandMap: AbstractIndexCommands<StorageLocationArticle> = {
    getAll: getAllArticleStorageLocation,
    getTotal: getArticleStorageLocationTotal,
    getPage: getArticleStorageLocationPage,
    setPage: setArticleStorageLocationPage,
    getTerm: getArticleStorageLocationTerm,
    setTerm: setArticleStorageLocationTerm,
    getSort: getArticleStorageLocationSort,
    setSort: setArticleStorageLocationSort,
    load: loadArticleStorageLocations,
    createElement: createArticleStorageLocation,
    deleteElement: deleteArticleStorageLocation,
  }

  displayedColumns = ['storageLocation.label', 'storageLocation.address.street', 'actualStock', 'reservedStock', 'buyStock'];
  tableConfig = [
    {
      name: 'ID',
      refName: 'id'
    },
    {
      name: 'Lagerort',
      refName: 'storageLocation',
      nestedName: 'label',
      useNestedName: true
    },
    {
      name: 'Verfügbar',
      refName: 'actualStock'
    },
    {
      name: 'Reserviert',
      refName: 'reservedStock'
    },
    {
      name: 'Meldebestand',
      refName: 'notificationStock'
    },
    {
      name: 'Eiserner Bestand',
      refName: 'autoOrderLessStock'
    },
    {
      name: 'Zielbestand',
      refName: 'targetStock'
    },
    {
      name: 'Lagerstatus',
      refName: 'storageStatus',
      type: 'textToColor'
    },
    {
      name: 'Adresse',
      refName: 'storageLocation',
      nestedName: ['address', 'street'],
      useNestedName: true,
    },
  ];

  showEmpty = false;

  constructor(private store: Store,
              private authService: AuthService,
              private dialog: MatDialog) {
    this.store.select(getEmptyFilter)
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(emptyFilter => this.showEmpty = emptyFilter);

    const userType = this.authService.currentUser.value.type;

    if (userType === UserEnum.ADMIN || userType === UserEnum.PURCHASING) {
      this.displayedColumns = [
        'storageLocation.label',
        'storageLocation.address.street',
        'actualStock',
        'reservedStock',
        'autoOrderLessStock',
        'notificationStock',
        'targetStock',
        'storageStatus',
        'buyStock'
      ];
    }
  }

  ngOnInit() {
    this.store.dispatch(loadArticleStorageLocations({ filterId: this.filterForId }));
  }

  editArticleStorageLocation(articleStorageLocation: StorageLocationArticle) {
    const userType = this.authService.currentUser.value.type;

    if (userType === UserEnum.ADMIN || userType === UserEnum.PURCHASING) {
      this.dialog.open(EditArticleStorageLocationComponent, {
        data: articleStorageLocation,
        width: '80vw',
        maxWidth: '800px'
      })
        .afterClosed()
        .subscribe(articleStorageLocation => {
          if (articleStorageLocation) {
            this.store.dispatch(updateArticleStorageLocation({ articleStorageLocation }))
          }
        });
    }
  }

  setEmptyFilter() {
    this.store.dispatch(changeEmptyFilter());
  }
}
