import {createFeatureSelector, createSelector} from "@ngrx/store";

import {BOOKINGS_FEATURE_KEY, bookingsAdapter, State} from "./bookings.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'Booking' feature state managed by NgRx
export const getBookingState = createFeatureSelector<State>(BOOKINGS_FEATURE_KEY);

const { selectAll, selectEntities } = bookingsAdapter.getSelectors();

export const getCompletedFilter = createSelector(
  getBookingState,
  (state: State) => state.showCompleted
)

export const getReportsCompletedFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.showCompleted
)

export const getReportsUserFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.userId
)

export const getReportsArticleFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.articleId
)

export const getReportsVendorFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.vendorId
)

export const getReportsDateStartFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.dateStart
)

export const getReportsDateEndFilter = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.dateEnd
)

export const getBookingLoaded = createSelector(
  getBookingState,
  (state: State) => state.loaded
);

export const getBookingError = createSelector(
  getBookingState,
  (state: State) => state.error
);

export const getBookingTotal = createSelector(
  getBookingState,
  (state: State) => state.total
);

export const getBookingArticlesTotal = createSelector(
  getBookingState,
  (state: State) => state.articleQuery.total
);

export const getBookingReportsTotal = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.total
);

export const getBookingPage = createSelector(
  getBookingState,
  (state: State) => state.page
);

export const getBookingTerm = createSelector(
  getBookingState,
  (state: State) => state.term
);

export const getBookingSort = createSelector(
  getBookingState,
  (state: State) => state.sort
);

export const getBookingArticlesPage = createSelector(
  getBookingState,
  (state: State) => state.articleQuery.page
);

export const getBookingArticlesTerm = createSelector(
  getBookingState,
  (state: State) => state.articleQuery.term
);

export const getBookingArticlesSort = createSelector(
  getBookingState,
  (state: State) => state.articleQuery.sort
);

export const getBookingReportsPage = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.page
);

export const getBookingReportsTerm = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.term
);

export const getBookingReportsSort = createSelector(
  getBookingState,
  (state: State) => state.reportsQuery.sort
);

export const getBookingQuery = createSelector(
  getBookingState,
  (state: State) => StateToQuery(state)
);

export const getBookingArticlesQuery = createSelector(
  getBookingState,
  (state: State) => StateToQuery(state.articleQuery)
);

export const getBookingReportsQuery = createSelector(
  getBookingState,
  (state: State) => StateToQuery(state.reportsQuery)
);

export const getAllBookings = createSelector(
  getBookingState,
  (state: State) => selectAll(state)
);

export const getAllBookingArticles = createSelector(
  getBookingState,
  (state: State) => state.articles
);

export const getAllBookingReports = createSelector(
  getBookingState,
  (state: State) => state.reports
);

export const getBookingEntities = createSelector(
  getBookingState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getBookingState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getBookingEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
