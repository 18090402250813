import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as StorageLocationArticleActions from "./storage-location-article.actions";
import {StorageLocationArticle} from "@knust/api-interfaces";
import {resetStorageLocationArticles} from "./storage-location-article.actions";

export const STORAGE_LOCATION_ARTICLE_FEATURE_KEY = 'storageLocationArticle';

export interface State extends EntityState<StorageLocationArticle> {
  selectedId?: number; // which StorageLocationArticle record has been selected
  loaded: boolean; // has the StorageLocationArticle list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
  showEmpty: boolean;
  filterId?: number; // Nach welchem Lagerort gefiltert werden soll
  filterBooking: {
    bookingId?: string | number | undefined,
    completed?: boolean,
  }; // Nach welcher Buchung gefiltert werden soll
}

export interface StorageLocationArticlePartialState {
  readonly [STORAGE_LOCATION_ARTICLE_FEATURE_KEY]: State;
}

export const storageLocationArticleAdapter: EntityAdapter<StorageLocationArticle> =
  createEntityAdapter<StorageLocationArticle>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null,
  filterBooking: {}
};

export const initialState: State = storageLocationArticleAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showEmpty: false,
  ...initialPaginationState
});

const storageLocationArticleReducer = createReducer(
  initialState,
  on(StorageLocationArticleActions.loadStorageLocationArticles, (state) => ({ ...state, loaded: false, error: null })),
  on(StorageLocationArticleActions.loadStorageLocationArticlesSuccess, (state, { storageLocationArticle, total }) => {
    return storageLocationArticleAdapter.setAll(storageLocationArticle, {...state, loaded: true, total});
  }),
  on(StorageLocationArticleActions.loadStorageLocationArticlesFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationArticleActions.resetStorageLocationArticles, (state) => storageLocationArticleAdapter.removeAll({ ...state, total: 0 })),
  on(StorageLocationArticleActions.changeEmptyFilter, (state) => ({...state, showEmpty: !state.showEmpty})),
  on(StorageLocationArticleActions.setLocationFilter, (state, { filterId }) => ({...state, filterId })),
  on(StorageLocationArticleActions.setBookingFilter, (state, { bookingId, completed }) => ({...state, filterBooking: { bookingId, completed } })),
  on(StorageLocationArticleActions.setSelectedStorageLocationArticle, (state, { id }) => ({ ...state, selectedId: id })),
  on(StorageLocationArticleActions.loadStorageLocationArticleDetailSuccess, (state, { storageLocationArticle }) =>
    storageLocationArticleAdapter.setAll([storageLocationArticle], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(StorageLocationArticleActions.loadStorageLocationArticleDetailFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationArticleActions.setStorageLocationArticlePage, (state, { page }) => ({ ...state, page })),
  on(StorageLocationArticleActions.setStorageLocationArticleTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(StorageLocationArticleActions.setStorageLocationArticleSort, (state, { sort }) => ({ ...state, sort })),
  on(StorageLocationArticleActions.clearStorageLocationArticle, (state) =>
    storageLocationArticleAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(StorageLocationArticleActions.saveStorageLocationArticleSuccess, (state, { storageLocationArticle, insert }) =>
    storageLocationArticleAdapter.upsertOne(storageLocationArticle, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(StorageLocationArticleActions.saveStorageLocationArticleFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationArticleActions.deleteStorageLocationArticleSuccess, (state, { id }) =>
    storageLocationArticleAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(StorageLocationArticleActions.deleteStorageLocationArticleFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return storageLocationArticleReducer(state, action);
}
