<h1 mat-dialog-title>Lagerort editieren</h1>

<form mat-dialog-content [formGroup]="storageLocationArticleForm" (keydown.enter)="save()">
  <mat-form-field appearance="fill">
    <mat-label>Eiserner Bestand</mat-label>
    <input matInput type="number" formControlName="autoOrderLessStock">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Meldebestand</mat-label>
    <input matInput type="number" formControlName="notificationStock">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Zielbestand</mat-label>
    <input matInput type="number" formControlName="targetStock">
  </mat-form-field>
</form>
<div mat-dialog-actions>
  <button mat-flat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()">Bestätigen</button>
</div>
