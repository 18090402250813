import {Address} from "./address.dto";
import {CoreProperties} from "./core-properties.dto";

export interface Vendor extends CoreProperties {
  name?: string;
  vendorNumber: string;
  // TODO: Implement Organization
  organization: any;
  deliveryAddress: Address;
}
