import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {StorageLocation} from "@knust/api-interfaces";

export const loadStorageLocation = createAction('[StorageLocation] Load StorageLocation');

export const loadStorageLocationSuccess = createAction(
  '[StorageLocation/API] Load StorageLocation Success',
  props<{ storageLocation: StorageLocation[], total: number }>()
);

export const loadStorageLocationFailure = createAction(
  '[StorageLocation/API] Load StorageLocation Failure',
  props<{ error: any }>()
);

export const setSelectedStorageLocation = createAction(
  '[StorageLocation/API] Set selected StorageLocation',
  props<{ id: number }>()
);

export const loadStorageLocationDetailUnneeded = createAction(
  '[StorageLocation/API] Load StorageLocation Detail Unneeded'
);

export const loadStorageLocationDetailSuccess = createAction(
  '[StorageLocation/API] Load StorageLocation Detail Success',
  props<{ storageLocation: StorageLocation }>()
);

export const loadStorageLocationDetailFailure = createAction(
  '[StorageLocation/API] Load StorageLocation Detail Failure',
  props<{ error: any }>()
);

export const setStorageLocationPage = createAction(
  '[StorageLocation/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setStorageLocationTerm = createAction(
  '[StorageLocation/API] Set Term',
  props<{ term: string }>()
);

export const setStorageLocationSort = createAction(
  '[StorageLocation/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearStorageLocation = createAction(
  '[StorageLocation/API] Clear'
);

export const createStorageLocation = createAction(
  '[StorageLocation/API] Create StorageLocation',
  props<{ storageLocation: Omit<StorageLocation, 'id'> }>()
);

export const updateStorageLocation = createAction(
  '[StorageLocation/API] Update StorageLocation',
  props<{ storageLocation: StorageLocation }>()
);

export const saveStorageLocationSuccess = createAction(
  '[StorageLocation/API] Save StorageLocation Success',
  props<{ storageLocation: StorageLocation, insert: boolean }>()
);

export const saveStorageLocationFailure = createAction(
  '[StorageLocation/API] Save StorageLocation Failure',
  props<{ error: any }>()
);

export const deleteStorageLocation = createAction(
  '[StorageLocation/API] Delete StorageLocation',
  props<{ storageLocation: StorageLocation }>()
);

export const deleteStorageLocationSuccess = createAction(
  '[StorageLocation/API] Delete StorageLocation Success',
  props<{ id: number | string }>()
);

export const deleteStorageLocationFailure = createAction(
  '[StorageLocation/API] Delete StorageLocation Failure',
  props<{ error: any }>()
);

export const restoreStorageLocation = createAction(
  '[StorageLocation/API] Restore StorageLocation',
  props<{ element: StorageLocation }>()
);

export const restoreStorageLocationSuccess = createAction(
  '[StorageLocation/API] Restore StorageLocation Success',
  props<{ storageLocation: StorageLocation }>()
);

export const restoreStorageLocationFailure = createAction(
  '[StorageLocation/API] Restore StorageLocation Failure',
  props<{ error: any }>()
);
