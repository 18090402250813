import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Vendor} from "@knust/api-interfaces";

export const loadVendor = createAction('[Vendor] Load Vendor');

export const loadVendorSuccess = createAction(
  '[Vendor/API] Load Vendor Success',
  props<{ vendor: Vendor[], total: number }>()
);

export const loadVendorFailure = createAction(
  '[Vendor/API] Load Vendor Failure',
  props<{ error: any }>()
);

export const setSelectedVendor = createAction(
  '[Vendor/API] Set selected Vendor',
  props<{ id: number }>()
);

export const loadVendorDetailUnneeded = createAction(
  '[Vendor/API] Load Vendor Detail Unneeded'
);

export const loadVendorDetailSuccess = createAction(
  '[Vendor/API] Load Vendor Detail Success',
  props<{ vendor: Vendor }>()
);

export const loadVendorDetailFailure = createAction(
  '[Vendor/API] Load Vendor Detail Failure',
  props<{ error: any }>()
);

export const setVendorPage = createAction(
  '[Vendor/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setVendorTerm = createAction(
  '[Vendor/API] Set Term',
  props<{ term: string }>()
);

export const setVendorSort = createAction(
  '[Vendor/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearVendor = createAction(
  '[Vendor/API] Clear'
);

export const createVendor = createAction(
  '[Vendor/API] Create Vendor',
  props<{ vendor: Omit<Vendor, 'id'> }>()
);

export const updateVendor = createAction(
  '[Vendor/API] Update Vendor',
  props<{ vendor: Vendor }>()
);

export const saveVendorSuccess = createAction(
  '[Vendor/API] Save Vendor Success',
  props<{ vendor: Vendor, insert: boolean }>()
);

export const saveVendorFailure = createAction(
  '[Vendor/API] Save Vendor Failure',
  props<{ error: any }>()
);

export const deleteVendor = createAction(
  '[Vendor/API] Delete Vendor',
);

export const deleteVendorSuccess = createAction(
  '[Vendor/API] Delete Vendor Success',
  props<{ id: number | string }>()
);

export const deleteVendorFailure = createAction(
  '[Vendor/API] Delete Vendor Failure',
  props<{ error: any }>()
);

export const restoreVendor = createAction(
  '[Vendor/API] Restore Vendor',
  props<{ element: Vendor }>()
);

export const restoreVendorSuccess = createAction(
  '[Vendor/API] Restore Vendor Success',
  props<{ vendor: Vendor }>()
);

export const restoreVendorFailure = createAction(
  '[Vendor/API] Restore Vendor Failure',
  props<{ error: any }>()
);
