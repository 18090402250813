import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Article} from "@knust/api-interfaces";

@Component({
  selector: 'knust-reserve-article-dialog',
  templateUrl: './reserve-article-dialog.component.html',
  styleUrls: ['./reserve-article-dialog.component.scss'],
})
export class ReserveArticleDialogComponent {
  storageLocationId;

  constructor(
    public dialogRef: MatDialogRef<ReserveArticleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { article: Article, storageLocationId?: number }
  ) {
    console.log(data);

    this.storageLocationId = data.storageLocationId ?? 0;
  }

}
