import {Category} from "./category.dto";
import {CoreProperties} from "./core-properties.dto";

export interface Article extends CoreProperties {
  internalId: string;
  title: string;
  description: string;
  active: boolean;
  soldOut: boolean;
  unitOfMeasure: string;
  samplingStep: number;
  preAssignWithdrawal: number;
  autoOrder: boolean;
  notificationStatus: number;
  // mainVendorArticle: VendorArticle;
  // mainVendor: Vendor;
  // vendorArticles: VendorArticle[];
  photo?: string;
  productCategory?: Category;
  weight?: number;
  size_length?: number;
  size_width?: number;
  size_height?: number;
  // storageLocationArticles: StorageLocationArticle[];
}
