import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {StorageLocationArticle} from "@knust/api-interfaces";

@Component({
  selector: 'knust-edit-article-storage-location',
  templateUrl: './edit-article-storage-location.component.html',
  styleUrls: ['./edit-article-storage-location.component.scss'],
})
export class EditArticleStorageLocationComponent {
  storageLocationArticleForm: UntypedFormGroup = this.fb.group({
    autoOrderLessStock: [null, Validators.required],
    notificationStock: [null, Validators.required],
    targetStock: [null, Validators.required],
  });

  constructor(public dialogRef: MatDialogRef<EditArticleStorageLocationComponent>,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: StorageLocationArticle) {
    this.storageLocationArticleForm.patchValue(data);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.storageLocationArticleForm.valid) {
      this.dialogRef.close({
        ...this.data,
        ...this.storageLocationArticleForm.value
      });
    }
  }
}
