import {Pipe, PipeTransform} from "@angular/core";
import {User} from "@knust/api-interfaces";
import {UserService} from "../services/user.service";

@Pipe({
  name: 'getUserById'
})
export class GetUserByIdPipe implements PipeTransform {
  constructor(private userService: UserService) {
  }

  transform(user: number | User | undefined): string {
    if (!user) {
      return 'Unbekannter Nutzer';
    }

    if (typeof user === 'number') {
      const userObject = this.userService.allUsers.value.filter(allUser => allUser.id === user)[0];

      if (userObject) {
        return userObject.person.firstName + ' ' + userObject.person.lastName;
      } else {
        return 'Unbekannter Nutzer';
      }
    }

    return user.person.firstName + ' ' + user.person.lastName;
  }

}
