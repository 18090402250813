import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {ImportArticle} from "@knust/api-interfaces";
import {
  getAllImportArticle,
  getImportArticlePage,
  getImportArticleSort,
  getImportArticleTerm,
  getImportArticleTotal
} from "./+state/import-article.selectors";
import {
  deleteImportArticle,
  loadImportArticle,
  setImportArticlePage,
  setImportArticleSort,
  setImportArticleTerm
} from "./+state/import-article.actions";

export const importArticleDisplayedColumns = ['internalId', 'title', 'description', 'importAction'];
export const importArticleColumnConfig = [
  {
    name: 'Artikelnummer',
    refName: 'internalId'
  },
  {
    name: 'Name',
    refName: 'title'
  },
  {
    name: 'Beschreibung',
    refName: 'description',
    disableSort: true,
  }
];

export const importArticleCommandMap: AbstractIndexCommands<ImportArticle> = {
  getAll: getAllImportArticle,
  getTotal: getImportArticleTotal,
  getPage: getImportArticlePage,
  setPage: setImportArticlePage,
  getTerm: getImportArticleTerm,
  setTerm: setImportArticleTerm,
  getSort: getImportArticleSort,
  setSort: setImportArticleSort,
  load: loadImportArticle,
  deleteElement: deleteImportArticle,
};
