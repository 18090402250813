import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {EmailTemplate} from "@knust/api-interfaces";
import {FormBuilder, UntypedFormGroup} from "@angular/forms";
import { updateEmail } from '../+state/email.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'knust-edit-email-template-dialog',
  templateUrl: './edit-email-templates-dialog.component.html',
  styleUrls: ['./edit-email-templates-dialog.component.scss'],
})
export class EmailTemplateDialogComponent {
  emailForm: UntypedFormGroup = this.fb.group({
    mailHead: [null],
    mailFeet: [null]
  });

  constructor(
    public dialogRef: MatDialogRef<EmailTemplateDialogComponent>,
    private fb: FormBuilder,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data?: EmailTemplate,
  ) {
    if (data) {
      this.emailForm.patchValue(data);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.emailForm.valid) {
      const updatedEmail: EmailTemplate = {
        ...this.data,
        ...this.emailForm.value,
        mailHead: this.emailForm.value.mailHead?.trim() || '',
        mailFeet: this.emailForm.value.mailFeet?.trim() || ''
      };

      this.store.dispatch(updateEmail({ email: updatedEmail }));
      this.dialogRef.close(updatedEmail);
    } else {
      this.emailForm.markAllAsTouched();
    }
  }
}
