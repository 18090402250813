import {Component, HostListener, OnInit} from "@angular/core";
import {BehaviorSubject, combineLatestWith, filter, map, Observable, tap} from "rxjs";
import {MatDrawerMode, MatSidenav} from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Store} from "@ngrx/store";
import {Location} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {NavigationEnd, Router} from "@angular/router";
import {LOGO_URL} from "./utility/constants/api.constants";
import {AuthService} from "./utility/services/auth.service";
import {UserService} from "./utility/services/user.service";
import {NotificationService} from "./utility/services/notification.service";
import {AuthStatus} from "./utility/enums/auth-status";
import {loadCart} from "./trunks/+state/trunk.actions";
import {getCart} from "./trunks/+state/trunk.selectors";

@Component({
  selector: 'knust-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
})
export class CoreComponent {
  logoPath = LOGO_URL;
  cartItemCount = 0;
  menu = [];
  isLoginWindow = true;
  showSidenav = new BehaviorSubject(false);
  windowSize = new BehaviorSubject(window.innerWidth);

  sidenavMode: MatDrawerMode = 'side';
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Tablet)
    .pipe(
      map(result => result.matches)
    );

  constructor(public authService: AuthService,
              private userService: UserService,
              public notificationService: NotificationService,
              private store: Store,
              private location: Location,
              private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver,
              private router: Router) {
    router.canceledNavigationResolution = 'computed';
    this.store.dispatch(loadCart());

    this.store.select(getCart).pipe(
      filter(cart => !!cart.articles)
    ).subscribe(cart => {
      this.cartItemCount = cart.articles.length;
    });

    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      combineLatestWith(this.authService.currentStatus, this.windowSize)
    ).subscribe(([event, authStatus, windowSize]: [any, AuthStatus, number]) => {
      console.log(event, authStatus, windowSize);

      if (event.url.includes('login') || authStatus !== AuthStatus.LOGGED_IN) {
        this.showSidenav.next(false);
        this.isLoginWindow = true;
        return;
      }

      this.isLoginWindow = false;

      if (windowSize < 1368) {
        this.sidenavMode = 'over';
        this.showSidenav.next(false);
      } else {
        this.sidenavMode = 'side';
        this.showSidenav.next(true);
      }
    });
  }

  changeSidenavState(event: boolean) {
    this.showSidenav.next(event);
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.windowSize.next(window.innerWidth);
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (this.dialog.openDialogs.length === 0) {
      this.location.back();
    }
  }

  dontClose(sidenav: MatSidenav) {
    sidenav.disableClose = true;
    setTimeout(()=> sidenav.disableClose = false);
  }
}
