import {CoreProperties} from "./core-properties.dto";
import {Trunk, TrunkArticle, User} from "@knust/api-interfaces";

export interface TrunkBooking extends CoreProperties {
  owner: User;
  amount: number;
  totalAmount: number;
  note?: string;
  trunk?: Trunk;
  article: TrunkArticle;
}
