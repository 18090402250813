<div class="form-box">
  <knust-data-table newButtonText="Neue Kategorie erstellen"
                    (entityRowClick)="openCategoryDialog($event)"
                    (addEntityClick)="openCategoryDialog()"
                    (deleteEntityClick)="deleteCategory($event)"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>
