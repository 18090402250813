export interface Address {
  id?: number;
  street: string;
  additional?: string;
  zip: string;
  city: string;
  country: string;
  latitude?: number | null;
  longitude?: number | null;
}
