import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {TrunksIndexComponent} from "./trunks-index/trunks-index.component";
import {CartComponent} from "./cart/cart.component";
import {TrunksDetailComponent} from "./trunks-detail/trunks-detail.component";
import {PendingChangesGuard} from "../utility/guards/pending-changes.guard";

const routes: Routes = [
  {
    path: '',
    component: TrunksIndexComponent
  },
  {
    path: 'warenkorb',
    pathMatch: "full",
    component: CartComponent,
    canDeactivate: [PendingChangesGuard]
  },
  {
    path: ':trunkId',
    component: TrunksDetailComponent,
    canDeactivate: [PendingChangesGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TrunksRoutingModule { }
