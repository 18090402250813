import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Article, DeliveryArticle, StorageLocation, VendorArticle} from "@knust/api-interfaces";
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ArticleService} from "../../utility/services/article.service";
import {StorageLocationService} from "../../utility/services/storage-location.service";
import {VendorArticleService} from "../../utility/services/vendor-article.service";
import {BehaviorSubject, debounceTime, filter, ReplaySubject} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'knust-edit-delivery-dialog',
  templateUrl: './edit-delivery-dialog.component.html',
  styleUrls: ['./edit-delivery-dialog.component.scss'],
})
export class EditDeliveryDialogComponent implements OnInit {
  deliveryArticleForm: UntypedFormGroup = this.fb.group({
    id: [null],
    count: [null, Validators.required],
    note: [null],
    mainArticle: this.fb.group({
      id: [null, Validators.required]
    }),
    vendorArticle: this.fb.group({
      id: [null, Validators.required]
    }),
    storageLocation: this.fb.group({
      id: [null, Validators.required]
    }),
  });

  articles: Article[] = [];
  articleFilterCtrl: FormControl = new FormControl();
  articleSearchTerm = '';
  public filteredArticles: ReplaySubject<Article[]> = new ReplaySubject<Article[]>(1);

  vendorArticles: VendorArticle[] = [];
  vendorArticleFilterCtrl: FormControl = new FormControl();
  vendorArticleSearchTerm = '';
  public filteredVendorArticles: ReplaySubject<VendorArticle[]> = new ReplaySubject<VendorArticle[]>(1);

  storageLocations: StorageLocation[] = [];
  storageLocationFilterCtrl: FormControl = new FormControl();
  storageLocationSearchTerm = '';
  public filteredStorageLocations: ReplaySubject<StorageLocation[]> = new ReplaySubject<StorageLocation[]>(1);

  constructor(public dialogRef: MatDialogRef<EditDeliveryDialogComponent>,
              private articleService: ArticleService,
              private vendorArticleService: VendorArticleService,
              private storageLocationService: StorageLocationService,
              private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: { deliveryArticle?: DeliveryArticle, editData: BehaviorSubject<[Article[], VendorArticle[], StorageLocation[]]> }) {}

  ngOnInit(): void {
    console.log(this.data);

    this.deliveryArticleForm.patchValue(this.data.deliveryArticle ?? {});

    console.log(this.deliveryArticleForm.value);

    console.log(typeof this.data.editData, this.data.editData);
    this.data.editData
      .subscribe(editData => {
        const articles = editData[0];

        if (this.data.deliveryArticle && !articles.some(article => article.id === this.data.deliveryArticle?.mainArticle.id)) {
          articles.push(this.data.deliveryArticle.mainArticle);
        }

        this.articles = articles;
        this.filteredArticles.next(articles);

        const vendorArticles = editData[1];

        if (this.data.deliveryArticle && !vendorArticles.some(vendorArticle => vendorArticle.id === this.data.deliveryArticle?.vendorArticle.id)) {
          vendorArticles.push(this.data.deliveryArticle.vendorArticle);
        }

        this.vendorArticles = vendorArticles;
        this.filteredVendorArticles.next(vendorArticles);

        const storageLocations = editData[2];

        if (this.data.deliveryArticle && !storageLocations.some(storageLocation => storageLocation.id === this.data.deliveryArticle?.storageLocation.id)) {
          storageLocations.push(this.data.deliveryArticle.storageLocation);
        }

        this.storageLocations = storageLocations;
        this.filteredStorageLocations.next(storageLocations);
      });

    this.articleFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.articleSearchTerm)
      )
      .subscribe(() => {
        this.filterArticles();
      });

    this.vendorArticleFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.vendorArticleSearchTerm)
      )
      .subscribe(() => {
        this.filterVendorArticles();
      });

    this.storageLocationFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.storageLocationSearchTerm)
      )
      .subscribe(() => {
        this.filterStorageLocations();
      });

    this.deliveryArticleForm.get('mainArticle')?.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(article => !!article.id)
      )
      .subscribe((article) => {
        this.setVendorArticles(article.id);
      });

    this.deliveryArticleForm.get('vendorArticle')?.valueChanges
      .pipe(
        untilDestroyed(this),
        filter(vendorArticle => !!vendorArticle.id),
      )
      .subscribe((vendorArticle) => {
        this.getVendorArticle(vendorArticle.id);
      });
  }

  filterArticles() {
    this.articleSearchTerm = this.articleFilterCtrl.value;

    this.articleService.loadArticles('?page=1&limit=100&search=' + this.articleSearchTerm.toLowerCase()).subscribe(
      res => this.filteredArticles.next(res.items)
    );
  }

  setVendorArticles(articleId: number) {
    this.vendorArticleService.loadVendorArticles('?articleId=' + articleId).subscribe(vendorArticles => {
      const mainVendorArticle = vendorArticles.items.find(vendorArticle => vendorArticle.isMainVendorArticle);

      this.filteredVendorArticles.next(vendorArticles.items);
      this.deliveryArticleForm.get(['vendorArticle', 'id'])?.setValue(mainVendorArticle?.id);
    });
  }

  filterVendorArticles() {
    this.vendorArticleSearchTerm = this.vendorArticleFilterCtrl.value;
    let query = '?page=1&limit=100&search=' + this.vendorArticleSearchTerm.toLowerCase();
    const selectedArticleId = this.deliveryArticleForm.get(['mainArticle', 'id'])?.value;

    if (selectedArticleId) {
      query += ('&articleId=' + selectedArticleId);
    }

    this.vendorArticleService.loadVendorArticles(query).subscribe(
      res => this.filteredVendorArticles.next(res.items)
    );
  }

  getVendorArticle(vendorArticleId: number) {
    const vendorArticle = this.vendorArticles.find(vendorArticle => vendorArticle.id === vendorArticleId);

    if (!vendorArticle) {
      this.vendorArticleService.loadVendorArticleDetails(vendorArticleId).subscribe(vendorArticle => {
        this.setArticleId(vendorArticle);
      });
    } else {
      this.setArticleId(vendorArticle);
    }
  }

  setArticleId(vendorArticle: VendorArticle) {
    if (this.deliveryArticleForm.get(['mainArticle', 'id'])?.value !== vendorArticle.article.id) {
      if (this.articles.findIndex(article => article.id === vendorArticle.article.id) === -1) {
        this.articles.push(vendorArticle.article as Article);
      }

      this.deliveryArticleForm.get(['mainArticle', 'id'])?.setValue(vendorArticle.article.id, { emitEvent: false });
    }
  }

  filterStorageLocations() {
    this.storageLocationSearchTerm = this.storageLocationFilterCtrl.value;

    this.storageLocationService.loadLocations('?page=1&limit=100&search=' + this.storageLocationSearchTerm.toLowerCase()).subscribe(
      res => this.filteredStorageLocations.next(res.items)
    );
  }

  save() {
    if (this.deliveryArticleForm.valid) {
      this.dialogRef.close(this.deliveryArticleForm.value);
    }
  }
}
