import {Component} from "@angular/core";
import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Store} from "@ngrx/store";
import {
  categoriesColumnConfig,
  categoriesCommandMap,
  categoriesDisplayedColumns
} from "../reusable-components/categories/categories-table-config";
import {
  storageLocationColumnConfig,
  storageLocationCommandMap,
  storageLocationDisplayedColumns
} from "../base-data/storage-location/storage-location-table";
import {vendorsColumnConfig, vendorsCommandMap, vendorsDisplayedColumns} from "../vendors/vendors-table";
import {
  vendorArticlesColumnConfig,
  vendorArticlesCommandMap,
  vendorArticlesDisplayedColumns
} from "../reusable-components/vendor-articles/vendor-articles-table";
import {articleColumnConfig, articleCommandMap, articleDisplayedColumns} from "../articles/articles-table";
import {usersColumnConfig, usersCommandMap, usersDisplayedColumns} from "../users/users-table";

@Component({
  selector: 'knust-trash',
  templateUrl: './trash.component.html',
  styleUrls: ['./trash.component.scss'],
})
export class TrashComponent {
  articleCommandMap = articleCommandMap;
  articleColumnConfig = articleColumnConfig;
  articleDisplayedColumns = [
    ...articleDisplayedColumns.filter(column => column !== 'buyShortcut' && column !== 'storageStatus'),
    'restoreAction'
  ];

  categoriesCommandMap = categoriesCommandMap;
  categoriesColumnConfig = categoriesColumnConfig;
  categoriesDisplayedColumns = [
    ...categoriesDisplayedColumns.filter(column => column !== 'deleteAction'),
    'restoreAction'
  ];

  storageLocationCommandMap = storageLocationCommandMap;
  storageLocationColumnConfig = storageLocationColumnConfig;
  storageLocationDisplayedColumns = [
    ...storageLocationDisplayedColumns.filter(column => column !== 'deleteAction'),
    'restoreAction'
  ];

  vendorsCommandMap = vendorsCommandMap;
  vendorsColumnConfig = vendorsColumnConfig;
  vendorsDisplayedColumns = [
    ...vendorsDisplayedColumns,
    'restoreAction'
  ];

  vendorArticlesCommandMap = vendorArticlesCommandMap;
  vendorArticlesColumnConfig = [
    ...vendorArticlesColumnConfig,
    {
      name: 'Artikel',
      refName: 'article',
      nestedName: 'title',
      useNestedName: true
    }
  ];
  vendorArticlesDisplayedColumns = [
    'article.title',
    ...vendorArticlesDisplayedColumns.filter(column => column !== 'isMainVendorArticle' && column !== 'id'),
    'restoreAction'
  ];

  usersCommandMap = usersCommandMap;
  usersColumnConfig = usersColumnConfig;
  usersDisplayedColumns = [
    ...usersDisplayedColumns.filter(column => column !== 'deleteAction' && !column.toLowerCase().includes('date')),
    'restoreAction'
  ];

  constructor(private store: Store) {
  }

  restoreElement(element: any, commandMap: AbstractIndexCommands<any>) {
    console.log(commandMap.restoreElement);
    if (commandMap.restoreElement) {
      this.store.dispatch(commandMap.restoreElement({ element }));
    }
  }
}
