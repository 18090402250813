import {Component, HostListener} from "@angular/core";
import {MatTableDataSource} from "@angular/material/table";
import {TrunkArticle} from "@knust/api-interfaces";
import {ColumnConfig} from "../../reusable-components/data-table/column-config";
import {Store} from "@ngrx/store";
import {getCart} from "../+state/trunk.selectors";
import {filter, map, tap} from "rxjs";
import {changeItemInCart, closeCart, deleteItemFromCart} from "../+state/trunk.actions";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {FormControl} from "@angular/forms";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";

@UntilDestroy()
@Component({
  selector: 'knust-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent {
  withdrawnStock: number[] = [];
  withdrawnStockNotes: (string | undefined)[] = [];
  withdrawnStockChange = false;

  displayedColumns = [
    'storageLocationArticle.article.title',
    'storageLocationArticle.article.internalId',
    'storageLocationArticle.storageLocation.label',
    'withdrawnStock',
    'deleteItemFromCart'
  ];
  columnConfig: ColumnConfig[] = [
    {
      name: 'Artikelname',
      refName: 'storageLocationArticle',
      nestedName: ['article', 'title'],
      useNestedName: true,
      disableSort: true
    },
    {
      name: 'Artikelnummer',
      refName: 'storageLocationArticle',
      nestedName: ['article', 'internalId'],
      useNestedName: true,
      disableSort: true
    },
    {
      name: 'Lagerort',
      refName: 'storageLocationArticle',
      nestedName: ['storageLocation', 'label'],
      useNestedName: true,
      disableSort: true
    },
  ];

  noteControl = new FormControl('');

  dataSource: MatTableDataSource<TrunkArticle> = new MatTableDataSource<TrunkArticle>([]);

  constructor(private store: Store, private dialog: MatDialog) {
    this.store.select(getCart).pipe(
      untilDestroyed(this),
      filter(cart => !!cart),
      tap(cart => this.noteControl.patchValue(cart.note ?? null)),
      map(cart => cart.articles),
      map(articles => {
        const sortedArticles = [...articles];

        sortedArticles.sort((a, b) => {
          if (a.storageLocationArticle.article?.id && b.storageLocationArticle.article?.id) {
            if (a.storageLocationArticle.article.id === b.storageLocationArticle.article.id) {
              return a.id <= b.id ? 1 : -1;
            } else {
              return (a.storageLocationArticle.article.id <= b.storageLocationArticle.article.id ? -1 : 1);
            }
          }

          return (a.id <= b.id ? 1 : -1);
        });

        return sortedArticles;
      }),
      tap(articles => {
        this.withdrawnStockChange = false;
        this.withdrawnStock = [];

        articles.forEach(article => {
          this.withdrawnStock.push(article.withdrawnStock);
          this.withdrawnStockNotes.push(article.note);
        });
      }),
    ).subscribe(articles => {
      this.dataSource = new MatTableDataSource<TrunkArticle>(articles);
    });
  }

  canDeactivate(): boolean {
    return !this.withdrawnStockChange;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = "Sie haben noch ungespeicherte Änderungen. Möchten Sie die Seite wirklich verlassen?";
    }
  }

  deleteItemFromCart(index: number) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Artikel wirklich entfernen?',
        text: 'Möchten Sie diesen Artikel wirklich aus dem Warenkorb entfernen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          const trunkArticle = this.dataSource.connect().value[index] as TrunkArticle;

          this.store.dispatch(deleteItemFromCart({ trunkArticleId: trunkArticle.id }));
        }
      })
  }

  saveCart(close?: boolean) {
    const cartArticles = this.dataSource.connect().value as TrunkArticle[];
    const withdrawnStockChanges: { trunkArticleId: number; amount: number; note?: string }[] = [];

    if (this.withdrawnStockChange) {
      for (let i = 0; i < cartArticles.length; i++) {
        withdrawnStockChanges.push({
          trunkArticleId: cartArticles[i].id,
          amount: this.withdrawnStock[i],
          note: this.withdrawnStockNotes[i]
        });
      }
    }

    if (close) {
      this.store.dispatch(closeCart({ withdrawnStockChanges, note: this.noteControl.value }));
    } else {
      this.store.dispatch(changeItemInCart({ withdrawnStockChanges, note: this.noteControl.value }));
    }

    this.withdrawnStockChange = false;
  }
}
