import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Vendor} from "@knust/api-interfaces";
import {getAllVendor, getVendorPage, getVendorSort, getVendorTerm, getVendorTotal} from "./+state/vendor.selectors";
import {
  createVendor,
  deleteVendor,
  loadVendor,
  restoreVendor,
  setVendorPage,
  setVendorSort,
  setVendorTerm
} from "./+state/vendor.actions";

export const vendorsDisplayedColumns = ['id', 'organization.name', 'vendorNumber'];
export const vendorsColumnConfig = [
  {
    name: 'ID',
    refName: 'id'
  },
  {
    name: 'Name',
    refName: 'organization',
    nestedName: 'name',
    useNestedName: true
  },
  {
    name: 'Lieferantennummer',
    refName: 'vendorNumber',
  }
];

export const vendorsCommandMap: AbstractIndexCommands<Vendor> = {
  getAll: getAllVendor,
  getTotal: getVendorTotal,
  getPage: getVendorPage,
  setPage: setVendorPage,
  getTerm: getVendorTerm,
  setTerm: setVendorTerm,
  getSort: getVendorSort,
  setSort: setVendorSort,
  load: loadVendor,
  createElement: createVendor,
  deleteElement: deleteVendor,
  restoreElement: restoreVendor
};
