import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageLocationArticlesComponent} from "./storage-locations-article.component";
import {StoreModule} from "@ngrx/store";
import * as fromStorageLocationArticle from "./+state/storage-location-article.reducer";
import {EffectsModule} from "@ngrx/effects";
import {StorageLocationArticleEffects} from "./+state/storage-location-article.effects";
import {DataTableModule} from "@knust/core";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {
  AddStorageLocationArticleDialogComponent
} from "./add-storage-location-article-dialog/add-storage-location-article-dialog.component";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  declarations: [
    StorageLocationArticlesComponent,
    AddStorageLocationArticleDialogComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(
      fromStorageLocationArticle.STORAGE_LOCATION_ARTICLE_FEATURE_KEY,
      fromStorageLocationArticle.reducer
    ),
    EffectsModule.forFeature([StorageLocationArticleEffects]),
    DataTableModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
  ],
  exports: [StorageLocationArticlesComponent],
})
export class StorageLocationArticlesModule {}
