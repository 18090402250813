import {ColumnConfig} from "../reusable-components/data-table/column-config";
import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {User} from "@knust/api-interfaces";
import {getAllUser, getUserPage, getUserSort, getUserTerm, getUserTotal} from "./+state/user.selectors";
import {deleteUser, loadUsers, restoreUser, setUserPage, setUserSort, setUserTerm} from "./+state/user.actions";

export const usersDisplayedColumns = [
  'id',
  'username',
  'person.firstName',
  'person.lastName',
  'person.mail',
  'createdDate',
  'updatedDate',
  'deleteAction'
];

export const usersColumnConfig: ColumnConfig[] = [
  {
    name: 'ID',
    refName: 'id',
  },
  {
    name: 'Erstellt am',
    refName: 'createdDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Zuletzt aktualisiert',
    refName: 'updatedDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Nutzername',
    refName: 'username'
  },
  {
    name: 'Vorname',
    refName: 'person',
    nestedName: 'firstName',
    useNestedName: true
  },
  {
    name: 'Nachname',
    refName: 'person',
    nestedName: 'lastName',
    useNestedName: true
  },
  {
    name: 'E-Mail',
    refName: 'person',
    nestedName: 'mail',
    useNestedName: true
  }
];

export const usersCommandMap: AbstractIndexCommands<User> = {
  getAll: getAllUser,
  getTotal: getUserTotal,
  getPage: getUserPage,
  setPage: setUserPage,
  getTerm: getUserTerm,
  setTerm: setUserTerm,
  getSort: getUserSort,
  setSort: setUserSort,
  load: loadUsers,
  deleteElement: deleteUser,
  restoreElement: restoreUser,
};
