import { Component, OnInit } from '@angular/core';
import {bookingsColumnConfig, bookingsCommandMap, bookingsDisplayedColumns} from "../bookings-table";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {Booking} from "@knust/api-interfaces";
import {getCompletedFilter} from "../+state/bookings.selectors";
import {changeCompletedFilter} from "../+state/bookings.actions";
import {
  resetStorageLocationArticles
} from "../../reusable-components/storage-locations-articles/+state/storage-location-article.actions";

@UntilDestroy()
@Component({
  selector: 'knust-bookings-index',
  templateUrl: './bookings-index.component.html',
  styleUrls: ['./bookings-index.component.scss'],
})
export class BookingsIndexComponent {
  displayedColumns = bookingsDisplayedColumns;
  columnConfig = bookingsColumnConfig;
  commandMap = bookingsCommandMap;

  showCompleted = false;

  constructor(private router: Router, private store: Store) {
    this.store.select(getCompletedFilter).pipe(
        untilDestroyed(this)
    ).subscribe(completedFilter => {
      this.showCompleted = completedFilter;
    });

    this.store.dispatch(resetStorageLocationArticles());
  }

  navigateToBooking(booking: Booking) {
    this.router.navigate([this.router.url, booking.id])
  }

  createBooking() {
    this.router.navigate([this.router.url, 'neu']);
  }

  setCompletedFilter() {
    this.store.dispatch(changeCompletedFilter());
  }
}
