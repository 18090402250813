import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {Booking, BookingArticle} from "@knust/api-interfaces";
import {BOOKING_URL, REPORTS_URL} from "../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }

  loadBookings(query?: string): Observable<PaginatedResponse<Booking>> {
    return this.http.get<PaginatedResponse<Booking>>(BOOKING_URL + (query ?? ''));
  }

  loadBookingDetails(id: number | string): Observable<Booking> {
    return this.http.get<Booking>(BOOKING_URL + '/details/' + id);
  }

  loadBookingReports(query?: string): Observable<PaginatedResponse<Booking>> {
    return this.http.get<PaginatedResponse<Booking>>(REPORTS_URL + '/bookings' + (query ?? ''));
  }

  downloadBookingReport(filePath: string) {
    return REPORTS_URL + '/download/' + filePath;
  }

  loadBookingArticles(id: number | string, query?: string): Observable<PaginatedResponse<BookingArticle>> {
    return this.http.get<PaginatedResponse<BookingArticle>>(BOOKING_URL + '/bookings/' + id + query)
  }

  addBookingArticle(id: number, booking: BookingArticle | Partial<BookingArticle>): Observable<BookingArticle> {
    return this.http.post<BookingArticle>(BOOKING_URL + '/bookings/' + id, booking);
  }

  deleteBookingArticle(id: number, booking: BookingArticle | Partial<BookingArticle>) {
    return this.http.delete(BOOKING_URL + '/bookings/' + id + '?bookingId=' + booking.id);
  }

  saveBooking(booking: Booking | Omit<Booking, 'id'>): Observable<Booking> {
    return this.http.post<Booking>(BOOKING_URL, booking);
  }

  deleteBooking(booking: Booking) {
    return this.http.delete(BOOKING_URL + '/' + booking.id);
  }

  closeBooking(bookingId: number, submitArticles: { storageLocationArticleId: number, amount: number }[]): Observable<{bookingId: number}> {
    return this.http.patch<{bookingId: number}>(BOOKING_URL + '/close/' + bookingId, submitArticles);
  }

  saveNote(note: string | null, id: number) {
    return this.http.post<{ note: string }>(BOOKING_URL + '/note/' + id, { note });
  }
}
