<div class="filterblock data-table-filter" *ngIf="!disableFilterblock">
  <mat-form-field appearance="standard" class="search-input-field">
    <input matInput placeholder="Suchen" [formControl]="searchCtrl">
    <mat-icon matSuffix
              (click)="searchCtrl.setValue(null)">{{(searchCtrl.valueChanges | async) ? 'clear' : 'search'}}</mat-icon>
  </mat-form-field>

  <ng-content></ng-content>
</div>

<div class="table-container">
  <table mat-table
         matSort
         [multiTemplateDataRows]="enableExpandableRows"
         [matSortActive]="matSortActive"
         [matSortDirection]="matSortDirection">

    <ng-container *ngFor="let column of columnConfig"
                  [matColumnDef]="(column.nestedName && column.useNestedName) ? (column.refName | getNestedName : column.nestedName) : column.refName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          [disabled]="column.disableSort ?? false"> {{column.name}} </th>
      <td mat-cell *matCellDef="let row" (click)="entityClicked(row)">
        <ng-container [ngSwitch]="column.type">
          <ng-container *ngSwitchDefault>
            {{column.nestedName ? (row | getNestedObject : column.refName : column.nestedName : column?.fallback) : (row[column.refName] ?? column?.fallback)}}{{column.suffix}}
          </ng-container>

          <ng-container *ngSwitchCase="'image'">
            <img [src]="column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName]">
          </ng-container>

          <ng-container *ngSwitchCase="'date'">
            {{(column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName]) | date : column.dateFormat}}
          </ng-container>

          <ng-container *ngSwitchCase="'currency'">
            {{(column.nestedName ? (row | getNestedObject : column.refName : column.nestedName : column?.fallback) : (row[column.refName] ?? column?.fallback)) | currency : 'EUR' : 'symbol-narrow'}}{{column.suffix}}
          </ng-container>

          <ng-container *ngSwitchCase="'user'">
            {{((column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName]) | getUserById)}}
          </ng-container>

          <ng-container *ngSwitchCase="'textToColor'">
            <div class="color-container"
                 [class]="'color-' + (column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName])">
              <div class="color-dot"></div>
              <mat-icon class="error-icon">error_outline</mat-icon>
              <mat-icon class="sold-out-icon">block</mat-icon>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="'boolean'">
            {{(column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName])
            ? column.trueText : column.falseText}}
          </ng-container>

          <div *ngSwitchCase="'longtext'" class="text-wrapper">
            {{column.nestedName ? (row | getNestedObject : column.refName : column.nestedName : column?.fallback) : (row[column.refName] ?? column?.fallback)}}{{column.suffix}}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="toggleExpand(element); $event.stopPropagation()">
          <mat-icon *ngIf="!expandedElements.includes(element)">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElements.includes(element)">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container *ngIf="enableSelection" matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        {{selectionHeaderText}}
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      *ngIf="hasMasterCheckbox"
                      [class.master-checkbox-margin]="selectionHeaderText.length > 0"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" (click)="selection.toggle(row)">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="buyStock" [formGroup]="buyStockForm">
      <th mat-header-cell *matHeaderCellDef> In den Warenkorb </th>
      <td mat-cell *matCellDef="let row; index as i">
        <ng-container [formGroupName]="row.id">
          <mat-form-field appearance="outline">
            <mat-label>Anzahl</mat-label>
            <input matInput type="number" class="buy-stock-input"
                   (keydown.enter)="buyStocks()"
                   min="0"
                   formControlName="amount"
                   (focusout)="changeStockInputToNextIntervalStep(row.article.samplingStep, row.id)"
                   [max]="row.actualStock"
                   [step]="row.article.samplingStep">
          </mat-form-field>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="bookings" [formGroup]="bookingsForm">
      <th mat-header-cell *matHeaderCellDef> Buchung </th>
      <td mat-cell *matCellDef="let row; index as i">
        <div class="booking-input-container" [formGroupName]="row.id">
          <mat-form-field appearance="outline" *ngIf="(bookingType | async) === BookingTypeEnum.MOVE">
            <mat-label>Empfangender Lagerort</mat-label>
            <mat-select formControlName="receivingStorageLocation">
              <mat-option>
                <ngx-mat-select-search [formControl]="storageLocationFilterCtrl"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let storageLocation of (filteredStorageLocations | async) ?? []" [value]="storageLocation.id">
                {{storageLocation.label}} - {{storageLocation.address.street}}, {{storageLocation.address.zip + ' ' + storageLocation.address.city}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Anzahl</mat-label>
            <input matInput type="number" class="buy-stock-input"
                   (keydown.enter)="bookStock()"
                   min="0"
                   formControlName="amount"
                   [max]="(bookingType | async) !== BookingTypeEnum.ADD ? row.actualStock : 99999">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Notiz</mat-label>
            <input matInput class="buy-stock-input"
                   formControlName="note">
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="takeFromTrunk">
      <th mat-header-cell *matHeaderCellDef> Entnehmen </th>
      <td mat-cell *matCellDef="let row; index as i">
        <div class="take-from-trunk-container" *ngIf="!disableEditing; else trunkNoActionsAvailable">
          <mat-form-field appearance="outline">
            <mat-label>Anzahl</mat-label>
            <input matInput type="number" class="buy-stock-input"
                   (keydown.enter)="takeFromTrunk()"
                   (focusout)="changeToNextIntervalStep($event, row.storageLocationArticle.article.samplingStep)"
                   [min]="row.withdrawnStock * -1"
                   [max]="row.availableStock"
                   [step]="row.storageLocationArticle.article.samplingStep"
                   [(ngModel)]="takeStockInputs.value[i]">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Kommentar</mat-label>
            <input matInput type="text" class="buy-stock-input"
                   (keydown.enter)="takeFromTrunk()"
                   [(ngModel)]="takeStockNotes.value[i]">
          </mat-form-field>
        </div>

        <ng-template #trunkNoActionsAvailable>
          Bearbeitung nicht mehr möglich
        </ng-template>
      </td>
    </ng-container>

    <ng-container matColumnDef="deliveryActions">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-buttons-container">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container *ngIf="!disableEditing; else noActionsAvailable">
              <button mat-menu-item (click)="duplicateEntityClick.emit(row)"
                      class="duplicate-button">
                Eintrag duplizieren
                <mat-icon>content_copy</mat-icon>
              </button>

              <button mat-menu-item (click)="deleteEntityClick.emit(row)"
                      class="delete-button">
                Eintrag löschen
                <mat-icon>delete</mat-icon>
              </button>
            </ng-container>

            <ng-template #noActionsAvailable>
              <button mat-menu-item>Bearbeitung nicht mehr möglich</button>
            </ng-template>
          </mat-menu>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="importAction">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-buttons-container">
          <button mat-stroked-button color="primary" (click)="entityActionClick.emit(row)">
            Speichern
            <mat-icon>save</mat-icon>
          </button>

          <button mat-stroked-button color="warn" (click)="deleteEntityClick.emit(row)">
            Entfernen
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteAction">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-buttons-container">
          <button mat-stroked-button color="warn" (click)="deleteEntityClick.emit(row)">
            Entfernen
            <mat-icon>delete</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="restoreAction">
      <th mat-header-cell *matHeaderCellDef>Aktionen</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-buttons-container">
          <button mat-stroked-button (click)="restoreEntityClick.emit(row)">
            Wiederherstellen
            <mat-icon>restore</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="buyShortcut">
      <th mat-header-cell *matHeaderCellDef>In den Warenkorb</th>
      <td mat-cell *matCellDef="let row">
        <div class="action-buttons-container">
          <button mat-stroked-button color="primary" (click)="entityActionClick.emit(row)" class="add-to-cart-btn">
            In den Warenkorb
            <mat-icon>shopping_cart</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDeliveries">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="expand-detail-cell">
        <div class="expand-detail-content" [class.expanded]="expandedElements.includes(element)"
             [@detailExpand]="expandedElements.includes(element) ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element.articles">
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Artikel</th>
              <td mat-cell *matCellDef="let element"> {{element.mainArticle.title}} </td>
            </ng-container>

            <ng-container matColumnDef="count">
              <th mat-header-cell *matHeaderCellDef> Anzahl </th>
              <td mat-cell *matCellDef="let element"> {{element.count}} </td>
            </ng-container>

            <ng-container matColumnDef="vendorArticleNumber">
              <th mat-header-cell *matHeaderCellDef> Lieferanten-Artikelnummer </th>
              <td mat-cell *matCellDef="let element"> {{element.vendorArticle.vendorArticleNumber}} </td>
            </ng-container>

            <ng-container matColumnDef="singlePrice">
              <th mat-header-cell *matHeaderCellDef> Einzelpreis </th>
              <td mat-cell *matCellDef="let element"> {{element.price | currency:'EUR' : 'symbol-narrow'}} </td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
              <th mat-header-cell *matHeaderCellDef> Gesamtpreis </th>
              <td mat-cell *matCellDef="let element"> {{element.totalPrice | currency:'EUR': 'symbol-narrow'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['title', 'count', 'vendorArticleNumber', 'singlePrice', 'totalPrice']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['title', 'count', 'vendorArticleNumber', 'singlePrice', 'totalPrice'];"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell no-data-cell" [colSpan]="5">Keine Artikel vorhanden</td>
            </tr>
          </table>

          <button mat-flat-button color="primary" (click)="entityActionClick.emit(element)">
            Volle Ansicht
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedTrunks">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length" class="expand-detail-cell">
        <div class="expand-detail-content" [class.expanded]="expandedElements.includes(element)"
             [@detailExpand]="expandedElements.includes(element) ? 'expanded' : 'collapsed'">
          <table mat-table [dataSource]="element.articles">
            <ng-container matColumnDef="title">
              <th mat-header-cell *matHeaderCellDef>Artikel</th>
              <td mat-cell *matCellDef="let element"> {{element.storageLocationArticle.article.title}} </td>
            </ng-container>

            <ng-container matColumnDef="withdrawnStock">
              <th mat-header-cell *matHeaderCellDef> Reservierte Menge </th>
              <td mat-cell *matCellDef="let element"> {{element.withdrawnStock}} </td>
            </ng-container>

            <ng-container matColumnDef="usedStock">
              <th mat-header-cell *matHeaderCellDef> Entnommene Menge </th>
              <td mat-cell *matCellDef="let element"> {{element.usedStock}} </td>
            </ng-container>

            <ng-container matColumnDef="note">
              <th mat-header-cell *matHeaderCellDef> Notiz </th>
              <td mat-cell *matCellDef="let element"> {{element.note}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="['title', 'withdrawnStock', 'usedStock', 'note']"></tr>
            <tr mat-row *matRowDef="let row; columns: ['title', 'withdrawnStock', 'usedStock', 'note'];"></tr>

            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell no-data-cell" [colSpan]="4">Keine Artikel vorhanden</td>
            </tr>
          </table>

          <mat-form-field appearance="fill">
            <mat-label>Notiz</mat-label>
            <textarea matInput disabled [value]="element.note"></textarea>
          </mat-form-field>

          <button mat-flat-button color="primary" (click)="entityActionClick.emit(element)">
            Volle Ansicht
            <mat-icon>chevron_right</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; index as i"
        (click)="toggleExpand(row)"
        [class.expanded-rows-activated]="enableExpandableRows"
        class="table-row"></tr>

    <ng-container *ngIf="enableExpandableRows">
      <tr mat-row *matRowDef="let row; columns: expandedColumns" class="expand-detail-row"></tr>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-data-cell" [colSpan]="displayedColumns.length">{{noDataText}}</td>
    </tr>
  </table>

  <!--
  <div *ngIf="(total | async) === 0 && (loading | async) === false" class="empty-msg">
    <div>Keine passenden Daten gefunden.</div>
  </div>-->

  <mat-toolbar>
    <mat-toolbar-row>
      <button mat-flat-button title="Neu erstellen" color="primary" (click)="new()" *ngIf="!disableNewButton && (authService.isAdmin() || authService.isPurchasing())">
        {{newButtonText}}
        <mat-icon>add</mat-icon>
      </button>

      <div class="spacer"></div>

      <mat-paginator #paginator
                     [length]="total | async"
                     [pageIndex]="pageIndex"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[1, 25, 50, 100, 250]"
                     class="mat-paginator-sticky">
      </mat-paginator>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="form-action-row" *ngIf="displayedColumns.includes('buyStock')">
    <button mat-flat-button color="primary" (click)="buyStocks()" class="save-btn">
      In den Warenkorb
      <mat-icon>shopping_cart</mat-icon>
    </button>
  </div>

  <div class="form-action-row" *ngIf="displayedColumns.includes('bookings')">
    <button mat-flat-button color="primary" (click)="bookStock()" class="save-btn" [disabled]="bookingsForm.pristine || bookingsForm.invalid || completed">
      Lagerbuchung {{completed ? 'durchgeführt' : 'durchführen'}}
      <mat-icon>{{completed ? 'done_all' : 'check'}}</mat-icon>
    </button>
  </div>

  <div class="form-action-row" *ngIf="enableExpandableRows">
    <button mat-flat-button color="primary" (click)="toggleAllExpanded()" class="save-btn">
      Alle {{allRowsExpanded ? 'einklappen' : 'ausklappen'}}
      <mat-icon>{{allRowsExpanded ? 'expand_less' : 'expand_more'}}</mat-icon>
    </button>
  </div>

  <div class="form-action-row" *ngIf="displayedColumns.includes('takeFromTrunk')">
    <button mat-stroked-button (click)="closeTrunk()" class="close-trunk-btn" [disabled]="disableEditing">
      Entnahme {{disableEditing ? 'bereits geschlossen' : 'schließen'}}
      <mat-icon>{{disableEditing? 'key_off' : 'key'}}</mat-icon>
    </button>

    <button mat-flat-button color="primary" (click)="takeAllFromTrunk()" class="save-btn" [disabled]="disableEditing">
      Alle entnehmen
      <mat-icon>keyboard_double_arrow_up</mat-icon>
    </button>

    <button mat-flat-button color="primary" (click)="takeFromTrunk()" [disabled]="disableEditing">
      Abbuchen
      <mat-icon>arrow_upward</mat-icon>
    </button>
  </div>
</div>

<!--
<mat-toolbar class="index-toolbar">
  <span></span>

  <mat-form-field appearance="standard">
    <input matInput placeholder="Suchen" [formControl]="searchCtrl">
    <mat-icon matSuffix
              (click)="searchCtrl.setValue(null)">{{(searchCtrl.valueChanges | async) ? 'clear' : 'search'}}</mat-icon>
  </mat-form-field>

  <div class="toolbar-spacer"></div>

  <button mat-flat-button color="accent" (click)="new()">News anlegen</button>
</mat-toolbar>

<div class="index-table-container">
  <table mat-table matSort [matSortActive]="matSortActive" [matSortDirection]="matSortDirection">

    Id Column
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    Title Column
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Titel</th>
      <td mat-cell *matCellDef="let row">{{row.title}}</td>
    </ng-container>

    Date Column
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
      <td mat-cell *matCellDef="let row">{{row.date | date}}</td>
    </ng-container>

    edit menu Column
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef class="align-right"></th>
      <td mat-cell *matCellDef="let row" class="align-right">
        <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="rowMenu"
                [matMenuTriggerData]="{ row }">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="edit($event, row.id)"></tr>
  </table>

  <mat-menu #rowMenu="matMenu">
    <ng-template matMenuContent let-row="row">
      <button mat-menu-item (click)="edit($event, row.id)">Bearbeiten</button>
      <button mat-menu-item (click)="delete(row)">Löschen</button>
    </ng-template>
  </mat-menu>

  <mat-paginator #paginator
                 [length]="total | async"
                 [pageIndex]="pageIndex"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[25, 50, 100, 250]"
                 class="mat-paginator-sticky">
  </mat-paginator>
</div>

<div *ngIf="(total | async) === 0" class="index-empty-msg"> && (loading | async) === false
  <div>Keine Daten gefunden.</div>
  <button mat-flat-button title="Neu erstellen" color="primary" (click)="new()" *ngIf="!disableNewButton">
    {{newButtonText}}
    <mat-icon>add</mat-icon>
  </button>
</div>
-->
