import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from "@angular/router";
import {filter, Observable} from "rxjs";
import {AuthService} from "../services/auth.service";
import {AuthStatus} from "../enums/auth-status";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return new Observable<boolean | UrlTree>((subscriber) => {
      this.authService.currentStatus
        .pipe(
          filter(status => status !== AuthStatus.PENDING),
        ).subscribe((authStatus) => {
        subscriber.next(authStatus === AuthStatus.LOGGED_IN ? true : this.router.parseUrl('/login?prev=' + state.url));
        subscriber.complete();
      });
    });
  }
}
