import {Component} from "@angular/core";
import {StorageLocationService} from "../../utility/services/storage-location.service";
import {ActivatedRoute} from "@angular/router";
import {StorageLocation} from "@knust/api-interfaces";
import {MatDialog} from "@angular/material/dialog";
import {StorageLocationDialogComponent} from "./storage-location-dialog/storage-location-dialog.component";
import {createStorageLocation, deleteStorageLocation, updateStorageLocation} from "./+state/storage-location.actions";
import {Store} from "@ngrx/store";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {
  storageLocationColumnConfig,
  storageLocationCommandMap,
  storageLocationDisplayedColumns
} from "./storage-location-table";

@Component({
  selector: 'knust-storage-location',
  templateUrl: './storage-location.component.html',
  styleUrls: ['./storage-location.component.scss'],
})
export class StorageLocationComponent {
  commandMap = storageLocationCommandMap;
  columnConfig = storageLocationColumnConfig;
  displayedColumns = storageLocationDisplayedColumns;

  constructor(private storageLocationService: StorageLocationService,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private store: Store) {}

  openStorageLocationDialog(storageLocation?: StorageLocation) {
    console.log(storageLocation);
    this.dialog.open(StorageLocationDialogComponent, {
      data: storageLocation,
      width: '40vw',
    })
      .afterClosed()
      .subscribe(storageLocation => {
        if (storageLocation) {
          this.saveLocation(storageLocation);
        }
      });
  }

  saveLocation(storageLocation: StorageLocation) {
    if (storageLocation.id) {
      console.log('Updating StorageLocation', storageLocation);
      this.store.dispatch(updateStorageLocation({ storageLocation }));
    } else {
      this.store.dispatch(createStorageLocation({ storageLocation }));
    }
  }

  deleteStorageLocation(storageLocation: StorageLocation) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Lagerort wirklich löschen?',
        text: 'Möchten Sie diesen Lagerort wirklich löschen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteStorageLocation({ storageLocation }));
        }
      })
  }
}
