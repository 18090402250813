import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {Vendor} from "@knust/api-interfaces";
import {VENDORS_URL} from "../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private http: HttpClient) { }

  loadVendors(query?: string): Observable<PaginatedResponse<Vendor>> {
    return this.http.get<PaginatedResponse<Vendor>>(VENDORS_URL + (query ?? ''));
  }

  loadVendorDetails(id: number | string): Observable<Vendor> {
    return this.http.get<Vendor>(VENDORS_URL + '/' + id);
  }

  saveVendor(vendor: Vendor | Omit<Vendor, 'id'>): Observable<Vendor> {
    return this.http.post<Vendor>(VENDORS_URL, vendor);
  }

  deleteVendor(vendorId: number): Observable<Vendor> {
    return this.http.delete<Vendor>(VENDORS_URL + '/' + vendorId);
  }

  restoreVendor(vendor: Vendor): Observable<Vendor> {
    return this.http.patch<Vendor>(VENDORS_URL + '/' + vendor.id, {});
  }
}
