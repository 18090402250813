import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'getNestedName'
})
export class GetNestedNamePipe implements PipeTransform {

  transform(refName: string, nestedName: string | string[]): string {
    if (Array.isArray(nestedName)) {
      return refName + '.' + nestedName.toString().replaceAll(',', '.');
    } else {
      return refName + '.' + nestedName;
    }
  }

}
