import {CoreProperties} from "./core-properties.dto";
import {BookingArticle} from "./booking-article.dto";
import {StorageLocation} from "./storage-location.dto";
import {BookingTypeEnum} from "./booking-type.enum";

export interface Booking extends CoreProperties {
  completed: boolean;
  articles: BookingArticle[];
  articleCount?: number;
  storageLocation?: Partial<{ id: number | null; }> | StorageLocation;
  note?: string | null;
  bookingType?: BookingTypeEnum | null;
}
