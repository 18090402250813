import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {ImportArticle} from "@knust/api-interfaces";
import {IMPORT_ARTICLES_URL} from "../constants/api.constants";

@Injectable({
  providedIn: 'root'
})
export class ImportArticleService {

  constructor(private http: HttpClient) { }

  loadImportArticles(query?: string): Observable<PaginatedResponse<ImportArticle>> {
    return this.http.get<PaginatedResponse<ImportArticle>>(IMPORT_ARTICLES_URL + (query ?? ''));
  }

  loadImportArticleDetails(id: number | string): Observable<ImportArticle> {
    return this.http.get<ImportArticle>(IMPORT_ARTICLES_URL + '/' + id);
  }

  saveImportArticle(article: ImportArticle | Omit<ImportArticle, 'id'>): Observable<ImportArticle> {
    return this.http.post<ImportArticle>(IMPORT_ARTICLES_URL, article);
  }

  deleteImportArticle(articleId: number, noMessage?: boolean) {
    return this.http.delete(IMPORT_ARTICLES_URL + '/' + articleId, { body: { noMessage } });
  }

  restoreImportArticle(article: ImportArticle): Observable<ImportArticle> {
    return this.http.patch<ImportArticle>(IMPORT_ARTICLES_URL + '/' + article.id, {});
  }
}
