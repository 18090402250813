import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {CATEGORY_URL} from "../constants/api.constants";
import {Category} from "@knust/api-interfaces";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(private http: HttpClient) { }

  loadCategories(query?: string): Observable<PaginatedResponse<Category>> {
    return this.http.get<PaginatedResponse<Category>>(CATEGORY_URL + (query ?? ''));
  }

  saveCategory(newCategory: Category | Omit<Category, 'id'>) {
    return this.http.post<Category>(CATEGORY_URL, newCategory);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete<Category>(CATEGORY_URL + '/' + categoryId);
  }

  restoreCategory(category: Category): Observable<Category> {
    return this.http.patch<Category>(CATEGORY_URL + '/' + category.id, {});
  }
}
