import {Article, StorageLocation, StorageLocationArticle, VendorArticle} from "@knust/api-interfaces";
import {CoreProperties} from "./core-properties.dto";

export interface DeliveryArticle extends CoreProperties {
  mainArticle: Article;
  vendorArticle: VendorArticle;
  storageArticle: StorageLocationArticle;
  storageLocation: StorageLocation;
  note?: string;
  count: number;
}
