<div class="login-box">
  <img class="logo" alt="Firmenlogo" [src]="logoPath">

  <h1 class="login-header">Willkommen zurück!</h1>
  <p class="login-hint">Melden Sie sich mit Ihren Benutzerdaten an.</p>

  <div *ngIf="error">
    <p>Fehler {{error.statusCode}}</p>
    <p>{{error.message}}</p>
  </div>

  <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
    <mat-form-field appearance="outline">
      <mat-label>Benutzername</mat-label>
      <input matInput formControlName="username">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Passwort</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>

    <button mat-raised-button color="primary" [disabled]="loginForm.invalid" type="submit" class="login-button">
      <span>Anmelden</span>
      <mat-icon>person_outline</mat-icon>
    </button>

    <a class="password-reset-link" routerLink="./passwort-zuruecksetzen">
      Passwort zurücksetzen
    </a>
  </form>
</div>
