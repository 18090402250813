import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as DeliveryActions from "./delivery.actions";
import {Delivery, DeliveryArticle} from "@knust/api-interfaces";

export const DELIVERY_FEATURE_KEY = 'delivery';

export interface State extends EntityState<Delivery> {
  selectedId?: number; // which Delivery record has been selected
  loaded: boolean; // has the Delivery list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
  showCompleted: boolean;
  articles: DeliveryArticle[];
  articleQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
  },
  reports: Delivery[];
  reportsQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
    showCompleted: boolean;
    userId?: number;
    articleId?: number;
    vendorId?: number;
    dateStart?: Date;
    dateEnd?: Date;
  }
}

export interface DeliveryPartialState {
  readonly [DELIVERY_FEATURE_KEY]: State;
}

export const deliveryAdapter: EntityAdapter<Delivery> =
  createEntityAdapter<Delivery>();

const initialSort: Sort = {
  active: 'updatedDate',
  direction: 'desc'
}

const initialArticleSort: Sort = {
  active: 'id',
  direction: 'asc'
}

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: initialSort
};

export const initialState: State = deliveryAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showCompleted: false,
  ...initialPaginationState,
  articles: [],
  articleQuery: {
    ...initialPaginationState,
    sort: initialArticleSort
  },
  reports: [],
  reportsQuery: {
    ...initialPaginationState,
    showCompleted: false,
  }
});

const deliveryReducer = createReducer(
  initialState,
  on(DeliveryActions.changeCompletedFilter, (state) => ({...state, showCompleted: !state.showCompleted})),
  on(DeliveryActions.changeReportsCompletedFilter, (state) => (
    {
      ...state,
      reportsQuery: {
        ...state.reportsQuery,
        showCompleted: !state.reportsQuery.showCompleted
      }
    })
  ),
  on(DeliveryActions.loadDelivery, (state) => ({ ...state, loaded: false, error: null })),
  on(DeliveryActions.loadDeliverySuccess, (state, { delivery, total }) =>
    deliveryAdapter.setAll(delivery, { ...state, loaded: true, total })
  ),
  on(DeliveryActions.loadDeliveryFailure, (state, { error }) => ({ ...state, error })),
  on(DeliveryActions.loadDeliveryArticlesSuccess, (state, { deliveryArticles, total }) =>
    ({...state, articles: deliveryArticles, articleQuery: {...state.articleQuery, total: total }})
  ),
  on(DeliveryActions.loadDeliveryReportsSuccess, (state, { deliveryReports, total }) =>
    ({...state, reports: deliveryReports, reportsQuery: {...state.reportsQuery, total: total }})
  ),
  on(DeliveryActions.setSelectedDelivery, (state, { id }) => ({ ...state, selectedId: id })),
  on(DeliveryActions.unsetSelectedDelivery, (state) => ({ ...state, selectedId: undefined, articles: [] })),
  on(DeliveryActions.loadDeliveryDetailSuccess, (state, { delivery }) =>
    deliveryAdapter.setAll([delivery], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(DeliveryActions.loadDeliveryDetailFailure, (state, { error }) => ({ ...state, error })),
  on(DeliveryActions.setDeliveryPage, (state, { page }) => ({ ...state, page })),
  on(DeliveryActions.setDeliveryTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(DeliveryActions.setDeliverySort, (state, { sort }) => ({ ...state, sort })),
  on(DeliveryActions.setDeliveryArticlesPage, (state, { page }) => ({ ...state, articleQuery: {...state.articleQuery, page} })),
  on(DeliveryActions.setDeliveryArticlesTerm, (state, { term }) =>
    ({
      ...state,
      articleQuery: {
        ...state.articleQuery,
        term,
        page: {
          ...state.articleQuery.page,
          pageIndex: 0,
        }
      }
    })
  ),
  on(DeliveryActions.setDeliveryArticlesSort, (state, { sort }) => ({ ...state, articleQuery: {...state.articleQuery, sort} })),
  on(DeliveryActions.setDeliveryReportsPage, (state, { page }) => ({ ...state, reportsQuery: {...state.reportsQuery, page} })),
  on(DeliveryActions.setDeliveryReportsTerm, (state, { term }) =>
    ({
      ...state,
      reportsQuery: {
        ...state.reportsQuery,
        term,
        page: {
          ...state.reportsQuery.page,
          pageIndex: 0,
        }
      }
    })
  ),
  on(DeliveryActions.setDeliveryReportsSort, (state, { sort }) => ({ ...state, reportsQuery: {...state.reportsQuery, sort} })),
  on(DeliveryActions.setReportsUserFilter, (state, { userId }) => ({ ...state, reportsQuery: {...state.reportsQuery, userId} })),
  on(DeliveryActions.setReportsArticleFilter, (state, { articleId }) => ({ ...state, reportsQuery: {...state.reportsQuery, articleId} })),
  on(DeliveryActions.setReportsVendorFilter, (state, { vendorId }) => ({ ...state, reportsQuery: {...state.reportsQuery, vendorId} })),
  on(DeliveryActions.setReportsDateFilter, (state, { dateStart, dateEnd }) => ({ ...state, reportsQuery: {...state.reportsQuery, dateStart, dateEnd} })),
  on(DeliveryActions.resetReportFilters, (state) => {
    const newReportsQuery = {...state.reportsQuery};
    delete newReportsQuery.userId;
    delete newReportsQuery.articleId;
    delete newReportsQuery.vendorId;
    delete newReportsQuery.dateStart;
    delete newReportsQuery.dateEnd;

    return {...state, reportsQuery: {...newReportsQuery}};
  }),
  on(DeliveryActions.clearDelivery, (state) =>
    deliveryAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(DeliveryActions.saveDeliverySuccess, (state, { delivery, insert }) =>
    deliveryAdapter.upsertOne(delivery, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(DeliveryActions.saveDeliveryFailure, (state, { error }) => ({ ...state, error })),
  on(DeliveryActions.deleteDeliverySuccess, (state, { id }) =>
    deliveryAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(DeliveryActions.deleteDeliveryFailure, (state, { error }) => ({ ...state, error })),
  on(DeliveryActions.updateDeliveryArticles, (state, { deliveryArticles, deliveryId }) => deliveryAdapter.updateOne({ id: deliveryId, changes: { articles: deliveryArticles}}, state)),
  on(DeliveryActions.addDeliveryArticleSuccess, (state, { deliveryArticle }) => {
    const articles = [...state.articles];
    const existingIndex = articles.findIndex(article => article.id === deliveryArticle.id);

    if (existingIndex > -1) {
      articles[existingIndex] = deliveryArticle;
    } else {
      articles.push(deliveryArticle);
    }

    return {...state, articles: articles, articleQuery: {...state.articleQuery, total: articles.length }}
  }),
  on(DeliveryActions.deleteDeliveryArticleSuccess, (state, { deliveryArticle }) => {
    const articles = [...state.articles];
    const existingIndex = articles.findIndex(article => article.id === deliveryArticle.id);

    articles.splice(existingIndex, 1);

    return {...state, articles: articles, articleQuery: {...state.articleQuery, total: articles.length }}
  }),
  on(DeliveryActions.closeDeliverySuccess, (state, { deliveryId }) => deliveryAdapter.updateOne({ id: deliveryId, changes: { completed: true }}, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return deliveryReducer(state, action);
}
