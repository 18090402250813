import {Component, OnInit} from "@angular/core";
import {Article, Category, UserEnum} from "@knust/api-interfaces";
import {setCategoryFilter, setSelectedArticle} from "../+state/article.actions";
import {Router} from "@angular/router";
import {CategoryService} from "../../utility/services/category.service";
import {MatDialog} from "@angular/material/dialog";
import {ReserveArticleDialogComponent} from "../reserve-article-dialog/reserve-article-dialog.component";
import {Store} from "@ngrx/store";
import {articleColumnConfig, articleCommandMap, articleDisplayedColumns} from "../articles-table";
import {FormControl} from "@angular/forms";
import {debounceTime, filter, ReplaySubject} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AuthService} from "../../utility/services/auth.service";
import {ScanArticleDialogComponent} from "../scan-article-dialog/scan-article-dialog.component";
import {StorageLocationArticleService} from "../../utility/services/storage-location-article.service";

@UntilDestroy()
@Component({
  selector: 'knust-articles-index',
  templateUrl: './articles-index.component.html',
  styleUrls: ['./articles-index.component.scss'],
})
export class ArticlesIndexComponent implements OnInit {
  commandMap = articleCommandMap;
  displayedColumns = articleDisplayedColumns;
  columnConfig = articleColumnConfig;

  categoryFilterCtrl: FormControl = new FormControl();
  categorySearchTerm = '';
  public filteredCategories: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  constructor(private router: Router,
              private store: Store,
              private dialog: MatDialog,
              private authService: AuthService,
              private storageLocationArticleService: StorageLocationArticleService,
              private categoryService: CategoryService) {
    this.filterCategories();

    const userType = this.authService.currentUser.value.type;
    const insertElementIndex = this.displayedColumns.findIndex(column => column === 'productCategory.label');
    const newArray = this.displayedColumns.slice(0, (insertElementIndex + 1));

    if (userType === UserEnum.ADMIN || userType === UserEnum.PURCHASING) {
      newArray.push('mainVendorArticle.price', 'mainVendor.organization.name', 'storageStatus');
    } else {
      newArray.push('description')
    }

    newArray.push(this.displayedColumns.slice(-1)[0]);

    this.displayedColumns = newArray;
  }

  ngOnInit() {
    this.categoryFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.categorySearchTerm)
      )
      .subscribe(() => {
        this.filterCategories();
      });
  }

  navigateToArticle(article: Article) {
    this.router.navigate([this.router.url, article.id])
  }

  createArticle() {
    this.router.navigate([this.router.url, 'neu']);
  }

  filterCategories() {
    this.categorySearchTerm = this.categoryFilterCtrl.value ?? '';

    this.categoryService.loadCategories('?page=1&limit=100&search=' + this.categorySearchTerm.toLowerCase()).subscribe(
      res => this.filteredCategories.next(res.items)
    );
  }

  reserveArticle(article?: Article, storageLocationId?: number, skipArticleSet?: boolean) {
    if (article && !skipArticleSet) {
      this.store.dispatch(setSelectedArticle({ id: article.id } ));
    }

    console.log('RESERVE ARTICLE', article);
    this.dialog.open(ReserveArticleDialogComponent, {
      data: {
        article,
        storageLocationId,
      }
    });
  }

  scanArticle() {
    this.dialog.open(ScanArticleDialogComponent, {
      width: '80vw',
      maxWidth: 'min(90vw, 800px)',
    })
      .afterClosed()
      .subscribe(storageLocationId => {
        if (storageLocationId) {
          this.storageLocationArticleService.getStorageLocationArticleDetails(storageLocationId).subscribe(storageLocation => {
            this.reserveArticle(storageLocation.article, storageLocationId, true);
          });
        }
      });
  }

  setArticleCategoryFilter(categoryId: number) {
    this.store.dispatch(setCategoryFilter({ categoryId }));
  }
}
