import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {StorageLocation} from "@knust/api-interfaces";
import {
  getAllStorageLocation,
  getStorageLocationPage,
  getStorageLocationSort,
  getStorageLocationTerm,
  getStorageLocationTotal
} from "./+state/storage-location.selectors";
import {
  createStorageLocation,
  deleteStorageLocation,
  loadStorageLocation,
  restoreStorageLocation,
  setStorageLocationPage,
  setStorageLocationSort,
  setStorageLocationTerm
} from "./+state/storage-location.actions";

export const storageLocationCommandMap: AbstractIndexCommands<StorageLocation> = {
  getAll: getAllStorageLocation,
  getTotal: getStorageLocationTotal,
  getPage: getStorageLocationPage,
  setPage: setStorageLocationPage,
  getTerm: getStorageLocationTerm,
  setTerm: setStorageLocationTerm,
  getSort: getStorageLocationSort,
  setSort: setStorageLocationSort,
  load: loadStorageLocation,
  createElement: createStorageLocation,
  deleteElement: deleteStorageLocation,
  restoreElement: restoreStorageLocation
}

export const storageLocationDisplayedColumns = ['id', 'label', 'address.street', 'address.zip', 'address.city', 'address.country', 'deleteAction'];
export const storageLocationColumnConfig = [
  {
    name: 'ID',
    refName: 'id'
  },
  {
    name: 'Bezeichnung',
    refName: 'label'
  },
  {
    name: 'Straße',
    refName: 'address',
    nestedName: 'street',
    useNestedName: true
  },
  {
    name: 'PLZ',
    refName: 'address',
    nestedName: 'zip',
    useNestedName: true
  },
  {
    name: 'Stadt',
    refName: 'address',
    nestedName: 'city',
    useNestedName: true
  },
  {
    name: 'Land',
    refName: 'address',
    nestedName: 'country',
    useNestedName: true
  }
];
