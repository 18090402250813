<div class="details-header">
  <div class="header-row">
    <h1>Stammdaten</h1>

    <div class="button-row">
      <button mat-flat-button [color]="editMode ? undefined : 'primary'" (click)="switchEditMode(true)">
        <ng-container *ngIf="!editMode; else editButtonCancel">
          Stammdaten bearbeiten
          <mat-icon>edit</mat-icon>
        </ng-container>

        <ng-template #editButtonCancel>
          Bearbeiten abbrechen
          <mat-icon>close</mat-icon>
        </ng-template>
      </button>
    </div>
  </div>
</div>

<form [formGroup]="baseDataForm" (ngSubmit)="saveBaseData()">
  <div class="form-box">
    <section>
      <h3>Allgemein</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Name</mat-label>
            <input matInput placeholder="Heinz Knust GmbH" formControlName="name">
          </mat-form-field>
        </div>

        <div class="input-column">
          <mat-form-field appearance="fill" class="notification-mail-form-field">
            <mat-label>E-Mail für Bestandsmeldungen</mat-label>
            <input matInput placeholder="info@bestandsmeldung.de" type="email" formControlName="notificationMail">
            <mat-hint>Nur verwendet, wenn der zugehörige Lagerort keine eigene E-Mail-Adresse hinterlegt hat</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Uhrzeit für Bestandsmeldungen & Bestellungen</mat-label>
            <input matInput type="time" placeholder="18:00" formControlName="notificationTime">
          </mat-form-field>

          <mat-checkbox class="order-immediately-checkbox" formControlName="orderImmediately" color="primary">
            Bestellungen bei Unterschreiten des <b>eisernen</b> Bestandes sofort ausführen
          </mat-checkbox>

          <mat-form-field appearance="fill">
            <mat-label>E-Mail für Kopien der Bestellungen</mat-label>
            <input matInput placeholder="info@bestellungen.de" type="email" formControlName="orderCCMail">
          </mat-form-field>
        </div>
      </div>
    </section>

    <section formGroupName="address">
      <h3>Adresse</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Straße</mat-label>
            <input matInput placeholder="Friedrich-Straße 30" formControlName="street">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Postleitzahl</mat-label>
            <input matInput placeholder="55555" formControlName="zip">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Stadt</mat-label>
            <input matInput placeholder="Essen" formControlName="city">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Land</mat-label>
            <mat-select formControlName="country">
              <mat-option [value]="'Deutschland'">
                Deutschland
              </mat-option>
              <mat-option [value]="'Österreich'">
                Österreich
              </mat-option>
              <mat-option [value]="'Schweiz'">
                Schweiz
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Adresszusatz</mat-label>
            <input matInput placeholder="Etage 2" formControlName="addressSupplement">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Längengrad</mat-label>
            <input matInput placeholder="51.672058" formControlName="longitude">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Breitengrad</mat-label>
            <input matInput placeholder="6.992028" formControlName="latitude">
          </mat-form-field>
        </div>
      </div>
    </section>

    <section>
      <h3>Kontaktdaten</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Telefon</mat-label>
            <input matInput type="tel" placeholder="+49 0201 777444" formControlName="phone">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Fax</mat-label>
            <input matInput placeholder="+49 0201 777555" formControlName="fax">
          </mat-form-field>
        </div>

        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Homepage-URL</mat-label>
            <input matInput placeholder="https://internetseite.de" formControlName="web">
          </mat-form-field>
        </div>
      </div>
    </section>

    <section>
      <h3>Notizen</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Notiz</mat-label>
            <textarea matInput placeholder="Dieser Kunde ist..." formControlName="note"></textarea>
          </mat-form-field>
        </div>
      </div>
    </section>

    <section>
      <h3>Logo</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <div class="image-upload-container">
            <input type="file" (change)="selectFile($event)" [disabled]="!editMode" accept="image/svg+xml">
            <button mat-flat-button type="button" color="primary"
                    [disabled]="!selectedFile || !editMode"
                    (click)="upload()">
              SVG-Datei hochladen
              <mat-icon>upload</mat-icon>
            </button>
          </div>

          <p class="logo-upload-hint">Ein hochgeladenes Logo ist nach einem Neuladen der Seite sichtbar.</p>
        </div>

        <div class="input-column"></div>
      </div>
    </section>

    <div class="form-save-row">
      <button mat-flat-button color="primary" class="save-btn" type="submit" [disabled]="!baseDataForm.valid" *ngIf="editMode">
        Speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</form>

<ng-container *ngIf="(authService.appInitCompleted | async) === true">
  <h2>Lagerorte</h2>

  <knust-storage-location></knust-storage-location>

  <h2>Kategorien</h2>

  <knust-categories></knust-categories>

  <h2>E-Mail-Vorlagen</h2>

  <knust-email-templates></knust-email-templates>
</ng-container>
