import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {DataTableComponent} from "./data-table.component";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatSortModule} from "@angular/material/sort";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatToolbarModule} from "@angular/material/toolbar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatButtonModule} from "@angular/material/button";
import {PipesModule} from "../../utility/pipes/pipes.module";
import {MatSelectModule} from "@angular/material/select";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

@NgModule({
  declarations: [DataTableComponent],
    imports: [
        CommonModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatIconModule,
        MatMenuModule,
        MatCheckboxModule,
        MatButtonModule,
        FormsModule,
        PipesModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
    ],
  exports: [
    DataTableComponent
  ]
})
export class DataTableModule {}
