import {Type} from "@angular/core";
import {filter, Observable, pipe, UnaryFunction} from "rxjs";
import {Action} from "@ngrx/store";
import {ofType} from "@ngrx/effects";
import {ROUTER_NAVIGATION} from "@ngrx/router-store";
import {RouterNavigationAction} from "@ngrx/router-store/src/actions";

import {CustomRouterState} from "./custom-route.serializer";

export function onNavigation(component: Type<unknown>): UnaryFunction<Observable<Action>, Observable<RouterNavigationAction<CustomRouterState>>> {
  return pipe(
    ofType(ROUTER_NAVIGATION),
    filter( (action: RouterNavigationAction<CustomRouterState>) => action.payload.routerState.component === component.name),
  );
}
