import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Article, DeliveryArticle, StorageLocation, VendorArticle} from "@knust/api-interfaces";
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, debounceTime, filter, ReplaySubject} from "rxjs";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ArticleService} from "../../../utility/services/article.service";

@UntilDestroy()
@Component({
  selector: 'knust-add-storage-location-article-dialog',
  templateUrl: './add-storage-location-article-dialog.component.html',
  styleUrls: ['./add-storage-location-article-dialog.component.scss'],
})
export class AddStorageLocationArticleDialogComponent implements OnInit {
  deliveryArticleForm: UntypedFormGroup = this.fb.group({
    article: this.fb.group({
      id: [null, Validators.required]
    }),
    amount: this.fb.control(0),
  });

  articles: Article[] = [];
  articleFilterCtrl: FormControl = new FormControl('');
  articleSearchTerm = '';
  public filteredArticles: ReplaySubject<Article[]> = new ReplaySubject<Article[]>(1);

  constructor(public dialogRef: MatDialogRef<AddStorageLocationArticleDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public storageLocationId: number,
              private articleService: ArticleService,
              private fb: FormBuilder) {}

  ngOnInit(): void {
    this.filterArticles();

    this.articleFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.articleSearchTerm)
      )
      .subscribe(() => {
        this.filterArticles();
      });
  }

  filterArticles() {
    this.articleSearchTerm = this.articleFilterCtrl.value;

    this.articleService.loadArticles('?notInStorageLocation=' + this.storageLocationId + '&page=1&limit=100&search=' + this.articleSearchTerm.toLowerCase()).subscribe(
      res => this.filteredArticles.next(res.items)
    );
  }

  save() {
    if (this.deliveryArticleForm.valid) {
      this.dialogRef.close(this.deliveryArticleForm.value);
    }
  }
}
