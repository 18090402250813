import {createFeatureSelector, createSelector} from "@ngrx/store";

import {IMPORT_ARTICLE_FEATURE_KEY, importArticleAdapter, State} from "./import-article.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'ImportArticle' feature state managed by NgRx
export const getImportArticleState = createFeatureSelector<State>(IMPORT_ARTICLE_FEATURE_KEY);

const { selectAll, selectEntities } = importArticleAdapter.getSelectors();

export const getImportArticleLoaded = createSelector(
  getImportArticleState,
  (state: State) => state.loaded
);

export const getImportArticleError = createSelector(
  getImportArticleState,
  (state: State) => state.error
);

export const getImportArticleTotal = createSelector(
  getImportArticleState,
  (state: State) => state.total
);

export const getImportArticlePage = createSelector(
  getImportArticleState,
  (state: State) => state.page
);

export const getImportArticleTerm = createSelector(
  getImportArticleState,
  (state: State) => state.term
);

export const getImportArticleSort = createSelector(
  getImportArticleState,
  (state: State) => state.sort
);

export const getImportArticleQuery = createSelector(
  getImportArticleState,
  (state: State) => StateToQuery(state)
);

export const getAllImportArticle = createSelector(
  getImportArticleState,
  (state: State) => selectAll(state)
);

export const getImportArticleEntities = createSelector(
  getImportArticleState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getImportArticleState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getImportArticleEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
