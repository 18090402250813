import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {Notification} from "../../reusable-components/notification/notification";

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notifications: BehaviorSubject<Notification[]> = new BehaviorSubject<Notification[]>([]);

  constructor() {
    this.checkNotifications();
  }

  createNotification(notification: Notification) {
    const notifications = this.notifications.value;

    notifications.push({...notification, age: Date.now()})

    this.notifications.next(notifications);
  }

  checkNotifications() {
    setInterval(() => {
      const notifications = this.notifications.value;

      const filteredNotifications = notifications.filter(notification => {
        if (notification.age) {
          return notification.age > (Date.now() - 4550);
        }

        return true;
      });

      this.notifications.next(filteredNotifications);
    }, 500);
  }
}
