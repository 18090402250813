import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CategoriesComponent} from "./categories.component";
import {StoreModule} from "@ngrx/store";
import * as fromCategory from "./+state/category.reducer";
import {EffectsModule} from "@ngrx/effects";
import {CategoryEffects} from "./+state/category.effects";
import {DataTableModule} from "../data-table/data-table.module";
import {CategoryDialogComponent} from "./category-dialog/category-dialog.component";
import {MatInputModule} from "@angular/material/input";
import {ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    CategoriesComponent,
    CategoryDialogComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(fromCategory.CATEGORY_FEATURE_KEY, fromCategory.reducer),
    EffectsModule.forFeature([CategoryEffects]),
    DataTableModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule
  ],
    exports: [
        CategoriesComponent
    ]
})
export class CategoriesModule {}
