import {Component} from "@angular/core";
import {Vendor} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {vendorsColumnConfig, vendorsCommandMap, vendorsDisplayedColumns} from "../vendors-table";

@Component({
  selector: 'knust-vendors-index',
  templateUrl: './vendors-index.component.html',
  styleUrls: ['./vendors-index.component.scss'],
})
export class VendorsIndexComponent {
  commandMap = vendorsCommandMap;
  displayedColumns = vendorsDisplayedColumns;
  columnConfig = vendorsColumnConfig;

  constructor(private router: Router) {
  }

  navigateToVendor(vendor: Vendor) {
    this.router.navigate([this.router.url, vendor.id])
  }

  createVendor() {
    this.router.navigate([this.router.url, 'neu']);
  }
}
