<form [formGroup]="userForm" (keydown.enter)="saveUser()">
  <div class="access-section">
    <h3>Zugangsdaten</h3>

    <div class="input-columns-container">
      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Nutzername</mat-label>
          <input matInput placeholder="mmustermann" formControlName="username">
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="authService.isAdmin()">
          <mat-label>Nutzertyp</mat-label>
          <mat-select formControlName="type">
            <mat-option *ngFor="let userType of userTypes" [value]="userType.type">
              {{userType.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-column">
        <mat-form-field appearance="fill" *ngIf="!showPasswordHint">
          <mat-label>Neues Passwort</mat-label>
          <input matInput placeholder="*******" formControlName="newPassword" [type]="hidePassword ? 'password' : 'text'">

          <mat-icon matSuffix (click)="switchPasswordVisibility($event)">
            {{hidePassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="!showPasswordHint">
          <mat-label>Passwort bestätigen</mat-label>
          <input matInput placeholder="*******" formControlName="confirmNewPassword" [type]="hidePassword ? 'password' : 'text'">

          <mat-icon matSuffix (click)="switchPasswordVisibility($event)">
            {{hidePassword ? 'visibility_off' : 'visibility'}}
          </mat-icon>
          <mat-error *ngIf="userForm.hasError('matching')">Passwörter stimmen nicht überein.</mat-error>
        </mat-form-field>

        <span *ngIf="showPasswordHint">Der neue Nutzer kann sein Passwort mit der Funktion "Passwort zurücksetzen" setzen.</span>
      </div>
    </div>
  </div>

  <div class="personal-section">
    <h3>Persönliche Daten</h3>

    <div class="input-columns-container" formGroupName="person">
      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Vorname</mat-label>
          <input matInput placeholder="Max" formControlName="firstName">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Nachname</mat-label>
          <input matInput placeholder="Mustermann" formControlName="lastName">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>E-Mail</mat-label>
          <input matInput placeholder="max@mustermann.de" formControlName="mail">
        </mat-form-field>
      </div>

      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Telefon</mat-label>
          <input matInput placeholder="0049 201 776644" formControlName="phone">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Handy</mat-label>
          <input matInput placeholder="0049 157 776644" formControlName="cell">
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>Fax</mat-label>
          <input matInput placeholder="0049 201 776644" formControlName="fax">
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="form-action-row">
    <button mat-flat-button (click)="cancel()" *ngIf="showCancel">
      Abbrechen
    </button>

    <button mat-flat-button color="primary" class="save-btn"
            [disabled]="userForm.pristine || userForm.invalid" (click)="saveUser()">
      Speichern
      <mat-icon>save</mat-icon>
    </button>
  </div>
</form>
