<h1>Artikelübersicht</h1>

<div class="form-box">
  <knust-data-table (entityRowClick)="navigateToArticle($event)"
                    (addEntityClick)="createArticle()"
                    (entityActionClick)="reserveArticle($event)"
                    newButtonText="Neuen Artikel erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
    <button mat-flat-button color="primary" (click)="scanArticle()" class="scan-article-btn">
      Code scannen
      <mat-icon>qr_code_scanner</mat-icon>
    </button>

    <mat-form-field appearance="fill">
      <mat-label>Produktkategorie</mat-label>
      <mat-select (selectionChange)="setArticleCategoryFilter($event.value)">
        <mat-option>
          <ngx-mat-select-search [formControl]="categoryFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option [value]="0">
          Alle
        </mat-option>
        <mat-option *ngFor="let category of filteredCategories | async" [value]="category.id">
          {{category.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </knust-data-table>
</div>
