<h1>Lagerbuchungen</h1>

<div class="form-box">
  <knust-data-table (entityRowClick)="navigateToBooking($event)"
                    (addEntityClick)="createBooking()"
                    newButtonText="Neue Lagerbuchung erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>
