<div class="details-header">
  <div class="header-row">
    <h1>Entnahme</h1>
  </div>

  <a (click)="location.back()" class="back-link">
    <mat-icon>arrow_back</mat-icon>
    Zurück
  </a>
</div>

<ng-container *ngIf="(trunk | async) as trunk">
  <div class="form-box">
    <knust-data-table [disableNewButton]="true"
                      (inputChanged)="takeStockInputs = $event"
                      (notesChanged)="takeStockNotes = $event"
                      (closeTrunkClick)="closingTrunk = true"
                      [disableEditing]="trunk.completed"
                      [commandMap]="commandMap"
                      [columnConfig]="columnConfig"
                      [displayedColumns]="displayedColumns">
    </knust-data-table>
  </div>

  <div class="form-box">
    <h3>Notiz</h3>

    <div class="input-columns-container">
      <div class="input-column">
        <mat-form-field appearance="fill">
          <mat-label>Notiz zur Entnahme</mat-label>
          <textarea matInput placeholder="Benötigt für Reperatur bei Kunden..." [formControl]="noteControl"
                    (ngModelChange)="noteChange = true"></textarea>
        </mat-form-field>
      </div>
    </div>

    <div class="save-note-row">
      <button mat-flat-button color="primary" (click)="saveNote()" [disabled]="!noteChange">
        Notiz speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</ng-container>

<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Durchgeführte Abbuchungen
      </mat-panel-title>
    </mat-expansion-panel-header>

    <knust-data-table [disableNewButton]="true"
                      [disableEditing]="true"
                      [commandMap]="commandMapBookings"
                      [columnConfig]="columnConfigBookings"
                      [displayedColumns]="displayedColumnsBookings">
    </knust-data-table>

    <button mat-stroked-button (click)="resetTrunk()" color="warn" class="reset-trunk-btn" [disabled]="(trunk | async)?.completed">
      Alle Abbuchungen stornieren
      <mat-icon>restart_alt</mat-icon>
    </button>
  </mat-expansion-panel>
</mat-accordion>
