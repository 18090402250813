import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as StorageLocationActions from "./storage-location.actions";
import {StorageLocation} from "@knust/api-interfaces";

export const STORAGE_LOCATION_FEATURE_KEY = 'storageLocation';

export interface State extends EntityState<StorageLocation> {
  selectedId?: number; // which StorageLocation record has been selected
  loaded: boolean; // has the StorageLocation list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface StorageLocationPartialState {
  readonly [STORAGE_LOCATION_FEATURE_KEY]: State;
}

export const storageLocationAdapter: EntityAdapter<StorageLocation> =
  createEntityAdapter<StorageLocation>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = storageLocationAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const storageLocationReducer = createReducer(
  initialState,
  on(StorageLocationActions.loadStorageLocation, (state) => ({ ...state, loaded: false, error: null })),
  on(StorageLocationActions.loadStorageLocationSuccess, (state, { storageLocation, total }) =>
    storageLocationAdapter.setAll(storageLocation, { ...state, loaded: true, total })
  ),
  on(StorageLocationActions.loadStorageLocationFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationActions.setSelectedStorageLocation, (state, { id }) => ({ ...state, selectedId: id })),
  on(StorageLocationActions.loadStorageLocationDetailSuccess, (state, { storageLocation }) =>
    storageLocationAdapter.setAll([storageLocation], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(StorageLocationActions.loadStorageLocationDetailFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationActions.setStorageLocationPage, (state, { page }) => ({ ...state, page })),
  on(StorageLocationActions.setStorageLocationTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(StorageLocationActions.setStorageLocationSort, (state, { sort }) => ({ ...state, sort })),
  on(StorageLocationActions.clearStorageLocation, (state) =>
    storageLocationAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(StorageLocationActions.saveStorageLocationSuccess, (state, { storageLocation, insert }) =>
    storageLocationAdapter.upsertOne(storageLocation, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(StorageLocationActions.saveStorageLocationFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationActions.deleteStorageLocationSuccess, (state, { id }) => {
    return storageLocationAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  }
  ),
  on(StorageLocationActions.deleteStorageLocationFailure, (state, { error }) => ({ ...state, error })),
  on(StorageLocationActions.restoreStorageLocationSuccess, (state, { storageLocation }) =>
    /* Wenn dieser Befehl ausgeführt wird, befindet sich der Nutzer im Papierkorb. Da dort nur die gelöschten
       Elemente im Store sein sollen, wird das Element entfernt. */
    storageLocationAdapter.removeOne(storageLocation.id, { ...state, total: state.total - 1 })
  ),
  on(StorageLocationActions.restoreStorageLocationFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return storageLocationReducer(state, action);
}
