import {createFeatureSelector, createSelector} from "@ngrx/store";

import {State, VENDOR_FEATURE_KEY, vendorAdapter} from "./vendor.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'Vendor' feature state managed by NgRx
export const getVendorState = createFeatureSelector<State>(VENDOR_FEATURE_KEY);

const { selectAll, selectEntities } = vendorAdapter.getSelectors();

export const getVendorLoaded = createSelector(
  getVendorState,
  (state: State) => state.loaded
);

export const getVendorError = createSelector(
  getVendorState,
  (state: State) => state.error
);

export const getVendorTotal = createSelector(
  getVendorState,
  (state: State) => state.total
);

export const getVendorPage = createSelector(
  getVendorState,
  (state: State) => state.page
);

export const getVendorTerm = createSelector(
  getVendorState,
  (state: State) => state.term
);

export const getVendorSort = createSelector(
  getVendorState,
  (state: State) => state.sort
);

export const getVendorQuery = createSelector(
  getVendorState,
  (state: State) => StateToQuery(state)
);

export const getAllVendor = createSelector(
  getVendorState,
  (state: State) => selectAll(state)
);

export const getVendorEntities = createSelector(
  getVendorState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getVendorState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getVendorEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
