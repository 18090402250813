import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {LoginRoutingModule} from "./login-routing.module";
import {LoginComponent} from "./login/login.component";
import {PasswordResetComponent} from "./password-reset/password-reset.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";

@NgModule({
  declarations: [LoginComponent, PasswordResetComponent],
  imports: [CommonModule, LoginRoutingModule, ReactiveFormsModule, MatInputModule, MatButtonModule, MatIconModule],
})
export class LoginModule {}
