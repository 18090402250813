<h1 mat-dialog-title>{{data?.label}} - E-Mail-Vorlage</h1>

<mat-dialog-content>
  <form [formGroup]="emailForm">
    <mat-form-field appearance="fill">
      <mat-label>Kopf-Text</mat-label>
      <textarea matInput formControlName="mailHead"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Fuß-Text</mat-label>
      <textarea matInput formControlName="mailFeet"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button mat-dialog-close (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()">Speichern</button>
</mat-dialog-actions>
