import {ActionReducerMap} from "@ngrx/store";
import * as fromRouter from "@ngrx/router-store";
import {routerReducer} from "@ngrx/router-store";

import {CustomRouterState} from "./custom-route.serializer";

export interface StoreRootState {
  router: fromRouter.RouterReducerState<CustomRouterState>;
}
export const reducers: ActionReducerMap<StoreRootState> = {
  router: routerReducer,
};
