import {createFeatureSelector, createSelector} from "@ngrx/store";

import {
  ARTICLE_STORAGE_LOCATION_FEATURE_KEY,
  articleStorageLocationAdapter,
  State
} from "./article-storage-location.reducer";
import {StateToQuery} from "../../../utility/abstract-index/state-to-query.func";

// Lookup the 'ArticleStorageLocation' feature state managed by NgRx
export const getArticleStorageLocationState = createFeatureSelector<State>(ARTICLE_STORAGE_LOCATION_FEATURE_KEY);

const { selectAll, selectEntities } = articleStorageLocationAdapter.getSelectors();

export const getArticleStorageLocationLoaded = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.loaded
);

export const getArticleStorageLocationError = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.error
);

export const getArticleStorageLocationTotal = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.total
);

export const getEmptyFilter = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.showEmpty
)

export const getArticleStorageLocationPage = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.page
);

export const getArticleStorageLocationTerm = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.term
);

export const getArticleStorageLocationSort = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.sort
);

export const getArticleStorageLocationQuery = createSelector(
  getArticleStorageLocationState,
  (state: State) => StateToQuery(state)
);

export const getAllArticleStorageLocation = createSelector(
  getArticleStorageLocationState,
  (state: State) => selectAll(state)
);

export const getArticleStorageLocationEntities = createSelector(
  getArticleStorageLocationState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getArticleStorageLocationState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getArticleStorageLocationEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
