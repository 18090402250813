import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {StorageLocationArticle} from "@knust/api-interfaces";

export const loadArticleStorageLocations = createAction(
  '[ArticleStorageLocation] Load ArticleStorageLocation',
  (payload: { filterId: number } = { filterId: 0 }) => payload
);

export const loadArticleStorageLocationsSuccess = createAction(
  '[ArticleStorageLocation/API] Load ArticleStorageLocation Success',
  props<{ articleStorageLocation: StorageLocationArticle[], total: number }>()
);

export const loadArticleStorageLocationsFailure = createAction(
  '[ArticleStorageLocation/API] Load ArticleStorageLocation Failure',
  props<{ error: any }>()
);

export const setSelectedArticleStorageLocation = createAction(
  '[ArticleStorageLocation/API] Set selected ArticleStorageLocation',
  props<{ id: number }>()
);

export const loadArticleStorageLocationDetailUnneeded = createAction(
  '[ArticleStorageLocation/API] Load ArticleStorageLocation Detail Unneeded'
);

export const loadArticleStorageLocationDetailSuccess = createAction(
  '[ArticleStorageLocation/API] Load ArticleStorageLocation Detail Success',
  props<{ articleStorageLocation: StorageLocationArticle }>()
);

export const loadArticleStorageLocationDetailFailure = createAction(
  '[ArticleStorageLocation/API] Load ArticleStorageLocation Detail Failure',
  props<{ error: any }>()
);

export const changeEmptyFilter = createAction(
  '[Delivery/API] Set Empty Filter',
)

export const setArticleStorageLocationPage = createAction(
  '[ArticleStorageLocation/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setArticleStorageLocationTerm = createAction(
  '[ArticleStorageLocation/API] Set Term',
  props<{ term: string }>()
);

export const setArticleStorageLocationSort = createAction(
  '[ArticleStorageLocation/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearArticleStorageLocation = createAction(
  '[ArticleStorageLocation/API] Clear'
);

export const createArticleStorageLocation = createAction(
  '[ArticleStorageLocation/API] Create ArticleStorageLocation',
  props<{ articleStorageLocation: Omit<StorageLocationArticle, 'id'> }>()
);

export const updateArticleStorageLocation = createAction(
  '[ArticleStorageLocation/API] Update ArticleStorageLocation',
  props<{ articleStorageLocation: StorageLocationArticle }>()
);

export const saveArticleStorageLocationSuccess = createAction(
  '[ArticleStorageLocation/API] Save ArticleStorageLocation Success',
  props<{ articleStorageLocation: StorageLocationArticle, insert: boolean }>()
);

export const saveArticleStorageLocationFailure = createAction(
  '[ArticleStorageLocation/API] Save ArticleStorageLocation Failure',
  props<{ error: any }>()
);

export const deleteArticleStorageLocation = createAction(
  '[ArticleStorageLocation/API] Delete ArticleStorageLocation',
  props<{ articleStorageLocation: StorageLocationArticle }>()
);

export const deleteArticleStorageLocationSuccess = createAction(
  '[ArticleStorageLocation/API] Delete ArticleStorageLocation Success',
  props<{ id: number | string }>()
);

export const deleteArticleStorageLocationFailure = createAction(
  '[ArticleStorageLocation/API] Delete ArticleStorageLocation Failure',
  props<{ error: any }>()
);
