import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {EMAIL_URL} from "../constants/api.constants";
import {EmailTemplate} from "@knust/api-interfaces";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  constructor(private http: HttpClient) { }

  loadEmails(): Observable<EmailTemplate[]> {
    return this.http.get<EmailTemplate[]>(EMAIL_URL)
  }

  updateEmail(id: number, email: Partial<EmailTemplate>): Observable<EmailTemplate> {
    return this.http.patch<EmailTemplate>(`${EMAIL_URL}/${id}`, email);
  }
}
