import {TrunkArticle} from "./trunk-article.dto";
import {CoreProperties} from "./core-properties.dto";

// TODO: Richtigen Nutzer als Owner angeben
export interface Trunk extends CoreProperties {
  owner: number;
  completed: boolean;
  isCart: boolean;
  note?: string | null;
  logMail: string;
  articles: TrunkArticle[];
}
