import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as VendorArticleActions from "./vendor-article.actions";
import {VendorArticle} from "@knust/api-interfaces";

export const VENDOR_ARTICLE_FEATURE_KEY = 'vendorArticle';

export interface State extends EntityState<VendorArticle> {
  selectedId?: number; // which VendorArticle record has been selected
  loaded: boolean; // has the VendorArticle list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface VendorArticlePartialState {
  readonly [VENDOR_ARTICLE_FEATURE_KEY]: State;
}

export const vendorArticleAdapter: EntityAdapter<VendorArticle> =
  createEntityAdapter<VendorArticle>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = vendorArticleAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const vendorArticleReducer = createReducer(
  initialState,
  on(VendorArticleActions.loadVendorArticles, (state) => ({ ...state, loaded: false, error: null })),
  on(VendorArticleActions.loadVendorArticlesSuccess, (state, { vendorArticle, total }) =>
    vendorArticleAdapter.setAll(vendorArticle, { ...state, loaded: true, total })
  ),
  on(VendorArticleActions.loadVendorArticlesFailure, (state, { error }) => ({ ...state, error })),
  on(VendorArticleActions.setSelectedVendorArticle, (state, { id }) => ({ ...state, selectedId: id })),
  on(VendorArticleActions.loadVendorArticleDetailSuccess, (state, { vendorArticle }) =>
    vendorArticleAdapter.setAll([vendorArticle], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(VendorArticleActions.loadVendorArticleDetailFailure, (state, { error }) => ({ ...state, error })),
  on(VendorArticleActions.setVendorArticlePage, (state, { page }) => ({ ...state, page })),
  on(VendorArticleActions.setVendorArticleTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(VendorArticleActions.setVendorArticleSort, (state, { sort }) => ({ ...state, sort })),
  on(VendorArticleActions.clearVendorArticle, (state) =>
    vendorArticleAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(VendorArticleActions.saveVendorArticleSuccess, (state, { vendorArticle, insert }) =>
    {
      let newState = {...state};

      if (vendorArticle.isMainVendorArticle) {
         newState = vendorArticleAdapter.map(entity => {
          return {
            ...entity,
            isMainVendorArticle: entity.id === vendorArticle.id,
          }
        }, newState);
      }

      return vendorArticleAdapter.upsertOne(vendorArticle, { ...newState, total: (insert ? newState.total + 1 : newState.total) });
    }
  ),
  on(VendorArticleActions.saveVendorArticleFailure, (state, { error }) => ({ ...state, error })),
  on(VendorArticleActions.deleteVendorArticleSuccess, (state, { id }) =>
    vendorArticleAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(VendorArticleActions.deleteVendorArticleFailure, (state, { error }) => ({ ...state, error })),
  on(VendorArticleActions.restoreVendorArticleSuccess, (state, { vendorArticle }) =>
    vendorArticleAdapter.removeOne(vendorArticle.id, { ...state, total: state.total - 1 })
  ),
  on(VendorArticleActions.restoreVendorArticleFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return vendorArticleReducer(state, action);
}
