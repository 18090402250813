import {Injectable} from "@angular/core";
import {BehaviorSubject, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL} from "../constants/api.constants";
import {AuthStatus} from "../enums/auth-status";
import {User, UserEnum} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {resetTrunkState} from "../../trunks/+state/trunk.actions";
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  initialUser: User = {
    id: 0,
    username: '',
    person: {
      firstName: '',
      lastName: '',
      mail: '',
    },
    type: UserEnum.BUYER
  }

  private token: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  private authStatus: BehaviorSubject<AuthStatus> = new BehaviorSubject<AuthStatus>(AuthStatus.PENDING);
  private user: BehaviorSubject<User> = new BehaviorSubject<User>(this.initialUser);
  private initCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  get currentToken() {
    return this.token;
  }

  get currentStatus() {
    return this.authStatus;
  }

  get currentUser() {
    return this.user;
  }

  get appInitCompleted() {
    return this.initCompleted;
  }

  constructor(private http: HttpClient, private router: Router, private store: Store) {
    this.authStatus.subscribe(res => console.log(res));

    const savedToken = sessionStorage.getItem('auth');
    const savedUser = sessionStorage.getItem('user');
    const initCompleted = sessionStorage.getItem('initCompleted');

    if (savedToken && savedUser) {
      console.log('Saved Token', savedToken);
      this.setLoginData({ accessToken: savedToken, user: JSON.parse(savedUser), initNotCompleted: !!initCompleted});
    } else {
      this.authStatus.next(AuthStatus.LOGGED_OUT);
    }
  }

  login(username: string, password: string) {
    const body = { username, password };

    return this.http.post<{ accessToken: string; user: User }>(API_BASE_URL + '/auth/login', body).pipe(
      tap(res => {
        console.log('Login in Progress', res);
        this.setLoginData(res);
      }),
    );
  }

  private setLoginData(userPayload: { accessToken: string; user: User, initNotCompleted?: boolean }) {
    console.log('Setting Token', userPayload);
    if (userPayload.accessToken) {
      this.token.next(userPayload.accessToken);
      this.user.next(userPayload.user);

      if (userPayload.initNotCompleted) {
        this.initCompleted.next(false);
        sessionStorage.setItem('initCompleted', 'false');
      }

      this.authStatus.next(AuthStatus.LOGGED_IN);

      sessionStorage.setItem('auth', userPayload.accessToken);
      sessionStorage.setItem('user', JSON.stringify(userPayload.user));
    }
  }

  register(username: string, password: string) {
    const body = { username, password };

    return this.http.post(API_BASE_URL + '/auth/register', body);
  }

  public isAdmin(): boolean {
    return this.user.value.type === UserEnum.ADMIN;
  }

  public isPurchasing(): boolean {
    return this.user.value.type === UserEnum.PURCHASING;
  }

  public logout(): void {
    sessionStorage.clear();

    this.token.next(undefined);
    this.authStatus.next(AuthStatus.LOGGED_OUT);
    this.user.next(this.initialUser);
    this.initCompleted.next(true);
    this.store.dispatch(resetTrunkState());

    this.router.navigate(['login']);
  }
}
