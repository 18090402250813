import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as CategoryActions from "./category.actions";
import {Category} from "@knust/api-interfaces";

export const CATEGORY_FEATURE_KEY = 'category';

export interface State extends EntityState<Category> {
  selectedId?: number; // which Category record has been selected
  loaded: boolean; // has the Category list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface CategoryPartialState {
  readonly [CATEGORY_FEATURE_KEY]: State;
}

export const categoryAdapter: EntityAdapter<Category> =
  createEntityAdapter<Category>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = categoryAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const categoryReducer = createReducer(
  initialState,
  on(CategoryActions.loadCategories, (state) => ({ ...state, loaded: false, error: null })),
  on(CategoryActions.loadCategoriesSuccess, (state, { category, total }) =>
    categoryAdapter.setAll(category, { ...state, loaded: true, total })
  ),
  on(CategoryActions.loadCategoriesFailure, (state, { error }) => ({ ...state, error })),
  on(CategoryActions.setSelectedCategory, (state, { id }) => ({ ...state, selectedId: id })),
  on(CategoryActions.loadCategoryDetailSuccess, (state, { category }) =>
    categoryAdapter.setAll([category], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(CategoryActions.loadCategoryDetailFailure, (state, { error }) => ({ ...state, error })),
  on(CategoryActions.setCategoryPage, (state, { page }) => ({ ...state, page })),
  on(CategoryActions.setCategoryTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(CategoryActions.setCategorySort, (state, { sort }) => ({ ...state, sort })),
  on(CategoryActions.clearCategory, (state) =>
    categoryAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(CategoryActions.saveCategorySuccess, (state, { category, insert }) =>
    categoryAdapter.upsertOne(category, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(CategoryActions.saveCategoryFailure, (state, { error }) => ({ ...state, error })),
  on(CategoryActions.deleteCategorySuccess, (state, { id }) =>
    categoryAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(CategoryActions.deleteCategoryFailure, (state, { error }) => ({ ...state, error })),
  on(CategoryActions.restoreCategorySuccess, (state, { category }) =>
    categoryAdapter.removeOne(category.id, { ...state, total: state.total - 1 })
  ),
  on(CategoryActions.deleteCategoryFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return categoryReducer(state, action);
}
