import {Component} from "@angular/core";
import {Delivery} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {getCompletedFilter} from "../+state/delivery.selectors";
import {changeCompletedFilter} from "../+state/delivery.actions";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {deliveryColumnConfig, deliveryCommandMap, deliveryDisplayedColumns} from "../delivery-table";

@UntilDestroy()
@Component({
  selector: 'knust-delivery-index',
  templateUrl: './delivery-index.component.html',
  styleUrls: ['./delivery-index.component.scss'],
})
export class DeliveryIndexComponent {
  displayedColumns = deliveryDisplayedColumns;
  columnConfig = deliveryColumnConfig;
  commandMap = deliveryCommandMap;

  showCompleted = false;

  constructor(private router: Router, private store: Store) {
    this.store.select(getCompletedFilter).pipe(
      untilDestroyed(this)
    ).subscribe(completedFilter => {
      this.showCompleted = completedFilter;
    });
  }

  navigateToDelivery(delivery: Delivery) {
    this.router.navigate([this.router.url, delivery.id])
  }

  createArticle() {
    this.router.navigate([this.router.url, 'neu']);
  }

  setCompletedFilter() {
    this.store.dispatch(changeCompletedFilter());
  }
}
