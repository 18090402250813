<h1>Wareneingangsbuchungen</h1>

<div class="form-box">
  <knust-data-table (entityRowClick)="navigateToDelivery($event)"
                    (addEntityClick)="createArticle()"
                    newButtonText="Neue Wareneingangsbuchung erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
    <mat-slide-toggle color="primary" [checked]="showCompleted" (toggleChange)="setCompletedFilter()">
      Abgeschlossene Buchungen anzeigen
    </mat-slide-toggle>
  </knust-data-table>
</div>
