import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import { EmailTemplate } from '@knust/api-interfaces';
import {
  getAllEmail,
} from "./+state/email.selectors";

export const mailCommandMap: AbstractIndexCommands<EmailTemplate> = {
  getAll: getAllEmail,
}

export const mailDisplayedColumns = ['id', 'label'];
export const mailColumnConfig = [
  {
    name: 'ID',
    refName: 'id'
  },
  {
    name: 'Name',
    refName: 'label'
  }
];
