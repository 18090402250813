import {Data, Params, RouterStateSnapshot} from "@angular/router";
import {BaseRouterStoreState, RouterStateSerializer} from "@ngrx/router-store";

export interface CustomRouterState extends BaseRouterStoreState {
  url: string;
  params: Params;
  queryParams: Params;
  data: Data;
  component: string | undefined;
}

export class CustomRouteSerializer implements RouterStateSerializer<CustomRouterState> {
  serialize(routerState: RouterStateSnapshot): CustomRouterState {
    let route = routerState.root;
    const {
      url,
      root: { queryParams },
    } = routerState;
    let component = undefined;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const { params, data } = route;
    if ( typeof route.component === 'function' ) {
      component = route.component.name;
    }

    return { url, params, queryParams, data, component };
  }
}
