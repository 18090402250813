import {ColumnConfig} from "../reusable-components/data-table/column-config";
import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Trunk} from "@knust/api-interfaces";
import {getAllTrunk, getTrunkPage, getTrunkSort, getTrunkTerm, getTrunkTotal} from "./+state/trunk.selectors";
import {deleteTrunk, loadTrunk, setTrunkPage, setTrunkSort, setTrunkTerm} from "./+state/trunk.actions";

export const trunkDisplayedColumns = ['id', 'createdDate', 'updatedDate', 'completed', 'note'];

export const trunkColumnConfig: ColumnConfig[] = [
  {
    name: 'ID',
    refName: 'id',
  },
  {
    name: 'Erstellt am',
    refName: 'createdDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Zuletzt aktualisiert',
    refName: 'updatedDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Status',
    refName: 'completed',
    type: 'boolean',
    trueText: 'geschlossen',
    falseText: 'aktiv'
  },
  {
    name: 'Besitzer',
    refName: 'createdByUser',
    type: 'user',
  },
  {
    name: 'Notiz',
    refName: 'note',
    type: 'longtext'
  }
];

export const trunkCommandMap: AbstractIndexCommands<Trunk> = {
  getAll: getAllTrunk,
  getTotal: getTrunkTotal,
  getPage: getTrunkPage,
  setPage: setTrunkPage,
  getTerm: getTrunkTerm,
  setTerm: setTrunkTerm,
  getSort: getTrunkSort,
  setSort: setTrunkSort,
  load: loadTrunk,
  deleteElement: deleteTrunk,
};
