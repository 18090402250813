import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {VendorsRoutingModule} from "./vendors-routing.module";
import {VendorsIndexComponent} from "./vendors-index/vendors-index.component";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {VendorsDetailComponent} from "./vendors-detail/vendors-detail.component";
import * as fromVendor from "./+state/vendor.reducer";
import {EffectsModule} from "@ngrx/effects";
import {VendorEffects} from "./+state/vendor.effects";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {StoreModule} from "@ngrx/store";
import {MatDialogModule} from "@angular/material/dialog";
import {ConfirmModule} from "../reusable-components/confirm/confirm.module";

@NgModule({
  declarations: [
    VendorsIndexComponent,
    VendorsDetailComponent,
  ],
    imports: [
      CommonModule,
      VendorsRoutingModule,
      DataTableModule,
      StoreModule.forFeature(fromVendor.VENDOR_FEATURE_KEY, fromVendor.reducer),
      EffectsModule.forFeature([VendorEffects]),
      ReactiveFormsModule,
      MatInputModule,
      MatSelectModule,
      MatButtonModule,
      MatIconModule,
      MatSlideToggleModule,
      MatDialogModule,
      ConfirmModule
    ],
})
export class VendorsModule {}
