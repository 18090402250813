import {UserEnum} from "./user.enum";
import {CoreProperties} from "./core-properties.dto";

export interface User extends CoreProperties {
  username: string;
  type: UserEnum;
  person: {
    firstName: string;
    lastName: string;
    mail: string;
    phone?: string;
    cell?: string;
    fax?: string;
  }
  password?: string;
  salt?: string;
}
