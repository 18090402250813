import {createFeatureSelector, createSelector} from "@ngrx/store";

import {EMAIL_FEATURE_KEY, emailAdapter, State} from "./email.reducer";

export const getEmailState = createFeatureSelector<State>(EMAIL_FEATURE_KEY);

const { selectAll } = emailAdapter.getSelectors();

export const getAllEmail = createSelector(
  getEmailState,
  (state: State) => selectAll(state)
);
