import {Component} from "@angular/core";
import {BarcodeFormat} from "@zxing/library";
import {MatDialogRef} from "@angular/material/dialog";
import {NotificationService} from "../../utility/services/notification.service";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'knust-scan-article-dialog',
  templateUrl: './scan-article-dialog.component.html',
  styleUrls: ['./scan-article-dialog.component.scss'],
})
export class ScanArticleDialogComponent {
  allowedFormats: BarcodeFormat[] = [ BarcodeFormat.CODE_128, BarcodeFormat.QR_CODE ];

  showOverlay = false;
  currentDevice: string | undefined = undefined;
  cameras: MediaDeviceInfo[] = [];
  selectedCamera: BehaviorSubject<MediaDeviceInfo | undefined> = new BehaviorSubject<MediaDeviceInfo | undefined>(undefined);

  torch = false;
  torchCompatible = false;

  constructor(public dialogRef: MatDialogRef<ScanArticleDialogComponent>,
              private notificationService: NotificationService) {}

  scanSuccess(result: string) {
    this.dialogRef.close(parseInt(result, 10));
  }

  foundCameras(event: MediaDeviceInfo[]) {
    console.log(event);

    event.forEach(camera => {
      if (!this.cameras.map(cameras => cameras.deviceId).includes(camera.deviceId)) {
        this.cameras.push(camera);
      }
    })
  }

  permissionsDenied() {
    this.notificationService.createNotification({
      title: 'Fehlende Berechtigung',
      text: 'Die Berechtigung für den Kamerazugriff wurde verweigert. Bitte die Einstellungen des Browsers überprüfen.',
      status: 'error',
      dismissed: 0
    });
  }

  createErrorMessage() {
    this.notificationService.createNotification({
      title: 'Scannen nicht möglich',
      text: 'Ein Fehler ist aufgetreten. Bitte noch einmal versuchen und ggf. die Anwendung neuladen.',
      status: 'error',
      dismissed: 0
    });
  }

  setSelectedCamera(deviceId: string) {
    if (deviceId !== this.currentDevice) {
      this.selectedCamera.next(this.cameras.find(camera => camera.deviceId === deviceId));
      this.currentDevice = deviceId;
    }
  }

  toggleTorch() {
    this.torch = !this.torch;

    if (!this.torch) {
      setTimeout(() => {
        this.selectedCamera.next(this.cameras.find(camera => camera.deviceId === this.currentDevice));
      }, 500)
    }
  }
}
