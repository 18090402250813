import {createSelector} from "@ngrx/store";
import {StoreRootState} from "./root.reducer";

export const getRouterState = (state: StoreRootState) => state.router;

export const getUrl = createSelector(
  getRouterState,
  state => state.state.url
);

export const getTrashState = createSelector(
  getRouterState,
  state => state.state.url.includes('papierkorb') ? 1 : 0
);

export const getQueryParams = createSelector(
  getRouterState,
  state => state.state.queryParams
);

export const getRouteParams = createSelector(
  getRouterState,
  state => state.state.params
);

export const getRouteData = createSelector(
  getRouterState,
  state => state.state.data
);

export const getRouteComponent = createSelector(
  getRouterState,
  state => state.state.component
);
