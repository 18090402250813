import { IsString, Matches, MaxLength, MinLength } from "class-validator"

export class LoginCredentialsDto {
    @IsString()
    @MinLength(4)
    @MaxLength(20)
    username!: string

    @IsString()
    password!: string
}
