import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as BookingActions from "./bookings.actions";
import {Booking, BookingArticle} from "@knust/api-interfaces";

export const BOOKINGS_FEATURE_KEY = 'bookings';

export interface State extends EntityState<Booking> {
  selectedId?: number; // which Booking record has been selected
  loaded: boolean; // has the Booking list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
  showCompleted: boolean;
  articles: BookingArticle[];
  articleQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
  },
  reports: Booking[];
  reportsQuery: {
    total: number;
    page: PageEvent;
    term: string;
    sort: Sort | null;
    showCompleted: boolean;
    userId?: number;
    articleId?: number;
    vendorId?: number;
    dateStart?: Date;
    dateEnd?: Date;
  }
}

export interface BookingPartialState {
  readonly [BOOKINGS_FEATURE_KEY]: State;
}

export const bookingsAdapter: EntityAdapter<Booking> =
  createEntityAdapter<Booking>();

const initialSort: Sort = {
  active: 'updatedDate',
  direction: 'desc'
}

const initialArticleSort: Sort = {
  active: 'id',
  direction: 'asc'
}

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: initialSort
};

export const initialState: State = bookingsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  showCompleted: false,
  ...initialPaginationState,
  articles: [],
  articleQuery: {
    ...initialPaginationState,
    sort: initialArticleSort
  },
  reports: [],
  reportsQuery: {
    ...initialPaginationState,
    showCompleted: false,
  }
});

const bookingsReducer = createReducer(
  initialState,
  on(BookingActions.changeCompletedFilter, (state) => ({...state, showCompleted: !state.showCompleted})),
  on(BookingActions.changeReportsCompletedFilter, (state) => (
    {
      ...state,
      reportsQuery: {
        ...state.reportsQuery,
        showCompleted: !state.reportsQuery.showCompleted
      }
    })
  ),
  on(BookingActions.loadBooking, (state) => ({ ...state, loaded: false, error: null })),
  on(BookingActions.loadBookingSuccess, (state, { bookings, total }) =>
    bookingsAdapter.setAll(bookings, { ...state, loaded: true, total })
  ),
  on(BookingActions.loadBookingFailure, (state, { error }) => ({ ...state, error })),
  on(BookingActions.loadBookingArticlesSuccess, (state, { bookingsArticles, total }) =>
    ({...state, articles: bookingsArticles, articleQuery: {...state.articleQuery, total: total }})
  ),
  on(BookingActions.loadBookingReportsSuccess, (state, { bookingsReports, total }) =>
    ({...state, reports: bookingsReports, reportsQuery: {...state.reportsQuery, total: total }})
  ),
  on(BookingActions.setSelectedBooking, (state, { id }) => ({ ...state, selectedId: id })),
  on(BookingActions.unsetSelectedBooking, (state) => ({ ...state, selectedId: undefined, articles: [] })),
  on(BookingActions.loadBookingDetailSuccess, (state, { bookings }) =>
    bookingsAdapter.setAll([bookings], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(BookingActions.loadBookingDetailFailure, (state, { error }) => ({ ...state, error })),
  on(BookingActions.setBookingPage, (state, { page }) => ({ ...state, page })),
  on(BookingActions.setBookingTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(BookingActions.setBookingSort, (state, { sort }) => ({ ...state, sort })),
  on(BookingActions.setBookingArticlesPage, (state, { page }) => ({ ...state, articleQuery: {...state.articleQuery, page} })),
  on(BookingActions.setBookingArticlesTerm, (state, { term }) =>
    ({
      ...state,
      articleQuery: {
        ...state.articleQuery,
        term,
        page: {
          ...state.articleQuery.page,
          pageIndex: 0,
        }
      }
    })
  ),
  on(BookingActions.setBookingArticlesSort, (state, { sort }) => ({ ...state, articleQuery: {...state.articleQuery, sort} })),
  on(BookingActions.setBookingReportsPage, (state, { page }) => ({ ...state, reportsQuery: {...state.reportsQuery, page} })),
  on(BookingActions.setBookingReportsTerm, (state, { term }) =>
    ({
      ...state,
      reportsQuery: {
        ...state.reportsQuery,
        term,
        page: {
          ...state.reportsQuery.page,
          pageIndex: 0,
        }
      }
    })
  ),
  on(BookingActions.setBookingReportsSort, (state, { sort }) => ({ ...state, reportsQuery: {...state.reportsQuery, sort} })),
  on(BookingActions.setReportsUserFilter, (state, { userId }) => ({ ...state, reportsQuery: {...state.reportsQuery, userId} })),
  on(BookingActions.setReportsArticleFilter, (state, { articleId }) => ({ ...state, reportsQuery: {...state.reportsQuery, articleId} })),
  on(BookingActions.setReportsVendorFilter, (state, { vendorId }) => ({ ...state, reportsQuery: {...state.reportsQuery, vendorId} })),
  on(BookingActions.setReportsDateFilter, (state, { dateStart, dateEnd }) => ({ ...state, reportsQuery: {...state.reportsQuery, dateStart, dateEnd} })),
  on(BookingActions.resetReportFilters, (state) => {
    const newReportsQuery = {...state.reportsQuery};
    delete newReportsQuery.userId;
    delete newReportsQuery.articleId;
    delete newReportsQuery.vendorId;
    delete newReportsQuery.dateStart;
    delete newReportsQuery.dateEnd;

    return {...state, reportsQuery: {...newReportsQuery}};
  }),
  on(BookingActions.clearBooking, (state) =>
    bookingsAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(BookingActions.saveBookingSuccess, (state, { bookings, insert }) =>
    bookingsAdapter.upsertOne(bookings, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(BookingActions.saveBookingFailure, (state, { error }) => ({ ...state, error })),
  on(BookingActions.deleteBookingSuccess, (state, { id }) =>
    bookingsAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(BookingActions.deleteBookingFailure, (state, { error }) => ({ ...state, error })),
  on(BookingActions.updateBookingArticles, (state, { bookingsArticles, bookingId }) => bookingsAdapter.updateOne({ id: bookingId, changes: { articles: bookingsArticles}}, state)),
  on(BookingActions.addBookingArticleSuccess, (state, { bookingsArticle }) => {
    const articles = [...state.articles];
    const existingIndex = articles.findIndex(article => article.id === bookingsArticle.id);

    if (existingIndex > -1) {
      articles[existingIndex] = bookingsArticle;
    } else {
      articles.push(bookingsArticle);
    }

    return {...state, articles: articles, articleQuery: {...state.articleQuery, total: articles.length }}
  }),
  on(BookingActions.deleteBookingArticleSuccess, (state, { bookingsArticle }) => {
    const articles = [...state.articles];
    const existingIndex = articles.findIndex(article => article.id === bookingsArticle.id);

    articles.splice(existingIndex, 1);

    return {...state, articles: articles, articleQuery: {...state.articleQuery, total: articles.length }}
  }),
  on(BookingActions.closeBookingSuccess, (state, { bookingId }) => bookingsAdapter.updateOne({ id: bookingId, changes: { completed: true }}, state)),
);

export function reducer(state: State | undefined, action: Action) {
  return bookingsReducer(state, action);
}
