import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {Action, createReducer, on} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

import * as ImportArticleActions from "./import-article.actions";
import {ImportArticle} from "@knust/api-interfaces";

export const IMPORT_ARTICLE_FEATURE_KEY = 'import-article';

export interface State extends EntityState<ImportArticle> {
  selectedId?: number; // which ImportArticle record has been selected
  loaded: boolean; // has the ImportArticle list been loaded
  error?: string | null; // last known error (if any)
  total: number;
  page: PageEvent;
  term: string;
  sort: Sort | null;
}

export interface ImportArticlePartialState {
  readonly [IMPORT_ARTICLE_FEATURE_KEY]: State;
}

export const importArticleAdapter: EntityAdapter<ImportArticle> =
  createEntityAdapter<ImportArticle>();

const initialPaginationState = {
  total: 0,
  page: {
    pageIndex: 0,
    pageSize: 25,
    length: 0
  },
  term: '',
  sort: null
};

export const initialState: State = importArticleAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  ...initialPaginationState
});

const importArticleReducer = createReducer(
  initialState,
  on(ImportArticleActions.loadImportArticle, (state) => ({ ...state, loaded: false, error: null })),
  on(ImportArticleActions.loadImportArticleSuccess, (state, { importArticle, total }) =>
    importArticleAdapter.setAll(importArticle, { ...state, loaded: true, total })
  ),
  on(ImportArticleActions.loadImportArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ImportArticleActions.setSelectedImportArticle, (state, { id }) => ({ ...state, selectedId: id })),
  on(ImportArticleActions.unsetSelectedImportArticle, (state) => ({ ...state, selectedId: undefined })),
  on(ImportArticleActions.loadImportArticleDetailSuccess, (state, { importArticle }) =>
    importArticleAdapter.setAll([importArticle], { ...state, loaded: false, ...initialPaginationState })
  ),
  on(ImportArticleActions.loadImportArticleDetailFailure, (state, { error }) => ({ ...state, error })),
  on(ImportArticleActions.setImportArticlePage, (state, { page }) => ({ ...state, page })),
  on(ImportArticleActions.setImportArticleTerm, (state, { term }) => ({
    ...state,
    term,
    page: {
      ...state.page,
      pageIndex: 0,
    }
  })),
  on(ImportArticleActions.setImportArticleSort, (state, { sort }) => ({ ...state, sort })),
  on(ImportArticleActions.clearImportArticle, (state) =>
    importArticleAdapter.removeAll({ ...state, ...initialPaginationState })
  ),
  on(ImportArticleActions.saveImportArticleSuccess, (state, { importArticle, insert }) =>
    importArticleAdapter.upsertOne(importArticle, { ...state, total: (insert ? state.total + 1 : state.total) })
  ),
  on(ImportArticleActions.saveImportArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ImportArticleActions.deleteImportArticleSuccess, (state, { id }) =>
    importArticleAdapter.removeOne(id + '', { ...state, total: state.total - 1 })
  ),
  on(ImportArticleActions.deleteImportArticleFailure, (state, { error }) => ({ ...state, error })),
  on(ImportArticleActions.restoreImportArticleSuccess, (state, { importArticle }) =>
    importArticleAdapter.removeOne(importArticle.id, { ...state, total: state.total - 1 })
  ),
  on(ImportArticleActions.restoreImportArticleFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return importArticleReducer(state, action);
}
