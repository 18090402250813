import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, UntypedFormGroup, Validators} from "@angular/forms";
import {VendorService} from "../../utility/services/vendor.service";
import {BehaviorSubject, filter, merge, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {getSelected} from "../+state/vendor.selectors";
import {Vendor} from "@knust/api-interfaces";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {getRouteParams} from "../../+state/root.selectors";
import {StoreRootState} from "../../+state/root.reducer";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {createVendor, deleteVendor, updateVendor} from "../+state/vendor.actions";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {MatDialog} from "@angular/material/dialog";
import {Location} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'knust-vendors-detail',
  templateUrl: './vendors-detail.component.html',
  styleUrls: ['./vendors-detail.component.scss'],
})
export class VendorsDetailComponent implements OnInit {
  enableContactPerson = new BehaviorSubject<boolean>(false);

  vendorForm: UntypedFormGroup = this.fb.group({
    id: [null],
    vendorNumber: [null, Validators.required],
    organization: this.fb.group({
      name: [null, Validators.required],
      mail: [null, [Validators.required, Validators.email]],
      phone: [null, Validators.required],
      fax: [null],
      web: [null],
      note: [null],
      address: this.fb.group({
        street: [null, Validators.required],
        zip: [null, Validators.required],
        city: [null, Validators.required],
        country: [null, Validators.required],
        addressSupplement: [null],
        latitude: new FormControl<number|null>(null),
        longitude: new FormControl<number|null>(null),
      }),
    }),
  });
  initialVendor!: Vendor;

  newEntity = false;
  editMode = false;

  vendor = merge(
    this.store.select(getRouteParams).pipe(
      filter(params => params['vendorId'] && params['vendorId'] === NEW_ENTITY),
      tap(() => {
        this.newEntity = true;
      }),
    ),
    this.store.select(getSelected).pipe(
      filter(selected => !!selected && !this.newEntity),
      tap(() => {
        this.newEntity = false;
        this.switchEditMode(false, false);
      })
    )
  );

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              public location: Location,
              private store: Store<StoreRootState>,
              private vendorService: VendorService) {
  }

  ngOnInit() {
    this.vendor.pipe(
      untilDestroyed(this)
    )
      .subscribe(vendor => {
        if (vendor) {
          console.log('Selected Vendor', vendor);
          if (vendor?.organization?.contactPerson) {
            this.toggleContactPerson(true);
          }

          if (vendor.id) {
            this.initialVendor = vendor as Vendor;
          }

          this.vendorForm.patchValue(vendor, {emitEvent: false});
        }
      });
  }

  switchEditMode(resetData?: boolean, editMode?: boolean) {
    this.editMode = editMode ?? !this.editMode;

    if (this.editMode) {
      this.vendorForm.enable();
    } else {
      this.vendorForm.disable();

      if (resetData) {
        this.vendorForm.reset();
        this.vendorForm.patchValue(this.initialVendor, { emitEvent: false });
      }
    }
  }

  toggleContactPerson(activateOnly?: boolean) {
    const vendorOrganizationForm = this.vendorForm.get('organization') as FormGroup;

    if (this.vendorForm.get('organization.contactPerson') && !activateOnly) {
      vendorOrganizationForm.removeControl('contactPerson');
      this.enableContactPerson.next(false);
    } else {
      vendorOrganizationForm.addControl('contactPerson',
        this.fb.group({
          firstName: [null, Validators.required],
          lastName: [null, Validators.required],
          position: [null],
          mail: [null],
          phone: [null],
          fax: [null],
          web: [null],
        })
      );

      if (!this.newEntity && !this.editMode) {
        vendorOrganizationForm.disable();
      }

      console.log(this.vendorForm.value);
      this.enableContactPerson.next(true);
    }
  }

  saveVendor() {
    console.log(this.vendorForm);

    if (this.newEntity) {
      this.store.dispatch(createVendor({vendor: this.vendorForm.value}));

      this.newEntity = false;
    } else {
      this.store.dispatch(updateVendor({
        vendor: this.vendorForm.value
      }));
    }

    this.switchEditMode(false, false);
  }

  deleteVendor() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Löschen bestätigen',
        text: 'Möchten Sie diesen Lieferanten wirklich löschen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteVendor());
        }
      })
  }
}
