import {Component} from "@angular/core";
import {AuthService} from "../utility/services/auth.service";
import {User} from "@knust/api-interfaces";
import {deleteUser} from "./+state/user.actions";
import {MatDialog} from "@angular/material/dialog";
import {EditUserDialogComponent} from "./edit-user-dialog/edit-user-dialog.component";
import {Store} from "@ngrx/store";
import {usersColumnConfig, usersCommandMap, usersDisplayedColumns} from "./users-table";
import {HttpClient} from "@angular/common/http";
import {API_BASE_URL} from "../utility/constants/api.constants";

@Component({
  selector: 'knust-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  displayedColumns = usersDisplayedColumns;
  columnConfig = usersColumnConfig;
  commandMap = usersCommandMap;

  constructor(public authService: AuthService,
              private dialog: MatDialog,
              private http: HttpClient,
              private store: Store) {}

  editUser(user?: User) {
    this.dialog.open(EditUserDialogComponent, {
      data: user,
      width: '80vw',
      maxWidth: '800px'
    })
      .afterClosed()
      .subscribe(user => {
        console.log(user);
      })
  }

  deleteUser(user: User) {
    this.store.dispatch(deleteUser({ user }));
  }

  testEmail(notifications?: boolean) {
    this.http.get(API_BASE_URL + '/mail/' + (notifications ? 'notifications' : 'orders')).subscribe();
  }
}
