import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as VendorArticleActions from "./vendor-article.actions";
import {getSelected, getVendorArticleQuery} from "./vendor-article.selectors";
import {NEW_ENTITY} from "../../../utility/constants/new-entity.constants";
import {onNavigation} from "../../../+state/on-navigation.operator";
import {VendorArticleService} from "../../../utility/services/vendor-article.service";
import {VendorArticlesComponent} from "../vendor-articles.component";
import {getSelectedId} from "../../../articles/+state/article.selectors";
import {TrashComponent} from "../../../trash/trash.component";
import {getTrashState} from "../../../+state/root.selectors";
import {StoreRootState} from "../../../+state/root.reducer";

@Injectable()
export class VendorArticleEffects {
  vendorArticleIndex$ = createEffect(() =>
    this
      .actions$.pipe(
      onNavigation(VendorArticlesComponent),
      map( () => VendorArticleActions.loadVendorArticles())
    )
  );

  vendorArticleTrash$ = createEffect(() =>
    this
      .actions$.pipe(
      onNavigation(TrashComponent),
      map( () => VendorArticleActions.loadVendorArticles(true))
    )
  );

  vendorArticleView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(VendorArticlesComponent),
      filter( action => action.payload.routerState.params['vendorArticleId'] !== NEW_ENTITY),
      map( (action) => {
        const vendorArticleId = parseInt(action.payload.routerState.params['vendorArticleId'], 10);

        if (!vendorArticleId) {
          throw new Error('vendorArticle id is missing');
        }

        return VendorArticleActions.setSelectedVendorArticle({ id: vendorArticleId });
      })
    )
  );

  selectVendorArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VendorArticleActions.setSelectedVendorArticle),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.vendorArticleService.loadVendorArticles().pipe(
              map(res => VendorArticleActions.loadVendorArticleDetailSuccess({ vendorArticle: res.items[0] }))
            );
          }

          return of(VendorArticleActions.loadVendorArticleDetailUnneeded());
        }),
        catchError((error) => {
          return of(VendorArticleActions.loadVendorArticlesFailure({ error }));
        })
      )
  );

  loadVendorArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(VendorArticleActions.loadVendorArticles),
        concatLatestFrom(() => [
          this.store.select(getVendorArticleQuery),
          this.store.select(getSelectedId),
          this.store.select(getTrashState)
        ]),
        switchMap(([action, query, articleId, trashState]) => {
          let completeQuery = query;
          completeQuery += '&deleted=' + trashState;

          if (!action.loadAll) {
            completeQuery += articleId ? ('&articleId=' + articleId) : '';
          }

          return this.vendorArticleService.loadVendorArticles('/' + completeQuery).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => VendorArticleActions.loadVendorArticlesSuccess({ vendorArticle: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
          return of(VendorArticleActions.loadVendorArticlesFailure({ error }));
        })
      )
  );

  setVendorArticleFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        VendorArticleActions.setVendorArticlePage,
        VendorArticleActions.setVendorArticleTerm,
        VendorArticleActions.setVendorArticleSort
      ),
      map(() => VendorArticleActions.loadVendorArticles())
    )
  );

  createVendorArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorArticleActions.createVendorArticle),
      concatLatestFrom(() => this.store.select(getSelectedId)),
      switchMap(([action, selectedArticleId]) => {
        const data = {...action.vendorArticle};

        if (selectedArticleId) {
          data.article = {id: selectedArticleId};
        }

        return this.vendorArticleService.saveVendorArticle(data).pipe(
          map(created =>
            VendorArticleActions.saveVendorArticleSuccess( { vendorArticle: created, insert: true })
          ),
          catchError(error => {
            return of(VendorArticleActions.saveVendorArticleFailure({ error }));
          })
        )
      })
    )
  );

  updateVendorArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorArticleActions.updateVendorArticle),
      concatLatestFrom(() => this.store.select(getSelectedId)),
      switchMap(([action, selectedId]) => {
        if (selectedId) {
          return this.vendorArticleService.saveVendorArticle({...action.vendorArticle, article: {id: selectedId}}).pipe(
            map(updated => {
              console.log('UPDATED VENDOR ARTICLE', updated);
                return VendorArticleActions.saveVendorArticleSuccess({vendorArticle: updated, insert: false})
              }
            ),
            catchError(error => {
              return of(VendorArticleActions.saveVendorArticleFailure({error}));
            })
          )
        }

        return of(VendorArticleActions.saveVendorArticleFailure({error: 'Kein Artikel ausgewählt.'}));
      })
    )
  );

  deleteVendorArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorArticleActions.deleteVendorArticle),
      switchMap((action) => {
        return this.vendorArticleService.deleteVendorArticle(action.vendorArticle).pipe(
          map(() =>
            VendorArticleActions.deleteVendorArticleSuccess( { id: action.vendorArticle.id ?? 0 })
          ),
          catchError(error => {
            return of(VendorArticleActions.deleteVendorArticleFailure({ error }));
          })
        )
      })
    )
  );

  restoreVendorArticle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VendorArticleActions.restoreVendorArticle),
      switchMap((action) => {
        return this.vendorArticleService.restoreVendorArticle(action.element).pipe(
          map(response =>
            VendorArticleActions.restoreVendorArticleSuccess( { vendorArticle: response })
          ),
          catchError(error => {
            return of(VendorArticleActions.restoreVendorArticleFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private vendorArticleService: VendorArticleService,
              private store: Store<StoreRootState>) {
  }
}
