import { IsString, MinLength, MaxLength, Matches } from "class-validator"

export class RegisterCredentialsDto {
    @IsString()
    @MinLength(4)
    @MaxLength(20)
    username!: string

    @IsString()
    @MinLength(6)
    @MaxLength(20)
    @Matches(
        /((?=.*\d)|(?=.*\w+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
        { message: 'Das gewählte Passwort ist zu schwach. Es muss mindestens 6 und maximal 20 Zeichen lang sein und jeweils ein Großbuchstaben. .'}
    )
    password!: string
}
