import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as BookingActions from "./bookings.actions";
import {
    getBookingArticlesQuery, getBookingQuery,
    getCompletedFilter,
    getReportsArticleFilter,
    getReportsCompletedFilter,
    getReportsDateEndFilter,
    getReportsDateStartFilter,
    getReportsUserFilter,
    getReportsVendorFilter,
    getSelected,
    getSelectedId
} from "./bookings.selectors";
import {BookingPartialState} from "./bookings.reducer";
import {BookingsIndexComponent} from "../bookings-index/bookings-index.component";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {BookingService, getTrunkReportsQuery, onNavigation} from "@knust/core";
import {BookingsDetailComponent} from "../bookings-detail/bookings-detail.component";
import {Router} from "@angular/router";
import {ReportsComponent} from "../../reports/reports.component";
import * as StorageLocationArticleActions
  from "../../reusable-components/storage-locations-articles/+state/storage-location-article.actions";
import {
  loadStorageLocationArticles
} from "../../reusable-components/storage-locations-articles/+state/storage-location-article.actions";

@Injectable()
export class BookingEffects {
  bookingsIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(BookingsIndexComponent),
      map( () => BookingActions.loadBooking())
    )
  );

  bookingsView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(BookingsDetailComponent),
      tap(() => console.log('Loading Booking Details')),
      map( (action) => {
        if (action.payload.routerState.params['bookingId'] === NEW_ENTITY) {
          return BookingActions.unsetSelectedBooking();
        }

        const bookingId = parseInt(action.payload.routerState.params['bookingId'], 10);

        if (!bookingId) {
          throw new Error('bookings id is missing');
        }

        return BookingActions.setSelectedBooking({ id: bookingId });
      })
    )
  );

  /*bookingsReports$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(ReportsComponent),
      tap(() => BookingActions.resetReportFilters()),
      map( () => BookingActions.loadBookingReports())
    )
  );*/

  selectBooking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.setSelectedBooking),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            console.log('Not selected - loading!')
            return this.bookingsService.loadBookingDetails(action.id).pipe(
              map(res => BookingActions.loadBookingDetailSuccess({ bookings: res }))
            );
          }

          return of(BookingActions.loadBookingDetailUnneeded());
        }),
        catchError((error) => {
          return of(BookingActions.loadBookingFailure({ error }));
        })
      )
  );

  loadBooking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.loadBooking),
        concatLatestFrom(() => [
          this.store.select(getBookingQuery),
          this.store.select(getCompletedFilter)
        ]),
        switchMap(([action, query, completed]) => {
          return this.bookingsService.loadBookings('/' + query + '&completed=' + (completed ? '1' : '0')).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => BookingActions.loadBookingSuccess({ bookings: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
          return of(BookingActions.loadBookingFailure({ error }));
        })
      )
  );

  loadBookingReports$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.loadBookingReports),
        concatLatestFrom(() => [
          this.store.select(getTrunkReportsQuery),
          this.store.select(getReportsCompletedFilter),
          this.store.select(getReportsUserFilter),
          this.store.select(getReportsArticleFilter),
          this.store.select(getReportsVendorFilter),
          this.store.select(getReportsDateStartFilter),
          this.store.select(getReportsDateEndFilter),
        ]),
        switchMap(([action, query, completed, userId, articleId, vendorId, startDate, endDate]) => {
          let completeQuery = query;

          console.log('LOAD BOOKINGS REPORTS', startDate, startDate?.toISOString());
          completeQuery += ('&completed=' + (completed ? '1' : '0'));
          completeQuery += ('&userId=' + (userId ?? ''));
          completeQuery += ('&articleId=' + (articleId ?? ''));
          completeQuery += ('&vendorId=' + (vendorId ?? ''));
          completeQuery += ('&startDate=' + (startDate ? startDate.toISOString().substring(0, 10) :  ''));
          completeQuery += ('&endDate=' + (endDate ? endDate.toISOString().substring(0, 10) : ''));
          completeQuery += ('&exportData=' + (action.download ? '1' : '0'));

          return this.bookingsService.loadBookingReports(completeQuery).pipe(
            map(res => {
              if (!action.download) {
                return BookingActions.loadBookingReportsSuccess({bookingsReports: res.items, total: res.meta.totalItems});
              } else {
                const downloadUrl = this.bookingsService.downloadBookingReport((res as any).filePath);

                const a = document.createElement("a");
                a.href = downloadUrl;
                const filename = "export-lagerbuchungen";
                a.setAttribute("download", filename + ".csv");
                a.setAttribute("target", "_blank");
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

                return BookingActions.downloadBookingReport({ url: (res as any).filePath });
              }
            }),
          );
        }),
        catchError((error) => {
          return of(BookingActions.loadBookingFailure({ error }));
        })
      )
  );

  /*loadBookingArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BookingActions.setSelectedBooking,
          BookingActions.loadBookingArticles
        ),
        concatLatestFrom(() => [
          this.store.select(getBookingArticlesQuery),
          this.store.select(getSelectedId)
        ]),
        switchMap(([action, query, bookingId]) => {
          if (bookingId) {
            return this.bookingsService.loadBookingArticles(bookingId, query).pipe(
              tap(() => console.log('Load BookingArticles Complete')),
              map(res => BookingActions.loadBookingArticlesSuccess({bookingsArticles: res.items, total: res.meta.totalItems})),
            );
          }

          return of(BookingActions.loadBookingArticlesFailure({ error: 'Keine Lagerbuchung ausgewählt.' }));
        }),
        catchError((error) => {
          return of(BookingActions.loadBookingArticlesFailure({ error }));
        })
      )
  );*/

  updateBookingArticles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.saveBookingSuccess),
        switchMap(() => {
          return of(BookingActions.loadBookingArticles());
        })
      ),
  )

  addBookingArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.addBookingArticle),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, selectedId]) => {
          if (selectedId) {
            return this.bookingsService.addBookingArticle(selectedId, action.bookingsArticle).pipe(
              map(res => BookingActions.addBookingArticleSuccess( { bookingsArticle: res }))
            )
          }

          return of(BookingActions.addBookingArticleFailure({ error: 'Keine Wareneingangsbuchung ausgewählt.' }));
        })
      )
  )

  deleteBookingArticle$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BookingActions.deleteBookingArticle),
        concatLatestFrom(() => this.store.select(getSelectedId)),
        switchMap(([action, selectedId]) => {
          if (selectedId) {
            return this.bookingsService.deleteBookingArticle(selectedId, action.bookingsArticle).pipe(
              map(() => BookingActions.deleteBookingArticleSuccess( { bookingsArticle: action.bookingsArticle }))
            )
          }

          return of(BookingActions.deleteBookingArticleFailure({ error: 'Keine Wareneingangsbuchung ausgewählt.' }));
        })
      )
  )

  setBookingFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingActions.setBookingPage,
        BookingActions.setBookingTerm,
        BookingActions.setBookingSort,
        BookingActions.changeCompletedFilter,
      ),
      map(() => BookingActions.loadBooking())
    )
  );

  setBookingReportsFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingActions.setBookingReportsPage,
        BookingActions.setBookingReportsTerm,
        BookingActions.setBookingReportsSort,
        BookingActions.changeReportsCompletedFilter,
        BookingActions.setReportsUserFilter,
        BookingActions.setReportsArticleFilter,
        BookingActions.setReportsVendorFilter,
        BookingActions.setReportsDateFilter,
      ),
      map(() => BookingActions.loadBookingReports())
    )
  );

  setBookingArticlesFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingActions.setBookingArticlesPage,
        BookingActions.setBookingArticlesTerm,
        BookingActions.setBookingArticlesSort
      ),
      map(() => BookingActions.loadBookingArticles())
    )
  );

  reloadBookingArticles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageLocationArticleActions.saveStorageLocationArticleSuccess
      ),
      map(() => StorageLocationArticleActions.loadStorageLocationArticles())
    )
  );

  createBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.createBooking),
      switchMap((action) => {
        return this.bookingsService.saveBooking(action.bookings).pipe(
          tap(() => console.log('Save Booking Effect Subscribe')),
          map(created =>
            BookingActions.saveBookingSuccess( { bookings: created, insert: true })
          ),
          catchError(error => {
            return of(BookingActions.saveBookingFailure({ error }));
          })
        )
      })
    )
  );

  updateBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.updateBooking),
      switchMap((action) => {
        return this.bookingsService.saveBooking(action.bookings).pipe(
          map(updated =>
            BookingActions.saveBookingSuccess( { bookings: updated, insert: false })
          ),
          catchError(error => {
            return of(BookingActions.saveBookingFailure({ error }));
          })
        )
      })
    )
  );

  saveBookingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingActions.saveBookingSuccess
      ),
      filter(action => action.insert),
      tap( action => {
        console.log('Starting Navigation', action.bookings);
        this.router.navigate(['lagerbuchung', action.bookings.id]);
      })
    ), {dispatch: false}
  );

  deleteBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.deleteBooking),
      switchMap((action) => {
        return this.bookingsService.deleteBooking(action.bookings).pipe(
          map(() =>
            BookingActions.deleteBookingSuccess( { id: action.bookings.id ?? 0 })
          ),
          catchError(error => {
            return of(BookingActions.deleteBookingFailure({ error }));
          })
        )
      })
    )
  );

  closeBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.closeBooking),
      switchMap((action) => {
        return this.bookingsService.closeBooking(action.bookingId, action.articles).pipe(
          map(res =>
            BookingActions.closeBookingSuccess({ bookingId: res.bookingId })
          )
        )
      })
    )
  )

  closeBookingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BookingActions.closeBookingSuccess
      ),
      tap( () => {
        this.router.navigate(['lagerbuchung']);
      })
    ), {dispatch: false}
  );

  saveNote = createEffect(() =>
    this.actions$.pipe(
      ofType(BookingActions.saveBookingNote),
      concatLatestFrom(() => [
        this.store.select(getSelectedId)
      ]),
      switchMap(([action, selectedId]) => {
        if (selectedId) {
          return this.bookingsService.saveNote(action.note, selectedId).pipe(
            map(res => BookingActions.saveBookingNoteSuccess( { note: res.note } ))
          )
        }

        return of(BookingActions.saveBookingNoteFailure( { error: 'Es ist keine Entnahme ausgewählt.' }));
      }),
    )
  )

  constructor(private readonly actions$: Actions,
              private router: Router,
              private bookingsService: BookingService,
              private store: Store<BookingPartialState>) {
  }
}
