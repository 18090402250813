import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {User} from "@knust/api-interfaces";

export const loadUsers = createAction(
  '[User] Load User',
);

export const loadUsersSuccess = createAction(
  '[User/API] Load User Success',
  props<{ user: User[], total: number }>()
);

export const loadUsersFailure = createAction(
  '[User/API] Load User Failure',
  props<{ error: any }>()
);

export const setSelectedUser = createAction(
  '[User/API] Set selected User',
  props<{ id: number }>()
);

export const loadUserDetailUnneeded = createAction(
  '[User/API] Load User Detail Unneeded'
);

export const loadUserDetailSuccess = createAction(
  '[User/API] Load User Detail Success',
  props<{ user: User }>()
);

export const loadUserDetailFailure = createAction(
  '[User/API] Load User Detail Failure',
  props<{ error: any }>()
);

export const setUserPage = createAction(
  '[User/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setUserTerm = createAction(
  '[User/API] Set Term',
  props<{ term: string }>()
);

export const setUserSort = createAction(
  '[User/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearUser = createAction(
  '[User/API] Clear'
);

export const createUser = createAction(
  '[User/API] Create User',
  props<{ user: Omit<User, 'id'> }>()
);

export const updateUser = createAction(
  '[User/API] Update User',
  props<{ user: User }>()
);

export const saveUserSuccess = createAction(
  '[User/API] Save User Success',
  props<{ user: User, insert: boolean }>()
);

export const saveUserFailure = createAction(
  '[User/API] Save User Failure',
  props<{ error: any }>()
);

export const deleteUser = createAction(
  '[User/API] Delete User',
  props<{ user: User }>()
);

export const deleteUserSuccess = createAction(
  '[User/API] Delete User Success',
  props<{ id: number | string }>()
);

export const deleteUserFailure = createAction(
  '[User/API] Delete User Failure',
  props<{ error: any }>()
);

export const restoreUser = createAction(
  '[User/API] Restore User',
  props<{ element: User }>()
);

export const restoreUserSuccess = createAction(
  '[User/API] Restore User Success',
  props<{ user: User }>()
);

export const restoreUserFailure = createAction(
  '[User/API] Restore User Failure',
  props<{ error: any }>()
);
