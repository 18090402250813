<div class="form-box">
  <knust-data-table
                    (entityRowClick)="openEmailDialog($event)"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns"
                    [disableFilterblock]="true"
                    [disableNewButton]="true"
                    [disablePagination]="true">
  </knust-data-table>
</div>
