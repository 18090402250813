import {Component} from "@angular/core";
import {Store} from "@ngrx/store";
import {createCategory, deleteCategory, loadCategories, updateCategory} from "./+state/category.actions";
import {Category} from "@knust/api-interfaces";
import {AuthService} from "../../utility/services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {CategoryDialogComponent} from "./category-dialog/category-dialog.component";
import {ConfirmComponent} from "../confirm/confirm.component";
import {categoriesColumnConfig, categoriesCommandMap, categoriesDisplayedColumns} from "./categories-table-config";

@Component({
  selector: 'knust-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent {
  commandMap = categoriesCommandMap;
  displayedColumns = categoriesDisplayedColumns;
  columnConfig = categoriesColumnConfig;

  constructor(private store: Store,
              private dialog: MatDialog,
              private authService: AuthService) {
    this.store.dispatch(loadCategories());
  }

  openCategoryDialog(category?: Category) {
    this.dialog.open(CategoryDialogComponent, {
      data: category,
      width: '40vw',
    })
      .afterClosed()
      .subscribe(category => {
        if (category && !category.id) {
          this.store.dispatch(createCategory( { category } ));
        } else if (category && category.id) {
          this.store.dispatch(updateCategory( { category }));
        }
      });
  }

  deleteCategory(category: Category) {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Kategorie wirklich löschen?',
        text: 'Möchten Sie diese Kategorie wirklich löschen?<br>Alle Artikel, die diese Kategorie verwenden, werden auf den Standard zurückgesetzt.',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteCategory({ category }));
        }
      })
  }
}
