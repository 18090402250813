import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'getNestedObject'
})
export class GetNestedObjectPipe implements PipeTransform {

  transform(row: any, refName: string, nestedPathArray: string | string[], fallback?: string): unknown {
    let lastObject = row[refName] ?? (fallback ?? 'Gelöschter Datensatz');

    if (typeof lastObject === 'string') {
      return lastObject;
    }

    if (Array.isArray(nestedPathArray)) {
      let i = 0;

      while (i < nestedPathArray.length) {
        lastObject = lastObject[nestedPathArray[i]] ?? (fallback ?? 'Gelöschter Datensatz');

        if (typeof lastObject === 'string') {
          return lastObject;
        }

        i++;
      }
    } else {
      lastObject = lastObject[nestedPathArray] ?? (fallback ?? 'Gelöschter Datensatz');
    }

    return lastObject;
  }

}
