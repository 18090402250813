import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {STORAGE_LOCATIONS_URL} from "../constants/api.constants";
import {StorageLocation} from "@knust/api-interfaces";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class StorageLocationService {

  constructor(private http: HttpClient) { }

  loadLocations(query?: string): Observable<PaginatedResponse<StorageLocation>> {
    return this.http.get<PaginatedResponse<StorageLocation>>(STORAGE_LOCATIONS_URL + (query ?? ''));
  }

  saveLocation(storageLocation: StorageLocation | Omit<StorageLocation, 'id'>): Observable<StorageLocation> {
    console.log('Save Location called', storageLocation);
    return this.http.post<StorageLocation>(STORAGE_LOCATIONS_URL, storageLocation);
  }

  deleteLocation(storageLocation: StorageLocation): Observable<StorageLocation> {
    return this.http.delete<StorageLocation>(STORAGE_LOCATIONS_URL + '/' + storageLocation.id);
  }

  restoreLocation(storageLocation: StorageLocation): Observable<StorageLocation> {
    return this.http.patch<StorageLocation>(STORAGE_LOCATIONS_URL + '/' + storageLocation.id, {});
  }
}
