<h1 mat-dialog-title>{{'Buchungseintrag ' + (data.deliveryArticle?.id ? 'editieren' : 'erstellen')}}</h1>

<mat-dialog-content>
  <form [formGroup]="deliveryArticleForm" (keydown.enter)="save()">
    <mat-form-field appearance="fill" formGroupName="mainArticle">
      <mat-label>Hauptartikel</mat-label>
      <mat-select formControlName="id">
        <mat-option>
          <ngx-mat-select-search [formControl]="articleFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let article of filteredArticles | async" [value]="article.id">
          {{article.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" formGroupName="vendorArticle">
      <mat-label>Lieferantenartikel</mat-label>
      <mat-select formControlName="id">
        <mat-option>
          <ngx-mat-select-search [formControl]="vendorArticleFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let vendorArticle of filteredVendorArticles | async" [value]="vendorArticle.id">
          {{vendorArticle.vendorArticleNumber}} - #{{vendorArticle.vendor.id}} {{vendorArticle.vendor.organization.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" formGroupName="storageLocation">
      <mat-label>Lagerort</mat-label>
      <mat-select formControlName="id">
        <mat-option>
          <ngx-mat-select-search [formControl]="storageLocationFilterCtrl"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let storageLocation of filteredStorageLocations | async" [value]="storageLocation.id">
          {{storageLocation.label}} - {{storageLocation.address.street}}, {{storageLocation.address.zip + ' ' + storageLocation.address.city}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Anzahl</mat-label>
      <input matInput type="number" formControlName="count">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Notiz</mat-label>
      <textarea matInput formControlName="note"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Abbrechen</button>

  <button mat-flat-button color="primary" [disabled]="deliveryArticleForm.invalid" (click)="save()">Speichern</button>
</mat-dialog-actions>
