import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {VendorArticle} from "@knust/api-interfaces";

export const loadVendorArticles = createAction(
  '[VendorArticle] Load VendorArticle',
  (loadAll: boolean = false) => ({ loadAll })
);

export const loadVendorArticlesSuccess = createAction(
  '[VendorArticle/API] Load VendorArticle Success',
  props<{ vendorArticle: VendorArticle[], total: number }>()
);

export const loadVendorArticlesFailure = createAction(
  '[VendorArticle/API] Load VendorArticle Failure',
  props<{ error: any }>()
);

export const setSelectedVendorArticle = createAction(
  '[VendorArticle/API] Set selected VendorArticle',
  props<{ id: number }>()
);

export const loadVendorArticleDetailUnneeded = createAction(
  '[VendorArticle/API] Load VendorArticle Detail Unneeded'
);

export const loadVendorArticleDetailSuccess = createAction(
  '[VendorArticle/API] Load VendorArticle Detail Success',
  props<{ vendorArticle: VendorArticle }>()
);

export const loadVendorArticleDetailFailure = createAction(
  '[VendorArticle/API] Load VendorArticle Detail Failure',
  props<{ error: any }>()
);

export const setVendorArticlePage = createAction(
  '[VendorArticle/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setVendorArticleTerm = createAction(
  '[VendorArticle/API] Set Term',
  props<{ term: string }>()
);

export const setVendorArticleSort = createAction(
  '[VendorArticle/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearVendorArticle = createAction(
  '[VendorArticle/API] Clear'
);

export const createVendorArticle = createAction(
  '[VendorArticle/API] Create VendorArticle',
  props<{ vendorArticle: Omit<VendorArticle, 'id'> }>()
);

export const updateVendorArticle = createAction(
  '[VendorArticle/API] Update VendorArticle',
  props<{ vendorArticle: VendorArticle }>()
);

export const saveVendorArticleSuccess = createAction(
  '[VendorArticle/API] Save VendorArticle Success',
  props<{ vendorArticle: VendorArticle, insert: boolean }>()
);

export const saveVendorArticleFailure = createAction(
  '[VendorArticle/API] Save VendorArticle Failure',
  props<{ error: any }>()
);

export const deleteVendorArticle = createAction(
  '[VendorArticle/API] Delete VendorArticle',
  props<{ vendorArticle: VendorArticle }>()
);

export const deleteVendorArticleSuccess = createAction(
  '[VendorArticle/API] Delete VendorArticle Success',
  props<{ id: number | string }>()
);

export const deleteVendorArticleFailure = createAction(
  '[VendorArticle/API] Delete VendorArticle Failure',
  props<{ error: any }>()
);

export const restoreVendorArticle = createAction(
  '[VendorArticle/API] Restore VendorArticle',
  props<{ element: VendorArticle }>()
);

export const restoreVendorArticleSuccess = createAction(
  '[VendorArticle/API] Restore VendorArticle Success',
  props<{ vendorArticle: VendorArticle }>()
);

export const restoreVendorArticleFailure = createAction(
  '[VendorArticle/API] Restore VendorArticle Failure',
  props<{ error: any }>()
);
