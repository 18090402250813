import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Category} from "@knust/api-interfaces";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'knust-vendor-article-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss'],
})
export class CategoryDialogComponent {
  categoryForm: UntypedFormGroup = this.fb.group({
    label: [null, Validators.required]
  });

  constructor(
    public dialogRef: MatDialogRef<CategoryDialogComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data?: Category,
  ) {
    console.log(data);
    if (data) {
      this.categoryForm.patchValue(data);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    if (this.categoryForm.valid) {
      this.dialogRef.close({
        id: this.data?.id ?? null,
        ...this.categoryForm.value,
      });
    } else {
      this.categoryForm.markAsTouched();
    }
  }
}
