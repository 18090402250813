import {Injectable} from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import {catchError, EMPTY, map, Observable} from "rxjs";
import {NotificationService} from "../services/notification.service";
import {AuthService} from "../services/auth.service";
import {AuthStatus} from "../enums/auth-status";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService, private authService: AuthService) {
  }


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse && event.body?.notification) {
          this.notificationService.createNotification({
            title: event.body.notification.title,
            text: event.body.notification.text,
            status: event.body.notification?.type ? event.body.notification?.type : 'message',
            dismissed: 0,
          });

          delete event.body.notification;
          return event;
        }

        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error);

        if (this.authService.currentStatus.value === AuthStatus.LOGGED_IN || error.error.level === 1) {
          this.notificationService.createNotification({
            title: 'Ein Fehler ist aufgetreten',
            text: error.error.message,
            status: 'error',
            dismissed: 0,
          });
        }

        return EMPTY;
      })
    );
  }
}
