import {Component} from "@angular/core";
import {
  mailColumnConfig,
  mailCommandMap, mailDisplayedColumns
} from './email-table-config';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '@knust/core';
import { loadEmails } from './+state/email.actions';
import { EmailTemplate } from '@knust/api-interfaces';
import { EmailTemplateDialogComponent } from './edit-email-templates-dialog/edit-email-templates-dialog.component';

@Component({
  selector: 'knust-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss'],
})

export class EmailTemplatesComponent {
  constructor(private store: Store,
              private dialog: MatDialog,
              private authService: AuthService) {
    this.store.dispatch(loadEmails());
  }

  protected readonly commandMap = mailCommandMap;
  protected readonly columnConfig = mailColumnConfig;
  protected readonly displayedColumns = mailDisplayedColumns;

  openEmailDialog(email?: EmailTemplate) {
    this.dialog.open(EmailTemplateDialogComponent, {
      data: email,
      width: '40vw',
    })
      .afterClosed()
      .subscribe();
  }
}
