import {Injectable} from "@angular/core";
import {catchError, filter, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as StorageLocationActions from "./storage-location.actions";
import {getSelected, getStorageLocationQuery} from "./storage-location.selectors";
import {StorageLocationComponent} from "../storage-location.component";
import {StorageLocationService} from "../../../utility/services/storage-location.service";
import {NEW_ENTITY} from "../../../utility/constants/new-entity.constants";
import {onNavigation} from "../../../+state/on-navigation.operator";
import {TrashComponent} from "../../../trash/trash.component";
import {StoreRootState} from "../../../+state/root.reducer";
import {getTrashState} from "../../../+state/root.selectors";

@Injectable()
export class StorageLocationEffects {
  storageLocationIndex$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(StorageLocationComponent),
      map( () => StorageLocationActions.loadStorageLocation())
    )
  );

  storageLocationTrash$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(TrashComponent),
      map( () => StorageLocationActions.loadStorageLocation())
    )
  );

  storageLocationView$ = createEffect(() =>
    this.actions$.pipe(
      onNavigation(StorageLocationComponent),
      filter( action => action.payload.routerState.params['storageLocationId'] !== NEW_ENTITY),
      map( (action) => {
        const storageLocationId = parseInt(action.payload.routerState.params['storageLocationId'], 10);

        if (!storageLocationId) {
          throw new Error('storageLocation id is missing');
        }

        return StorageLocationActions.setSelectedStorageLocation({ id: storageLocationId });
      })
    )
  );

  selectStorageLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StorageLocationActions.setSelectedStorageLocation),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.storageLocationService.loadLocations().pipe(
              map(res => StorageLocationActions.loadStorageLocationDetailSuccess({ storageLocation: res.items[0] }))
            );
          }

          return of(StorageLocationActions.loadStorageLocationDetailUnneeded());
        }),
        catchError((error) => {
          return of(StorageLocationActions.loadStorageLocationFailure({ error }));
        })
      )
  );

  loadStorageLocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(StorageLocationActions.loadStorageLocation),
        concatLatestFrom(() => [
          this.store.select(getStorageLocationQuery),
          this.store.select(getTrashState)
        ]),
        switchMap(([action, query, trashState]) => {
          return this.storageLocationService.loadLocations('/' + query + '&deleted=' + trashState).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => StorageLocationActions.loadStorageLocationSuccess({ storageLocation: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
          return of(StorageLocationActions.loadStorageLocationFailure({ error }));
        })
      )
  );

  setStorageLocationFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        StorageLocationActions.setStorageLocationPage,
        StorageLocationActions.setStorageLocationTerm,
        StorageLocationActions.setStorageLocationSort
      ),
      map(() => StorageLocationActions.loadStorageLocation())
    )
  );

  createStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationActions.createStorageLocation),
      switchMap((action) => {
        return this.storageLocationService.saveLocation(action.storageLocation).pipe(
          map(created =>
            StorageLocationActions.saveStorageLocationSuccess( { storageLocation: created, insert: true })
          ),
          catchError(error => {
            return of(StorageLocationActions.saveStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  updateStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationActions.updateStorageLocation),
      switchMap((action) => {
        return this.storageLocationService.saveLocation(action.storageLocation).pipe(
          map(updated => StorageLocationActions.saveStorageLocationSuccess( { storageLocation: updated, insert: false })
          ),
          catchError(error => {
            return of(StorageLocationActions.saveStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  deleteStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationActions.deleteStorageLocation),
      switchMap((action) => {
        return this.storageLocationService.deleteLocation(action.storageLocation).pipe(
          map((response) =>
            StorageLocationActions.deleteStorageLocationSuccess( { id: response.id })
          ),
          catchError(error => {
            return of(StorageLocationActions.deleteStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  restoreStorageLocation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(StorageLocationActions.restoreStorageLocation),
      switchMap((action) => {
        return this.storageLocationService.restoreLocation(action.element).pipe(
          map((response) =>
            StorageLocationActions.restoreStorageLocationSuccess( { storageLocation: response })
          ),
          catchError(error => {
            return of(StorageLocationActions.restoreStorageLocationFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private storageLocationService: StorageLocationService,
              private store: Store<StoreRootState>) {
  }
}
