import {Component, Input, OnInit} from "@angular/core";
import {MatDialog} from "@angular/material/dialog";
import {ActionsSubject, Store} from "@ngrx/store";
import {
  createVendorArticle,
  deleteVendorArticle,
  loadVendorArticles,
  updateVendorArticle
} from "./+state/vendor-article.actions";
import {UserEnum, VendorArticle} from "@knust/api-interfaces";
import {VendorArticleDialogComponent} from "./vendor-article-dialog/vendor-article-dialog.component";
import {getAllVendorArticle} from "./+state/vendor-article.selectors";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {
  vendorArticlesColumnConfig,
  vendorArticlesCommandMap,
  vendorArticlesDisplayedColumns
} from "./vendor-articles-table";
import {AuthService} from "../../utility/services/auth.service";
import {ConfirmComponent} from "../confirm/confirm.component";
import {ofType} from "@ngrx/effects";
import * as VendorArticleActions from "./+state/vendor-article.actions";
import {NotificationService} from "../../utility/services/notification.service";
import {formatCurrency, formatNumber} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'knust-vendor-articles',
  templateUrl: './vendor-articles.component.html',
  styleUrls: ['./vendor-articles.component.scss'],
})
export class VendorArticlesComponent implements OnInit {
  @Input() openDialog = false;

  isFirstVendorArticle = false;

  commandMap = vendorArticlesCommandMap;
  displayedColumns = vendorArticlesDisplayedColumns;
  columnConfig = vendorArticlesColumnConfig;

  constructor(private dialog: MatDialog,
              private authService: AuthService,
              private actionsSubject: ActionsSubject,
              private notificationService: NotificationService,
              private store: Store) {
    this.store.dispatch(loadVendorArticles());

    this.actionsSubject.pipe(
      ofType(VendorArticleActions.loadVendorArticlesSuccess)
    ).subscribe(res => {
      this.isFirstVendorArticle = res.vendorArticle.length === 0;

      if (this.isFirstVendorArticle && (authService.isAdmin() || authService.isPurchasing())) {
        this.openVendorArticleDialog(undefined, true);
      }
    });

    if (authService.currentUser.value.type === UserEnum.ADMIN) {
      this.displayedColumns = [...this.displayedColumns, 'deleteAction'];
    }
  }

  ngOnInit() {
    if (this.openDialog) {
      this.openVendorArticleDialog();
    }
  }

  openVendorArticleDialog(vendorArticle?: VendorArticle, disableClose?: boolean) {
    if (this.dialog.openDialogs.length !== 0) {
      return;
    }
    
    this.dialog.open(VendorArticleDialogComponent, {
      data: {
        vendorArticle,
        isFirstVendorArticle: this.isFirstVendorArticle,
        disableClose
      },
      disableClose,
      width: '40vw',
    })
      .afterClosed()
      .subscribe(vendorArticle => {
        if (vendorArticle) {
          if (!vendorArticle.id) {
            this.store.dispatch(createVendorArticle({vendorArticle}));
          } else {
            this.store.dispatch(updateVendorArticle({vendorArticle}));
          }
        }
      });
  }

  deleteVendorArticle(vendorArticle: VendorArticle) {
    if (!vendorArticle.isMainVendorArticle) {
      this.dialog.open(ConfirmComponent, {
        data: {
          title: 'Löschen bestätigen',
          text: 'Möchten Sie diese Lieferanten-Artikel-Beziehung wirklich löschen?',
          buttonColor: 'warn'
        }
      })
        .afterClosed()
        .subscribe(confirm => {
          if (confirm) {
            this.store.dispatch(deleteVendorArticle({vendorArticle}));
          }
        })
    } else {
      this.notificationService.createNotification({
        title: 'Löschen nicht möglich',
        text: 'Der Artikel des Hauptlieferanten kann nicht gelöscht werden',
        status: 'error',
        dismissed: 0,
      });
    }
  }
}
