<h1 mat-dialog-title>{{'Lagerort ' + (data ? 'editieren' : 'erstellen')}}</h1>
<form mat-dialog-content [formGroup]="storageLocationForm" (keydown.enter)="save()">
  <div class="input-columns-container">
    <div class="input-column">
      <mat-form-field appearance="fill">
        <mat-label>Bezeichnung</mat-label>
        <input matInput formControlName="label">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>E-Mail für Bestandsmeldungen</mat-label>
        <input matInput type="email" formControlName="notificationMail">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>E-Mail für Kopien der Bestellungen</mat-label>
        <input matInput placeholder="info@bestellungen.de" type="email" formControlName="orderCCMail">
      </mat-form-field>
    </div>

    <div class="input-column">
      <mat-form-field appearance="fill">
        <mat-label>Notiz</mat-label>
        <textarea matInput formControlName="note"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div formGroupName="address" class="address-container">
    <h3>Adresse</h3>

    <div class="address-column">
      <mat-form-field appearance="fill">
        <mat-label>Straße</mat-label>
        <input matInput formControlName="street">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>PLZ</mat-label>
        <input matInput formControlName="zip">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Stadt</mat-label>
        <input matInput formControlName="city">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Land</mat-label>
        <mat-select formControlName="country">
          <mat-option [value]="'Deutschland'">
            Deutschland
          </mat-option>
          <mat-option [value]="'Österreich'">
            Österreich
          </mat-option>
          <mat-option [value]="'Schweiz'">
            Schweiz
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="address-column">
      <mat-form-field appearance="fill">
        <mat-label>Zusatz</mat-label>
        <input matInput formControlName="addressSupplement">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Längengrad</mat-label>
        <input matInput formControlName="latitude">
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Breitengrad</mat-label>
        <input matInput formControlName="longitude">
      </mat-form-field>
    </div>
  </div>
</form>
<div mat-dialog-actions>
  <button mat-flat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()"
          [disabled]="storageLocationForm.invalid || storageLocationForm.pristine">
    Speichern
    <mat-icon>save</mat-icon>
  </button>
</div>
