import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BASE_DATA_URL} from "../constants/api.constants";
import {BaseData} from "@knust/api-interfaces";

@Injectable({
  providedIn: 'root'
})
export class BaseDataService {

  constructor(private http: HttpClient) { }

  loadDetails() {
    return this.http.get<BaseData>(BASE_DATA_URL);
  }

  saveDetails(baseData: BaseData) {
    return this.http.post<BaseData>(BASE_DATA_URL, baseData);
  }

  uploadLogo(formData: FormData) {
    return this.http.post(BASE_DATA_URL + '/logo/upload', formData);
  }
}
