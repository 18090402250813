<h1>Papierkorb</h1>

<div class="form-box">
  <h3>Gelöschte Artikel</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, articleCommandMap)"
                    [commandMap]="articleCommandMap"
                    [columnConfig]="articleColumnConfig"
                    [displayedColumns]="articleDisplayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Gelöschte Lagerorte</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, storageLocationCommandMap)"
                    [commandMap]="storageLocationCommandMap"
                    [columnConfig]="storageLocationColumnConfig"
                    [displayedColumns]="storageLocationDisplayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Gelöschte Kategorien</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, categoriesCommandMap)"
                    [commandMap]="categoriesCommandMap"
                    [columnConfig]="categoriesColumnConfig"
                    [displayedColumns]="categoriesDisplayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Gelöschte Lieferanten</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, vendorsCommandMap)"
                    [commandMap]="vendorsCommandMap"
                    [columnConfig]="vendorsColumnConfig"
                    [displayedColumns]="vendorsDisplayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Gelöschte Lieferanten-Artikel-Beziehungen</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, vendorArticlesCommandMap)"
                    [commandMap]="vendorArticlesCommandMap"
                    [columnConfig]="vendorArticlesColumnConfig"
                    [displayedColumns]="vendorArticlesDisplayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Gelöschte Benutzer</h3>

  <knust-data-table [disableNewButton]="true"
                    [disableEditing]="true"
                    (restoreEntityClick)="restoreElement($event, usersCommandMap)"
                    [commandMap]="usersCommandMap"
                    [columnConfig]="usersColumnConfig"
                    [displayedColumns]="usersDisplayedColumns">
  </knust-data-table>
</div>
