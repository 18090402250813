import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {TrashRoutingModule} from "./trash-routing.module";
import {TrashComponent} from "./trash.component";
import {ArticlesModule} from "../articles/articles.module";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {CategoriesModule} from "../reusable-components/categories/categories.module";
import {BaseDataModule} from "../base-data/base-data.module";
import {VendorsModule} from "../vendors/vendors.module";
import {VendorArticlesModule} from "../reusable-components/vendor-articles/vendor-articles.module";
import {UsersModule} from "../users/users.module";

@NgModule({
  declarations: [TrashComponent],
  imports: [
    CommonModule,
    TrashRoutingModule,
    ArticlesModule,
    CategoriesModule,
    BaseDataModule,
    VendorsModule,
    VendorArticlesModule,
    DataTableModule,
    UsersModule
  ],
})
export class TrashModule {}
