import {createFeatureSelector, createSelector} from "@ngrx/store";

import {State, VENDOR_ARTICLE_FEATURE_KEY, vendorArticleAdapter} from "./vendor-article.reducer";
import {StateToQuery} from "../../../utility/abstract-index/state-to-query.func";

// Lookup the 'VendorArticle' feature state managed by NgRx
export const getVendorArticleState = createFeatureSelector<State>(VENDOR_ARTICLE_FEATURE_KEY);

const { selectAll, selectEntities } = vendorArticleAdapter.getSelectors();

export const getVendorArticleLoaded = createSelector(
  getVendorArticleState,
  (state: State) => state.loaded
);

export const getVendorArticleError = createSelector(
  getVendorArticleState,
  (state: State) => state.error
);

export const getVendorArticleTotal = createSelector(
  getVendorArticleState,
  (state: State) => state.total
);

export const getVendorArticlePage = createSelector(
  getVendorArticleState,
  (state: State) => state.page
);

export const getVendorArticleTerm = createSelector(
  getVendorArticleState,
  (state: State) => state.term
);

export const getVendorArticleSort = createSelector(
  getVendorArticleState,
  (state: State) => state.sort
);

export const getVendorArticleQuery = createSelector(
  getVendorArticleState,
  (state: State) => StateToQuery(state)
);

export const getAllVendorArticle = createSelector(
  getVendorArticleState,
  (state: State) => selectAll(state)
);

export const getVendorArticleEntities = createSelector(
  getVendorArticleState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getVendorArticleState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getVendorArticleEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
