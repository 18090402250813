import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {STORAGE_LOCATION_ARTICLES_URL} from "../constants/api.constants";
import {StorageLocationArticle} from "@knust/api-interfaces";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class StorageLocationArticleService {

  constructor(private http: HttpClient) { }

  loadStorageLocationArticles(query?: string): Observable<PaginatedResponse<StorageLocationArticle>> {
    return this.http.get<PaginatedResponse<StorageLocationArticle>>(STORAGE_LOCATION_ARTICLES_URL + (query ?? ''));
  }

  getStorageLocationArticleDetails(id: string | number) {
    return this.http.get<StorageLocationArticle>(STORAGE_LOCATION_ARTICLES_URL + '/details/' + id);
  }

  saveLocation(storageLocationArticle: StorageLocationArticle): Observable<StorageLocationArticle> {
    return this.http.post<StorageLocationArticle>(STORAGE_LOCATION_ARTICLES_URL, storageLocationArticle);
  }

  deleteLocation(storageLocationArticle: StorageLocationArticle) {
    return this.http.delete(STORAGE_LOCATION_ARTICLES_URL + '/' + storageLocationArticle.id);
  }

  exportStorageLocationArticles(): Observable<{ filePath: string }> {
    return this.http.get<{ filePath: string }>(STORAGE_LOCATION_ARTICLES_URL + '/export');
  }
}
