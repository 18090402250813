<h1>Warenkorb</h1>

<div class="form-box">
  <table mat-table
         matSort
         [dataSource]="dataSource">

    <ng-container *ngFor="let column of columnConfig"
                  [matColumnDef]="(column.nestedName && column.useNestedName) ? (column.refName | getNestedName : column.nestedName) : column.refName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
          [disabled]="column.disableSort ?? false"> {{column.name}} </th>
      <td mat-cell *matCellDef="let row">
        <ng-container>
          {{column.nestedName ? (row | getNestedObject : column.refName : column.nestedName) : row[column.refName]}}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="withdrawnStock" *ngIf="displayedColumns.includes('withdrawnStock')">
      <th mat-header-cell *matHeaderCellDef> Menge </th>
      <td mat-cell *matCellDef="let row; index as i">
        <div class="withdraw-stock-input-container">
          <mat-form-field appearance="outline">
            <mat-label>Anzahl</mat-label>
            <input matInput type="number" class="buy-stock-input"
                   min="0"
                   [max]="row.storageLocationArticle.actualStock"
                   [step]="row.storageLocationArticle.article.samplingStep"
                   [(ngModel)]="withdrawnStock[i]" (ngModelChange)="withdrawnStockChange = true">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Kommentar</mat-label>
            <input matInput type="text" class="buy-stock-input"
                   [(ngModel)]="withdrawnStockNotes[i]" (ngModelChange)="withdrawnStockChange = true">
          </mat-form-field>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="deleteItemFromCart" *ngIf="displayedColumns.includes('deleteItemFromCart')">
      <th mat-header-cell *matHeaderCellDef>Artikel entfernen</th>
      <td mat-cell *matCellDef="let row; index as i">
        <button mat-stroked-button color="warn" (click)="deleteItemFromCart(i)">
          Entfernen
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="table-row"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell no-data-cell" [colSpan]="displayedColumns.length">Es wurden keine Daten gefunden.</td>
    </tr>
  </table>

  <div class="note-row">
    <mat-form-field appearance="fill">
      <mat-label>Notiz zur Entnahme</mat-label>
      <textarea matInput placeholder="Benötigt für Reperatur bei Kunden..." [formControl]="noteControl"
                (ngModelChange)="withdrawnStockChange = true"></textarea>
    </mat-form-field>
  </div>

  <div class="action-row">
    <button mat-stroked-button (click)="saveCart()" [disabled]="!withdrawnStockChange" class="save-cart-btn">
      Änderungen speichern
      <mat-icon>save</mat-icon>
    </button>

    <button mat-flat-button color="primary" (click)="saveCart(true)" class="reserve-items-btn">
      Waren reservieren
      <mat-icon>inventory</mat-icon>
    </button>
  </div>
</div>
