<h1>Lieferanten</h1>

<div class="form-box">
  <knust-data-table (entityRowClick)="navigateToVendor($event)"
                    (addEntityClick)="createVendor()"
                    newButtonText="Neuen Lieferanten erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>
