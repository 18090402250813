import {createFeatureSelector, createSelector} from "@ngrx/store";

import {State, USER_FEATURE_KEY, userAdapter} from "./user.reducer";
import {StateToQuery} from "../../utility/abstract-index/state-to-query.func";

// Lookup the 'User' feature state managed by NgRx
export const getUserState = createFeatureSelector<State>(USER_FEATURE_KEY);

const { selectAll, selectEntities } = userAdapter.getSelectors();

export const getUserLoaded = createSelector(
  getUserState,
  (state: State) => state.loaded
);

export const getUserError = createSelector(
  getUserState,
  (state: State) => state.error
);

export const getUserTotal = createSelector(
  getUserState,
  (state: State) => state.total
);

export const getUserPage = createSelector(
  getUserState,
  (state: State) => state.page
);

export const getUserTerm = createSelector(
  getUserState,
  (state: State) => state.term
);

export const getUserSort = createSelector(
  getUserState,
  (state: State) => state.sort
);

export const getUserQuery = createSelector(
  getUserState,
  (state: State) => StateToQuery(state)
);

export const getAllUser = createSelector(
  getUserState,
  (state: State) => selectAll(state)
);

export const getUserEntities = createSelector(
  getUserState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getUserState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getUserEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
