import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {REPORTS_URL, TRUNK_CART_URL, TRUNK_URL} from "../constants/api.constants";
import {HttpClient} from "@angular/common/http";
import {ChangeTrunkRequest, Trunk, TrunkArticle, TrunkBooking} from "@knust/api-interfaces";
import {PaginatedResponse} from "../interfaces/paginated-response";

@Injectable({
  providedIn: 'root'
})
export class TrunkService {
  constructor(private http: HttpClient) {
  }

  loadCart(): Observable<Trunk> {
    return this.http.get<Trunk>(TRUNK_URL + '/cart');
  }

  loadTrunks(query?: string): Observable<PaginatedResponse<Trunk>> {
    return this.http.get<PaginatedResponse<Trunk>>(TRUNK_URL + query);
  }

  loadTrunkDetails(id: number | string): Observable<Trunk> {
    return this.http.get<Trunk>(TRUNK_URL + '/details/' + id);
  }

  loadTrunkArticles(id: number | string, query?: string): Observable<PaginatedResponse<TrunkArticle>> {
    return this.http.get<PaginatedResponse<TrunkArticle>>(TRUNK_URL + '/articles/' + id + query);
  }

  loadTrunkBookings(id: number | string, query?: string): Observable<PaginatedResponse<TrunkBooking>> {
    return this.http.get<PaginatedResponse<TrunkBooking>>(TRUNK_URL + '/bookings/' + id + query);
  }

  loadTrunkReports(query?: string): Observable<PaginatedResponse<Trunk>> {
    return this.http.get<PaginatedResponse<Trunk>>(REPORTS_URL + '/trunks' + (query ?? ''));
  }

  downloadTrunkReport(filePath: string) {
    return REPORTS_URL + '/download/' + filePath;
  }

  changeItemInTrunk(request: ChangeTrunkRequest): Observable<{ articles: TrunkArticle[] }> {
    return this.http.post<{ articles: TrunkArticle[] }>(TRUNK_URL + '/change-amount', request);
  }

  resetTrunk(trunkId: number) {
    return this.http.post(TRUNK_URL + '/reset/' + trunkId, {});
  }

  closeTrunk(withdrawnStockChanges: { trunkArticleId: number; amount: number; }[], trunkId: number): Observable<Trunk> {
    return this.http.patch<Trunk>(TRUNK_URL + '/close/' + trunkId, withdrawnStockChanges);
  }

  addItemsToCart(item: { storageLocationArticleId: number, amount: number }[]): Observable<Trunk> {
    return this.http.post<Trunk>(TRUNK_CART_URL, item);
  }

  changeItemInCart(withdrawnStockChanges: { trunkArticleId: number, amount: number; note?: string }[], note: string | null): Observable<{ trunkArticles: TrunkArticle[], note: string | null }> {
    return this.http.post<{ trunkArticles: TrunkArticle[], note: string | null }>(TRUNK_CART_URL + '/change-amount', { withdrawnStockChanges, note });
  }

  deleteItemFromCart(trunkArticleId: number): Observable<{ id: number }> {
    return this.http.patch<{ id: number }>(TRUNK_CART_URL + '/delete', { trunkArticleId });
  }

  closeCart(withdrawnStockChanges: { trunkArticleId: number; amount: number; }[], note: string | null): Observable<Trunk> {
    return this.http.patch<Trunk>(TRUNK_CART_URL + '/close', { withdrawnStockChanges, note });
  }

  saveNote(note: string | null, id: number) {
    return this.http.post<{ note: string }>(TRUNK_URL + '/note/' + id, { note });
  }
}
