<h1>Berichte</h1>

<div class="form-box">
  <h3>Wareneingangsbuchungen</h3>

  <knust-data-table [disableNewButton]="true"
                    (entityActionClick)="navigateToDelivery($event)"
                    [enableExpandableRows]="true"
                    [expandedColumns]="['expandedDeliveries']"
                    [commandMap]="deliveryReportsCommandMap"
                    [columnConfig]="deliveryReportsColumnConfig"
                    [displayedColumns]="deliveryReportsDisplayedColumns">
    <mat-slide-toggle color="primary" [checked]="showCompletedDeliveries" (toggleChange)="setDeliveriesCompletedFilter()">
      Abgeschlossene Buchungen anzeigen
    </mat-slide-toggle>

    <div class="advanced-filters-row">
      <mat-form-field appearance="fill">
        <mat-label>Benutzer</mat-label>
        <mat-select (selectionChange)="setDeliveriesUserFilter($event.value)">
          <mat-option>
            <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
            {{user.person.firstName}} {{user.person.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Artikel</mat-label>
        <mat-select (selectionChange)="setDeliveriesArticleFilter($event.value)">
          <mat-option>
            <ngx-mat-select-search [formControl]="articleFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let article of filteredArticles | async" [value]="article.id">
            {{article.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Lieferant</mat-label>
        <mat-select (selectionChange)="setDeliveriesVendorFilter($event.value)">
          <mat-option>
            <ngx-mat-select-search [formControl]="vendorFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let vendor of filteredVendors | async" [value]="vendor.id">
            {{vendor.organization.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Datumsbereich</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Startdatum" disabled (dateChange)="setDeliveriesDateStartFilter($event.value)">
          <input matEndDate placeholder="Enddatum" disabled (dateChange)="setDeliveriesDateEndFilter($event.value)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker disabled="false">
          <mat-date-range-picker-actions>
            <button mat-button (click)="resetDatepicker()">Zurücksetzen</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Bestätigen</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>

      <button class="export-button" mat-flat-button color="primary" (click)="exportDeliveryReports()">
        Daten exportieren
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Warenentnahmen</h3>

  <knust-data-table [disableNewButton]="true"
                    (entityActionClick)="navigateToTrunk($event)"
                    [enableExpandableRows]="true"
                    [expandedColumns]="['expandedTrunks']"
                    [commandMap]="trunkReportsCommandMap"
                    [columnConfig]="trunkReportsColumnConfig"
                    [displayedColumns]="trunkReportsDisplayedColumns">
    <mat-slide-toggle color="primary" [checked]="showCompletedTrunks" (toggleChange)="setTrunksCompletedFilter()">
      Abgeschlossene Entnahmen anzeigen
    </mat-slide-toggle>

    <div class="advanced-filters-row">
      <mat-form-field appearance="fill">
        <mat-label>Benutzer</mat-label>
        <mat-select (selectionChange)="setTrunksUserFilter($event.value)">
          <mat-option>
            <ngx-mat-select-search [formControl]="userFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let user of filteredUsers | async" [value]="user.id">
            {{user.person.firstName}} {{user.person.lastName}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Artikel</mat-label>
        <mat-select (selectionChange)="setTrunksArticleFilter($event.value)">
          <mat-option>
            <ngx-mat-select-search [formControl]="articleFilterCtrl"></ngx-mat-select-search>
          </mat-option>
          <mat-option *ngFor="let article of filteredArticles | async" [value]="article.id">
            {{article.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Datumsbereich</mat-label>
        <mat-date-range-input [rangePicker]="trunkPicker">
          <input matStartDate placeholder="Startdatum" disabled (dateChange)="setTrunksDateStartFilter($event.value)">
          <input matEndDate placeholder="Enddatum" disabled (dateChange)="setTrunksDateEndFilter($event.value)">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="trunkPicker"></mat-datepicker-toggle>
        <mat-date-range-picker #trunkPicker disabled="false">
          <mat-date-range-picker-actions>
            <button mat-button (click)="resetDatepicker()">Zurücksetzen</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>Bestätigen</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>

      <button class="export-button" mat-flat-button color="primary" (click)="exportTrunkReports()">
        Daten exportieren
        <mat-icon>file_download</mat-icon>
      </button>
    </div>
  </knust-data-table>
</div>
