import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {VendorsIndexComponent} from "./vendors-index/vendors-index.component";
import {VendorsDetailComponent} from "./vendors-detail/vendors-detail.component";

const routes: Routes = [
  {
    path: '',
    component: VendorsIndexComponent
  },
  {
    path: ':vendorId',
    component: VendorsDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class VendorsRoutingModule { }
