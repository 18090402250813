<h1>Artikel importieren / exportieren</h1>

<div class="form-box">
  <section>
    <h3>Neue Artikel importieren</h3>

    <input type="file" (change)="selectFile($event)">
    <button mat-flat-button color="primary" (click)="upload()">Datei hochladen</button>
  </section>

  <section>
    <h3>Beispieldatei herunterladen</h3>

    <a mat-stroked-button href="/assets/csv/import-example.csv" download>
      Download
      <mat-icon>download</mat-icon>
    </a>
  </section>
</div>

<div class="form-box">
  <h3>Bereits importierte Artikel</h3>

  <knust-data-table (entityRowClick)="navigateToArticleEditing($event)"
                    (entityActionClick)="saveImportArticle($event)"
                    (deleteEntityClick)="deleteImportArticle($event)"
                    [disableNewButton]="true"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>

<div class="form-box">
  <h3>Artikellagerorte exportieren</h3>

  <button class="export-button" mat-flat-button color="primary" (click)="exportArticles()">
    Artikellagerorte exportieren
    <mat-icon>file_download</mat-icon>
  </button>
</div>
