import {createFeatureSelector, createSelector} from "@ngrx/store";

import {CATEGORY_FEATURE_KEY, categoryAdapter, State} from "./category.reducer";
import {StateToQuery} from "../../../utility/abstract-index/state-to-query.func";

// Lookup the 'Category' feature state managed by NgRx
export const getCategoryState = createFeatureSelector<State>(CATEGORY_FEATURE_KEY);

const { selectAll, selectEntities } = categoryAdapter.getSelectors();

export const getCategoryLoaded = createSelector(
  getCategoryState,
  (state: State) => state.loaded
);

export const getCategoryError = createSelector(
  getCategoryState,
  (state: State) => state.error
);

export const getCategoryTotal = createSelector(
  getCategoryState,
  (state: State) => state.total
);

export const getCategoryPage = createSelector(
  getCategoryState,
  (state: State) => state.page
);

export const getCategoryTerm = createSelector(
  getCategoryState,
  (state: State) => state.term
);

export const getCategorySort = createSelector(
  getCategoryState,
  (state: State) => state.sort
);

export const getCategoryQuery = createSelector(
  getCategoryState,
  (state: State) => StateToQuery(state)
);

export const getAllCategory = createSelector(
  getCategoryState,
  (state: State) => selectAll(state)
);

export const getCategoryEntities = createSelector(
  getCategoryState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getCategoryState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getCategoryEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
