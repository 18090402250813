import {createFeatureSelector, createSelector} from "@ngrx/store";

import {
  STORAGE_LOCATION_ARTICLE_FEATURE_KEY,
  storageLocationArticleAdapter,
  State
} from "./storage-location-article.reducer";
import {StateToQuery} from "../../../utility/abstract-index/state-to-query.func";

// Lookup the 'StorageLocationArticle' feature state managed by NgRx
export const getStorageLocationArticleState = createFeatureSelector<State>(STORAGE_LOCATION_ARTICLE_FEATURE_KEY);

const { selectAll, selectEntities } = storageLocationArticleAdapter.getSelectors();

export const getStorageLocationArticleLoaded = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.loaded
);

export const getStorageLocationArticleError = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.error
);

export const getStorageLocationArticleTotal = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.total
);

export const getEmptyFilter = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.showEmpty
);

export const getFilterId = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.filterId
);

export const getBookingFilter = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.filterBooking
);

export const getStorageLocationArticlePage = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.page
);

export const getStorageLocationArticleTerm = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.term
);

export const getStorageLocationArticleSort = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.sort
);

export const getStorageLocationArticleQuery = createSelector(
  getStorageLocationArticleState,
  (state: State) => StateToQuery(state)
);

export const getAllStorageLocationArticle = createSelector(
  getStorageLocationArticleState,
  (state: State) => selectAll(state)
);

export const getStorageLocationArticleEntities = createSelector(
  getStorageLocationArticleState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getStorageLocationArticleState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getStorageLocationArticleEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
