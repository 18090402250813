import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {StorageLocationArticle} from "@knust/api-interfaces";

export const loadStorageLocationArticles = createAction(
  '[StorageLocationArticle] Load StorageLocationArticle',
  (payload: { filterId: number | undefined, bookingId: string | number | undefined, completed: boolean } = {
    filterId: undefined,
    bookingId: undefined,
    completed: false,
  }) => payload
);

export const loadStorageLocationArticlesSuccess = createAction(
  '[StorageLocationArticle/API] Load StorageLocationArticle Success',
  props<{ storageLocationArticle: StorageLocationArticle[], total: number }>()
);

export const loadStorageLocationArticlesFailure = createAction(
  '[StorageLocationArticle/API] Load StorageLocationArticle Failure',
  props<{ error: any }>()
);

export const resetStorageLocationArticles = createAction(
  '[StorageLocationArticle/API] Reset StorageLocationArticle',
);

export const setSelectedStorageLocationArticle = createAction(
  '[StorageLocationArticle/API] Set selected StorageLocationArticle',
  props<{ id: number }>()
);

export const loadStorageLocationArticleDetailUnneeded = createAction(
  '[StorageLocationArticle/API] Load StorageLocationArticle Detail Unneeded'
);

export const loadStorageLocationArticleDetailSuccess = createAction(
  '[StorageLocationArticle/API] Load StorageLocationArticle Detail Success',
  props<{ storageLocationArticle: StorageLocationArticle }>()
);

export const loadStorageLocationArticleDetailFailure = createAction(
  '[StorageLocationArticle/API] Load StorageLocationArticle Detail Failure',
  props<{ error: any }>()
);

export const changeEmptyFilter = createAction(
  '[Delivery/API] Set Empty Filter',
)

export const setLocationFilter = createAction(
  '[Delivery/API] Set Location Filter',
  props<{ filterId: number }>()
)

export const setBookingFilter = createAction(
  '[Delivery] Set Booking Filter',
  props<{ bookingId: string | number | undefined, completed: boolean }>()
)

export const setStorageLocationArticlePage = createAction(
  '[StorageLocationArticle/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setStorageLocationArticleTerm = createAction(
  '[StorageLocationArticle/API] Set Term',
  props<{ term: string }>()
);

export const setStorageLocationArticleSort = createAction(
  '[StorageLocationArticle/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearStorageLocationArticle = createAction(
  '[StorageLocationArticle/API] Clear'
);

export const createStorageLocationArticle = createAction(
  '[StorageLocationArticle/API] Create StorageLocationArticle',
  props<{ storageLocationArticle: Omit<StorageLocationArticle, 'id'> }>()
);

export const updateStorageLocationArticle = createAction(
  '[StorageLocationArticle/API] Update StorageLocationArticle',
  props<{ storageLocationArticle: StorageLocationArticle }>()
);

export const saveStorageLocationArticleSuccess = createAction(
  '[StorageLocationArticle/API] Save StorageLocationArticle Success',
  props<{ storageLocationArticle: StorageLocationArticle, insert: boolean }>()
);

export const saveStorageLocationArticleFailure = createAction(
  '[StorageLocationArticle/API] Save StorageLocationArticle Failure',
  props<{ error: any }>()
);

export const deleteStorageLocationArticle = createAction(
  '[StorageLocationArticle/API] Delete StorageLocationArticle',
  props<{ storageLocationArticle: StorageLocationArticle }>()
);

export const deleteStorageLocationArticleSuccess = createAction(
  '[StorageLocationArticle/API] Delete StorageLocationArticle Success',
  props<{ id: number | string }>()
);

export const deleteStorageLocationArticleFailure = createAction(
  '[StorageLocationArticle/API] Delete StorageLocationArticle Failure',
  props<{ error: any }>()
);
