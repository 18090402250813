import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {User, UserEnum} from "@knust/api-interfaces";
import {FormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Store} from "@ngrx/store";
import {createUser, updateUser} from "../+state/user.actions";
import {AuthService} from "../../utility/services/auth.service";
import CustomValidators from "../../utility/validators/custom-validators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'knust-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss'],
})
export class EditUserFormComponent implements OnInit {
  @Input() user!: User | null | undefined;
  @Input() showCancel: boolean = false;
  @Output() saveClick = new EventEmitter<any>();
  @Output() cancelClick = new EventEmitter<any>();

  UserEnum = UserEnum;
  userTypes = Object.values(UserEnum).map(type => {
    switch (type) {
      case 'admin': {
        return { label: "Administrator", type };
      }

      case 'purchasing': {
        return { label: "Einkäufer", type };
      }

      case 'buyer': {
        return { label: "Käufer", type };
      }

      /*case 'service': {
        return { label: "Service", type };
      }*/

      default: {
        return { label: type, type };
      }
    }
  });
  hidePassword = false;
  showPasswordHint = false;

  userForm: UntypedFormGroup = this.fb.group({
    id: [null],
    username: ['', Validators.required],
    newPassword: [null, Validators.minLength(6)],
    confirmNewPassword: [null],
    person: this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mail: ['', [Validators.required, Validators.email]],
      phone: [''],
      cell: [''],
      fax: [''],
    })
  }, {
    validators: [CustomValidators.match('newPassword', 'confirmNewPassword')]
  });

  get confirmNewPassword() {
    return this.userForm.controls['confirmNewPassword'];
  }

  constructor(private fb: FormBuilder, private store: Store, public authService: AuthService) {}

  ngOnInit() {
    console.log(this.user);

    this.authService.currentUser.subscribe(user => {
      if (user.type === UserEnum.ADMIN) {
        this.userForm.addControl('type', this.fb.control(UserEnum.BUYER, Validators.required));

        if (this.user?.id === user.id) {
          this.userForm.get('type')?.disable();
        }
      }
    });

    if (this.user) {
      this.userForm.patchValue(this.user);

      this.setPasswordValidationRequired();
    } else {
      this.userForm.get('newPassword')?.disable();
      this.userForm.get('confirmNewPassword')?.disable();
      this.showPasswordHint = true;
    }
  }

  switchPasswordVisibility(event: Event) {
    this.hidePassword = !this.hidePassword;

    event.stopPropagation();
  }

  setPasswordValidationRequired() {
    this.userForm.get('newPassword')?.valueChanges
      .pipe(
        untilDestroyed(this)
      )
      .subscribe(value => {
        console.log(value)
        if (!!value && value.length > 0) {
          this.confirmNewPassword.setValidators(Validators.required);
          this.confirmNewPassword.updateValueAndValidity();
          this.confirmNewPassword.markAsTouched();
        } else {
          this.confirmNewPassword.removeValidators(Validators.required);
          this.confirmNewPassword.updateValueAndValidity();
          this.confirmNewPassword.markAsPristine();
        }
      });
  }

  saveUser() {
    if (this.userForm.valid) {
      const user: User = {
        ...this.user,
        ...this.userForm.value
      }

      delete user.password;
      delete user.salt;

      if (user.id) {
        this.store.dispatch(updateUser({ user }))
      } else {
        this.store.dispatch(createUser({ user }))
      }

      this.saveClick.next(true);
    }
  }

  cancel() {
    this.cancelClick.next(true);
  }
}
