import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {ProfileRoutingModule} from "./profile-routing.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ProfileRoutingModule
  ]
})
export class ProfileModule { }
