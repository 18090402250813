import {Article, StorageLocation, StorageLocationArticle, VendorArticle} from "@knust/api-interfaces";
import {CoreProperties} from "./core-properties.dto";

export interface BookingArticle extends CoreProperties {
  mainArticle: Article;
  storageArticle: StorageLocationArticle;
  amount: number;
  note?: string;
  receivingStorageArticle?: StorageLocationArticle;
}
