<div class="details-header">
  <div class="header-row">
    <h1>Lieferant</h1>

    <div class="button-row">
      <button mat-flat-button color="warn" *ngIf="editMode && !newEntity" (click)="deleteVendor()">
        Lieferanten löschen
        <mat-icon>delete_outline</mat-icon>
      </button>

      <button mat-flat-button [color]="editMode ? undefined : 'primary'" (click)="switchEditMode(true)" *ngIf="!newEntity">
        <ng-container *ngIf="!editMode; else editButtonCancel">
          Lieferanten bearbeiten
          <mat-icon>edit</mat-icon>
        </ng-container>

        <ng-template #editButtonCancel>
          Bearbeiten abbrechen
          <mat-icon>close</mat-icon>
        </ng-template>
      </button>
    </div>
  </div>

  <a (click)="location.back()" class="back-link">
    <mat-icon>arrow_back</mat-icon>
    Zurück
  </a>
</div>

<form [formGroup]="vendorForm" (ngSubmit)="saveVendor()">
  <div class="form-box">
    <section>
      <h3>Allgemein</h3>

      <div class="input-columns-container">
        <div class="input-column">
          <ng-container formGroupName="organization">
            <mat-form-field appearance="fill">
              <mat-label>Name</mat-label>
              <input matInput placeholder="Heinz Knust GmbH" formControlName="name">
            </mat-form-field>
          </ng-container>

          <mat-form-field appearance="fill">
            <mat-label>Lieferantennummer</mat-label>
            <input matInput placeholder="LF-HKG-001" formControlName="vendorNumber">
          </mat-form-field>

          <ng-container formGroupName="organization">
            <mat-form-field appearance="fill">
              <mat-label>Telefon</mat-label>
              <input matInput placeholder="+49 0201 777444" formControlName="phone">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>E-Mail</mat-label>
              <input matInput type="email" placeholder="info@knust.de" formControlName="mail">
            </mat-form-field>
          </ng-container>
        </div>

        <div class="input-column">
          <ng-container formGroupName="organization">
            <mat-form-field appearance="fill">
              <mat-label>Fax</mat-label>
              <input matInput placeholder="+49 0201 777666" formControlName="fax">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Internetseite</mat-label>
              <input matInput placeholder="https://internetseite.de" formControlName="web">
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Notiz</mat-label>
              <textarea matInput placeholder="Diese Firma ist..." formControlName="note"></textarea>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </section>

    <section formGroupName="organization">
      <h3>Firmenadresse</h3>

      <div class="input-columns-container" formGroupName="address">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Straße</mat-label>
            <input matInput formControlName="street">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>PLZ</mat-label>
            <input matInput formControlName="zip">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Stadt</mat-label>
            <input matInput formControlName="city">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Land</mat-label>
            <mat-select formControlName="country">
              <mat-option [value]="'Deutschland'">
                Deutschland
              </mat-option>
              <mat-option [value]="'Österreich'">
                Österreich
              </mat-option>
              <mat-option [value]="'Schweiz'">
                Schweiz
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Zusatz</mat-label>
            <input matInput formControlName="addressSupplement">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Längengrad</mat-label>
            <input matInput formControlName="latitude">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Breitengrad</mat-label>
            <input matInput formControlName="longitude">
          </mat-form-field>
        </div>
      </div>
    </section>

    <section formGroupName="organization">
      <div class="section-heading">
        <mat-slide-toggle color="primary" [disabled]="!newEntity && !editMode"
                          [checked]="enableContactPerson | async" (toggleChange)="toggleContactPerson()"></mat-slide-toggle>
        <h3>Ansprechpartner</h3>
      </div>

      <div class="input-columns-container" formGroupName="contactPerson" *ngIf="enableContactPerson | async">
        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Vorname</mat-label>
            <input matInput placeholder="Max" formControlName="firstName">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Nachname</mat-label>
            <input matInput placeholder="Mustermann" formControlName="lastName">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Position</mat-label>
            <input matInput placeholder="Geschäftsführer" formControlName="position">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>E-Mail</mat-label>
            <input matInput placeholder="max@mustermann.de" formControlName="mail">
          </mat-form-field>
        </div>

        <div class="input-column">
          <mat-form-field appearance="fill">
            <mat-label>Telefon</mat-label>
            <input matInput placeholder="+49 0201 777444" formControlName="phone">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Fax</mat-label>
            <input matInput placeholder="+49 0201 777666" formControlName="fax">
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>Internetseite</mat-label>
            <input matInput placeholder="https://internetseite.de" formControlName="web">
          </mat-form-field>
        </div>
      </div>
    </section>

    <div class="form-action-row">
      <button mat-flat-button color="primary" type="submit" class="save-btn"
              *ngIf="editMode || newEntity"
              [disabled]="!vendorForm.valid">
        Speichern
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</form>
