import {Component, OnDestroy, OnInit} from "@angular/core";
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {
  Article,
  Booking,
  BookingArticle,
  BookingTypeEnum,
  StorageLocation,
  StorageLocationArticle,
  VendorArticle
} from "@knust/api-interfaces";
import {ColumnConfig} from "../../reusable-components/data-table/column-config";
import {BehaviorSubject, combineLatest, debounceTime, filter, map, merge, ReplaySubject, take, tap} from "rxjs";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {Location} from "@angular/common";
import {Store} from "@ngrx/store";
import {
  addBookingArticle,
  closeBooking, createBooking,
  deleteBookingArticle, loadBookingArticles, saveBookingNote,
  setBookingArticlesPage, setBookingArticlesSort,
  setBookingArticlesTerm, updateBooking
} from "../+state/bookings.actions";
import {
  getAllBookingArticles,
  getBookingArticlesPage, getBookingArticlesSort,
  getBookingArticlesTerm,
  getBookingArticlesTotal, getSelected
} from "../+state/bookings.selectors";
import {articleColumnConfig, articleCommandMap, articleDisplayedColumns} from "../../articles/articles-table";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ArticleService} from "../../utility/services/article.service";
import {StorageLocationService} from "../../utility/services/storage-location.service";
import {VendorArticleService} from "../../utility/services/vendor-article.service";
import {BookingService} from "../../utility/services/booking.service";
import {StoreRootState} from "../../+state/root.reducer";
import {getRouteParams} from "../../+state/root.selectors";
import {
  resetStorageLocationArticles
} from "../../reusable-components/storage-locations-articles/+state/storage-location-article.actions";

@UntilDestroy()
@Component({
  selector: 'knust-bookings-detail',
  templateUrl: './bookings-detail.component.html',
  styleUrls: ['./bookings-detail.component.scss'],
})
export class BookingsDetailComponent implements OnInit, OnDestroy {
  protected readonly BookingTypeEnum = BookingTypeEnum;
  bookingTypes = Object.values(BookingTypeEnum).map(type => {
    switch (type) {
      case 'Zubuchung': {
        return { label: "Zubuchung", type };
      }

      case 'Abbuchung': {
        return { label: "Abbuchung", type };
      }

      case 'Umbuchung': {
        return { label: "Umbuchung", type };
      }

      default: {
        return { label: type, type };
      }
    }
  });

  bookingForm = this.fb.group({
    id: this.fb.control<null | number>(null),
    bookingType: this.fb.control<BookingTypeEnum | null>(null, Validators.required),
    storageLocation: this.fb.group({
      id: this.fb.control<number | null>(null, Validators.required),
    }),
    note: ['']
  });
  selectedStorageLocation = new BehaviorSubject<number | null>(null);
  selectedBookingType = new BehaviorSubject<BookingTypeEnum | null>(null);
  selectedArticles = new BehaviorSubject<BookingArticle[] | null>(null);

  commandMap: AbstractIndexCommands<BookingArticle> = {
    getAll: getAllBookingArticles,
    getTotal: getBookingArticlesTotal,
    getPage: getBookingArticlesPage,
    setPage: setBookingArticlesPage,
    getTerm: getBookingArticlesTerm,
    setTerm: setBookingArticlesTerm,
    getSort: getBookingArticlesSort,
    setSort: setBookingArticlesSort,
    load: loadBookingArticles,
  }
  displayedColumns = [
    'mainArticle.photo',
    'mainArticle.title',
    'mainArticle.internalId',
    'vendorArticle.vendor.organization.name',
    'vendorArticle.vendorArticleNumber',
    'storageLocation.label',
    'count',
    'note',
  ];
  columnConfig: ColumnConfig[] = [
    {
      name: 'Artikelbild',
      refName: 'mainArticle',
      nestedName: 'photo',
      useNestedName: true,
      type: 'image',
      disableSort: true,
    },
    {
      name: 'Artikelname',
      refName: 'mainArticle',
      nestedName: 'title',
      useNestedName: true
    },
    {
      name: 'Artikelnummer',
      refName: 'mainArticle',
      nestedName: 'internalId',
      useNestedName: true
    },
    {
      name: 'Lieferant',
      refName: 'vendorArticle',
      nestedName: ['vendor', 'organization', 'name'],
      useNestedName: true
    },
    {
      name: 'Lieferanten-Artikelnr.',
      refName: 'vendorArticle',
      nestedName: ['vendorArticleNumber'],
      useNestedName: true
    },
    {
      name: 'Lagerort',
      refName: 'storageLocation',
      nestedName: ['label'],
      useNestedName: true
    },
    {
      name: 'Anzahl',
      refName: 'count'
    },
    {
      name: 'Notiz',
      refName: 'note'
    }
  ];

  allStorageLocations: BehaviorSubject<StorageLocation[]> = new BehaviorSubject<StorageLocation[]>([]);
  storageLocationFilterCtrl: FormControl = new FormControl();
  storageLocationSearchTerm = '';
  public filteredStorageLocations: ReplaySubject<StorageLocation[]> = new ReplaySubject<StorageLocation[]>(1);

  newEntity = false;
  booking = merge(
      this.store.select(getRouteParams).pipe(
          filter(params => params['bookingId'] && params['bookingId'] === NEW_ENTITY),
          tap(() => {
            console.log('New Entity');
            this.newEntity = true;
          }),
          map(() => ({
            createdByUser: 0,
            updatedByUser: 0,
            createdDate: new Date(),
            updatedDate: new Date(),
            completed: false,
            label: null,
            articles: [],
            storageLocation: undefined,
          }) as Partial<Booking>)
      ),
      this.store.select(getSelected).pipe(
          filter(selected => !!selected),
          tap((selected) => {
            if (selected) {
              this.bookingForm.patchValue(selected);

              if (selected.storageLocation && selected.storageLocation.id && selected.bookingType) {
                this.selectedStorageLocation.next(selected.storageLocation.id);
                this.selectedBookingType.next(selected.bookingType);
                this.selectedArticles.next(selected.articles);

                this.bookingForm.controls.bookingType.disable();
                this.bookingForm.controls.storageLocation.disable();
              }
            }
            console.log('Selected Entity')
            this.newEntity = false;
          })
      )
  );

  constructor(private fb: FormBuilder,
              public location: Location,
              private storageLocationService: StorageLocationService,
              private store: Store<StoreRootState>) {
  }

  ngOnInit() {
    // Um Lagerorte initial zu befüllen
    this.filterStorageLocations(true);

    this.storageLocationFilterCtrl.valueChanges
        .pipe(
            debounceTime(500),
            filter(value => value !== this.storageLocationSearchTerm)
        )
        .subscribe(() => {
          this.filterStorageLocations();
        });
  }

  ngOnDestroy() {
    this.bookingForm.reset();
  }

  filterStorageLocations(initialLoad?: boolean) {
    this.storageLocationSearchTerm = this.storageLocationFilterCtrl.value ?? '';

    this.storageLocationService.loadLocations('?page=1&limit=100&search=' + this.storageLocationSearchTerm.toLowerCase()).subscribe(
        res => {
          this.filteredStorageLocations.next(res.items);

          if (initialLoad) {
            this.allStorageLocations.next(res.items);
          }
        }
    );
  }

  saveBooking() {
    if (this.bookingForm.valid) {
      if (!this.bookingForm.controls.id.value) {
        const booking: Booking | Omit<Booking, 'id'> = {
          ...this.bookingForm.value,
          completed: false,
          articles: [],
        };

        this.store.dispatch(createBooking({bookings: booking}));
      } else {
        this.store.dispatch(saveBookingNote({ note: this.bookingForm.controls.note.value }));
        this.bookingForm.controls.note.markAsPristine();
      }
    }
  }

  closeBooking(submitBookings: { storageLocationArticleId: number; amount: number, note?: string, receivingStorageLocation?: number }[]) {
    this.booking
        .pipe(take(1))
        .subscribe(booking => {
          if (!this.newEntity && booking) {
            this.store.dispatch(closeBooking({ bookingId: (booking as Booking).id, articles: submitBookings }));
            this.store.dispatch(resetStorageLocationArticles());
          }
        });
  }
}
