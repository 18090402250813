<div class="notification" *ngFor="let notification of notificationService.notifications | async">
  <div class="notification-content">
    <div class="notification-progress" [class.error]="notification.status === 'error'" [class.warning]="notification.status === 'warning'"></div>

    <h3 class="notification-heading"
        [class.error]="notification.status === 'error'" [class.warning]="notification.status === 'warning'">{{notification.title}}</h3>
    <p class="notification-content">
      {{notification.text}}
    </p>
  </div>
</div>
