import {createFeatureSelector, createSelector} from "@ngrx/store";

import {State, STORAGE_LOCATION_FEATURE_KEY, storageLocationAdapter} from "./storage-location.reducer";
import {StateToQuery} from "../../../utility/abstract-index/state-to-query.func";

// Lookup the 'StorageLocation' feature state managed by NgRx
export const getStorageLocationState = createFeatureSelector<State>(STORAGE_LOCATION_FEATURE_KEY);

const { selectAll, selectEntities } = storageLocationAdapter.getSelectors();

export const getStorageLocationLoaded = createSelector(
  getStorageLocationState,
  (state: State) => state.loaded
);

export const getStorageLocationError = createSelector(
  getStorageLocationState,
  (state: State) => state.error
);

export const getStorageLocationTotal = createSelector(
  getStorageLocationState,
  (state: State) => state.total
);

export const getStorageLocationPage = createSelector(
  getStorageLocationState,
  (state: State) => state.page
);

export const getStorageLocationTerm = createSelector(
  getStorageLocationState,
  (state: State) => state.term
);

export const getStorageLocationSort = createSelector(
  getStorageLocationState,
  (state: State) => state.sort
);

export const getStorageLocationQuery = createSelector(
  getStorageLocationState,
  (state: State) => StateToQuery(state)
);

export const getAllStorageLocation = createSelector(
  getStorageLocationState,
  (state: State) => selectAll(state)
);

export const getStorageLocationEntities = createSelector(
  getStorageLocationState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getStorageLocationState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getStorageLocationEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
