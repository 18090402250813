import {environment} from "environments/environment";

export const API_BASE_URL = environment.apiBaseURL;

export const BASE_DATA_URL = API_BASE_URL + '/base-data';
export const LOGO_URL = BASE_DATA_URL + '/logo';

export const CATEGORY_URL = API_BASE_URL + '/category';
export const STORAGE_LOCATIONS_URL = API_BASE_URL + '/storage-location';
export const STORAGE_LOCATION_ARTICLES_URL = API_BASE_URL + '/storage-location-article';
export const VENDORS_URL = API_BASE_URL + '/vendor';
export const ARTICLES_URL = API_BASE_URL + '/article';
export const IMPORT_ARTICLES_URL = API_BASE_URL + '/import';
export const USER_URL = API_BASE_URL + '/user';
export const VENDOR_ARTICLES_URL = API_BASE_URL + '/vendor-article';
export const DELIVERY_URL = API_BASE_URL + '/delivery';
export const TRUNK_URL = API_BASE_URL + '/trunk';
export const TRUNK_CART_URL = TRUNK_URL + '/cart';
export const REPORTS_URL = API_BASE_URL + '/reports';
export const BOOKING_URL = API_BASE_URL + '/bookings';
export const EMAIL_URL = API_BASE_URL + '/mail/email-template';
