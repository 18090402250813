import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {BaseDataRoutingModule} from "./base-data-routing.module";
import {BaseDataComponent} from "./base-data/base-data.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatSelectModule} from "@angular/material/select";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {StorageLocationComponent} from "./storage-location/storage-location.component";
import {
  StorageLocationDialogComponent
} from "./storage-location/storage-location-dialog/storage-location-dialog.component";
import {MatDialogModule} from "@angular/material/dialog";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import * as fromStorageLocation from "./storage-location/+state/storage-location.reducer";
import {StorageLocationEffects} from "./storage-location/+state/storage-location.effects";
import {CategoriesModule} from "../reusable-components/categories/categories.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import { EmailTemplatesComponent } from './email-templates/email-templates.component';
import * as fromEmail from './email-templates/+state/email.reducer';
import { EmailEffects } from './email-templates/+state/email.effects';
import {
  EmailTemplateDialogComponent,
} from './email-templates/edit-email-templates-dialog/edit-email-templates-dialog.component';

@NgModule({
  declarations: [
    BaseDataComponent,
    StorageLocationComponent,
    StorageLocationDialogComponent,
    EmailTemplatesComponent,
    EmailTemplateDialogComponent
  ],
    imports: [
        CommonModule,
        BaseDataRoutingModule,
        ReactiveFormsModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatDialogModule,
        DataTableModule,
        StoreModule.forFeature(fromStorageLocation.STORAGE_LOCATION_FEATURE_KEY, fromStorageLocation.reducer),
        EffectsModule.forFeature([StorageLocationEffects]),
        StoreModule.forFeature(fromEmail.EMAIL_FEATURE_KEY, fromEmail.reducer),
        EffectsModule.forFeature([EmailEffects]),
        CategoriesModule,
        MatCheckboxModule,
    ],
})
export class BaseDataModule {}
