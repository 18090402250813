import {createAction, props} from "@ngrx/store";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {Category} from "@knust/api-interfaces";

export const loadCategories = createAction('[Category] Load Category');

export const loadCategoriesSuccess = createAction(
  '[Category/API] Load Category Success',
  props<{ category: Category[], total: number }>()
);

export const loadCategoriesFailure = createAction(
  '[Category/API] Load Category Failure',
  props<{ error: any }>()
);

export const setSelectedCategory = createAction(
  '[Category/API] Set selected Category',
  props<{ id: number }>()
);

export const loadCategoryDetailUnneeded = createAction(
  '[Category/API] Load Category Detail Unneeded'
);

export const loadCategoryDetailSuccess = createAction(
  '[Category/API] Load Category Detail Success',
  props<{ category: Category }>()
);

export const loadCategoryDetailFailure = createAction(
  '[Category/API] Load Category Detail Failure',
  props<{ error: any }>()
);

export const setCategoryPage = createAction(
  '[Category/API] Set Page',
  props<{ page: PageEvent }>()
);

export const setCategoryTerm = createAction(
  '[Category/API] Set Term',
  props<{ term: string }>()
);

export const setCategorySort = createAction(
  '[Category/API] Set Sort',
  props<{ sort: Sort | null }>()
);

export const clearCategory = createAction(
  '[Category/API] Clear'
);

export const createCategory = createAction(
  '[Category/API] Create Category',
  props<{ category: Omit<Category, 'id'> }>()
);

export const updateCategory = createAction(
  '[Category/API] Update Category',
  props<{ category: Category }>()
);

export const saveCategorySuccess = createAction(
  '[Category/API] Save Category Success',
  props<{ category: Category, insert: boolean }>()
);

export const saveCategoryFailure = createAction(
  '[Category/API] Save Category Failure',
  props<{ error: any }>()
);

export const deleteCategory = createAction(
  '[Category/API] Delete Category',
  props<{ category: Category }>()
);

export const deleteCategorySuccess = createAction(
  '[Category/API] Delete Category Success',
  props<{ id: number | string }>()
);

export const deleteCategoryFailure = createAction(
  '[Category/API] Delete Category Failure',
  props<{ error: any }>()
);

export const restoreCategory = createAction(
  '[Category/API] Restore Category',
  props<{ element: Category }>()
);

export const restoreCategorySuccess = createAction(
  '[Category/API] Restore Category Success',
  props<{ category: Category }>()
);

export const restoreCategoryFailure = createAction(
  '[Category/API] Restore Category Failure',
  props<{ error: any }>()
);
