import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {ArticleService} from "../../utility/services/article.service";
import {Article, Category} from "@knust/api-interfaces";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {BehaviorSubject, debounceTime, filter, map, merge, Observable, ReplaySubject, startWith, tap} from "rxjs";
import {getRouteParams} from "../../+state/root.selectors";
import {NEW_ENTITY} from "../../utility/constants/new-entity.constants";
import {Store} from "@ngrx/store";
import {StoreRootState} from "../../+state/root.reducer";
import {createArticle, deleteArticle, updateArticle} from "../+state/article.actions";
import {getSelected} from "../+state/article.selectors";
import {DEFAULT_IMAGE} from "../../utility/constants/app.constants";
import {CategoryService} from "../../utility/services/category.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmComponent} from "../../reusable-components/confirm/confirm.component";
import {Location} from "@angular/common";
import {deleteImportArticle} from "../../import/+state/import-article.actions";
import {API_BASE_URL} from "../../utility/constants/api.constants";
import {AuthService} from "../../utility/services/auth.service";

@UntilDestroy()
@Component({
  selector: 'knust-articles-details',
  templateUrl: './articles-details.component.html',
  styleUrls: ['./articles-details.component.scss'],
})
export class ArticlesDetailsComponent implements OnInit {
  selectedFile = undefined;

  articleForm: UntypedFormGroup = this.fb.group({
    id: [null],
    internalId: [null, Validators.required],
    title: [null, Validators.required],
    description: [null, Validators.required],
    active: [true, Validators.required],
    soldOut: [false, Validators.required],
    unitOfMeasure: [null, Validators.required],
    samplingStep: [null, Validators.required],
    autoOrder: [true, Validators.required],
    photo: [null],
    productCategory: this.fb.group({
      id: [null]
    }),
    weight: [null],
    size_height: [null],
    size_width: [null],
    size_length: [null],
  });

  editMode = false;
  defaultImageURL = DEFAULT_IMAGE;
  newEntity = false;
  importEntity: number | undefined = undefined;

  categoryFilterCtrl: FormControl = new FormControl();
  categorySearchTerm = '';
  public filteredCategories: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  unitsOfMeasure: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  filteredUnits!: Observable<string[]> | undefined;

  article = merge(
    this.store.select(getRouteParams).pipe(
      filter(params => params['articleId'] && params['articleId'] === NEW_ENTITY),
      tap(() => {
        this.newEntity = true;
        this.editMode = true;
      }),
      map(() => ({
        internalId: '',
        title: '',
        description: '',
        active: true,
        soldOut: false,
        unitOfMeasure: '',
        samplingStep: 1,
        preAssignWithdrawal: 0,
        autoOrder: true,
        notificationStatus: 0,
      }) as Omit<Article, 'id'>)
    ),
    this.store.select(getSelected).pipe(
      filter(selected => !!selected),
      tap(() => {
        console.log('NewEntity false');
        this.newEntity = false;
        this.editMode = false;
      })
    )
  );
  initialArticle!: Article | Omit<Article, 'id'>;

  constructor(private fb: FormBuilder,
              private dialog: MatDialog,
              public location: Location,
              public authService: AuthService,
              private articleService: ArticleService,
              public categoryService: CategoryService,
              private store: Store<StoreRootState>) {
  }

  ngOnInit() {
    this.filterCategories();
    this.getMeasurements();

    this.article.pipe(
      untilDestroyed(this)
    )
      .subscribe(article => {
        if (article) {
          this.initialArticle = article;
          this.articleForm.patchValue(article, { emitEvent: false });
        }
      });

    const state: { article: Article } | undefined = this.location.getState() as { article: Article };

    console.log(state);

    if (state?.article) {
      console.log('Patching Value!', state.article);
      this.importEntity = state.article.id;

      this.articleForm.patchValue({...state.article, id: null}, { emitEvent: false });
    }

    this.categoryFilterCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(500),
        filter(value => value !== this.categorySearchTerm)
      )
      .subscribe(() => {
        this.filterCategories();
      });

    this.filteredUnits = this.articleForm.get('unitOfMeasure')?.valueChanges.pipe(
      startWith(''),
      map(value => {
        return value ? this._filterMeasurements(value) : this.unitsOfMeasure.value.slice();
      }),
    );
  }

  getMeasurements() {
    if (this.authService.isAdmin()) {
      this.articleService.getUnitsOfMeasure().subscribe(units => this.unitsOfMeasure.next(units));
    }
  }

  private _filterMeasurements(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.unitsOfMeasure.value.filter(option => option.toLowerCase().includes(filterValue));
  }

  filterCategories() {
    this.categorySearchTerm = this.categoryFilterCtrl.value ?? '';

    this.categoryService.loadCategories('?page=1&limit=100&search=' + this.categorySearchTerm.toLowerCase()).subscribe(
      res => this.filteredCategories.next(res.items)
    );
  }

  selectFile(event: any): void {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (this.selectedFile) {
      const fd = new FormData();
      fd.append('file', this.selectedFile);

      this.articleService.uploadArticleImage(fd).subscribe(response => {
        this.articleForm.get('photo')?.patchValue(API_BASE_URL + '/' + response.path);
      })
    }
  }

  switchEditMode() {
    this.editMode = !this.editMode;

    this.articleForm.reset();
    this.articleForm.patchValue(this.initialArticle, { emitEvent: false });
  }

  saveArticle() {
    if (this.newEntity) {
      this.store.dispatch(
        createArticle({ article: this.articleForm.value })
      );
    } else {
      this.store.dispatch(
        updateArticle({ article: this.articleForm.value })
      );
    }

    if (this.importEntity) {
      this.store.dispatch(deleteImportArticle({ id: this.importEntity, noMessage: true } ))
    }
  }

  deleteArticle() {
    this.dialog.open(ConfirmComponent, {
      data: {
        title: 'Artikel wirklich löschen?',
        text: 'Möchten Sie diesen Artikel wirklich löschen?',
        buttonColor: 'warn'
      }
    })
      .afterClosed()
      .subscribe(confirm => {
        if (confirm) {
          this.store.dispatch(deleteArticle());
        }
      })
  }
}
