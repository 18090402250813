<div class="form-box">
  <knust-data-table (entityRowClick)="openStorageLocationDialog($event)"
                    (addEntityClick)="openStorageLocationDialog()"
                    (deleteEntityClick)="deleteStorageLocation($event)"
                    newButtonText="Neuen Lagerort erstellen"
                    [commandMap]="commandMap"
                    [columnConfig]="columnConfig"
                    [displayedColumns]="displayedColumns">
  </knust-data-table>
</div>
