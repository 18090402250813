import {createAction, props} from "@ngrx/store";
import {EmailTemplate} from "@knust/api-interfaces";

export const loadEmails = createAction('[Email] Load Email');

export const loadEmailsSuccess = createAction(
  '[Email/API] Load Email Success',
  props<{ email: EmailTemplate[] }>()
);

export const loadEmailsFailure = createAction(
  '[Email/API] Load Email Failure',
  props<{ error: any }>()
);

export const setSelectedEmail = createAction(
  '[Email/API] Set selected Email',
  props<{ id: number }>()
);

export const updateEmail = createAction(
  '[Email/API] Update Email',
  props<{ email: EmailTemplate }>()
);


export const updateEmailSuccess = createAction(
  "[Email/API] Update Email Success",
  props<{ email: EmailTemplate }>()
);

export const updateEmailFailure = createAction(
  "[Email/API] Update Email Failure",
  props<{ error: string }>()
);
