import {ColumnConfig} from "../reusable-components/data-table/column-config";
import {AbstractIndexCommands} from "../utility/abstract-index/abstract-index-commands";
import {Booking} from "@knust/api-interfaces";
import {
  getAllBookings,
  getBookingPage,
  getBookingSort,
  getBookingTerm,
  getBookingTotal
} from "./+state/bookings.selectors";
import {
  createBooking,
  deleteBooking,
  loadBooking,
  setBookingPage,
  setBookingSort,
  setBookingTerm
} from "./+state/bookings.actions";

export const bookingsDisplayedColumns = ['id', 'createdDate', 'createdByUser', 'updatedDate', 'updatedByUser', 'completed', 'note'];
export const bookingsColumnConfig: ColumnConfig[] = [
  {
    name: 'ID',
    refName: 'id',
  },
  {
    name: 'Erstellt am',
    refName: 'createdDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Erstellt von',
    refName: 'createdByUser',
    type: 'user'
  },
  {
    name: 'Zuletzt aktualisiert',
    refName: 'updatedDate',
    type: 'date',
    dateFormat: 'dd.MM.yy - HH:mm',
  },
  {
    name: 'Aktualisiert von',
    refName: 'updatedByUser',
    type: 'user'
  },
  {
    name: 'Notiz',
    refName: 'note',
    fallback: 'Nicht gesetzt'
  },
  {
    name: 'Status',
    refName: 'completed',
    type: 'boolean',
    trueText: 'Abgeschlossen',
    falseText: 'Aktiv',
    disableSort: true
  }
];

export const bookingsCommandMap: AbstractIndexCommands<Booking> = {
  getAll: getAllBookings,
  getTotal: getBookingTotal,
  getPage: getBookingPage,
  setPage: setBookingPage,
  getTerm: getBookingTerm,
  setTerm: setBookingTerm,
  getSort: getBookingSort,
  setSort: setBookingSort,
  load: loadBooking,
  createElement: createBooking,
  deleteElement: deleteBooking,
};
