import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";

export function StateToQuery(state: {
  page: PageEvent;
  term: string;
  sort: Sort | null;
}): string {
  return '?page=' + (state.page.pageIndex + 1) +
    '&limit=' + state.page.pageSize +
    (state.sort ? '&sortBy=' + state.sort.active + ':' + state.sort.direction : '') +
    (state.term ? '&search=' + state.term : '');
}
