import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {ActionsSubject, Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {AbstractIndexComponent} from "../../utility/abstract-index/abstract-index.component";
import {StorageLocationPartialState} from "../../base-data/storage-location/+state/storage-location.reducer";
import {BookingArticle, BookingTypeEnum, StorageLocation, StorageLocationArticle} from "@knust/api-interfaces";
import {BehaviorSubject, combineLatestWith, debounceTime, filter, map, Observable, ReplaySubject, take} from "rxjs";
import {AbstractIndexCommands} from "../../utility/abstract-index/abstract-index-commands";
import {SelectionModel} from "@angular/cdk/collections";
import {addItemsToCart, addItemsToCartSuccess, changeItemsInTrunk, closeTrunk} from "../../trunks/+state/trunk.actions";
import {ColumnConfig} from "./column-config";
import {FormBuilder, FormControl, UntypedFormGroup} from "@angular/forms";
import {ofType} from "@ngrx/effects";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {AuthService} from "../../utility/services/auth.service";
import {
  calculateNextIntervalStep,
  changeToNextIntervalStep
} from "../../utility/functions/change-to-next-interval-step.function";
import {NotificationService} from "../../utility/services/notification.service";
import {StorageLocationService} from "../../utility/services/storage-location.service";

@UntilDestroy()
@Component({
  selector: 'knust-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent extends AbstractIndexComponent<StorageLocation, StorageLocationPartialState> implements OnInit {
  buyStockInputs: number[] = [];
  takeStockInputs: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
  takeStockNotes: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  storageLocationFilterCtrl: FormControl = new FormControl();
  storageLocationSearchTerm = '';
  public filteredStorageLocations: ReplaySubject<StorageLocation[]> = new ReplaySubject<StorageLocation[]>(1);

  buyStockForm: UntypedFormGroup = this.fb.group({});

  bookingsForm: UntypedFormGroup = this.fb.group({});
  protected readonly BookingTypeEnum = BookingTypeEnum;

  changeToNextIntervalStep = changeToNextIntervalStep;

  @Input() disableFilterblock = false;
  @Input() disableNewButton = false;
  @Input() disableEditing = false;
  @Input() newButtonText = 'Neues Element hinzufügen';
  @Input() noDataText = 'Es wurden keine Daten gefunden.';
  @Input() enableExpandableRows = false;
  @Input() expandedColumns: string[] = [];
  @Input() allRowsExpanded = false;
  expandedElements: any[] = [];
  @Input() enableSelection = false;
  @Input() useClickAsSelection = false;
  @Input() propagateClicks = false;
  @Input () initialSelection: any[] = [];
  @Input () allowMultiSelect = true;
  @Input () hasMasterCheckbox = false;
  @Input () selectionHeaderText = 'Hinzugefügt';
  selection = new SelectionModel<any> ( this.allowMultiSelect, this.initialSelection );
  @Input() initialStorageLocations!: Observable<StorageLocation[] | null>;
  @Input() selectedStorageLocation!: BehaviorSubject<number | null>;
  @Input() selectedArticles!: BehaviorSubject<BookingArticle[] | null>;
  @Input() bookingType!: Observable<BookingTypeEnum | null>;
  @Input() completed: boolean = false;
  @Input() disablePagination: boolean = false;

  @Input() columnConfig: ColumnConfig[]                        = [];

  @Input() goToLastPage!: Observable<void>;

  @Output() entityRowClick             = new EventEmitter<any> ();
  @Output() addEntityClick             = new EventEmitter<any> ();
  @Output() addEmptyEntityClick        = new EventEmitter<any> ();
  @Output() deleteEntityClick          = new EventEmitter<any> ();
  @Output() restoreEntityClick         = new EventEmitter<any> ();
  @Output() toggleEntityClick          = new EventEmitter<any> ();
  @Output() entityActionClick          = new EventEmitter<any> ();
  @Output() duplicateEntityClick       = new EventEmitter<any> ();
  @Output() filterAddedEvent           = new EventEmitter<any> ();
  @Output() languageFilterChangedEvent = new EventEmitter<any> ();
  @Output() selectionUpdated          = new EventEmitter<any[]> ();
  @Output() inputChanged               = new EventEmitter<any> ();
  @Output() notesChanged               = new EventEmitter<any> ();
  @Output() closeTrunkClick            = new EventEmitter<any> ();
  @Output() submitBookingsClick        = new EventEmitter<any> ();

  @Input() commandMap!: AbstractIndexCommands<any>;

  @Input() displayedColumns: string[] = [];

  constructor(
    protected override store: Store<StorageLocationPartialState>,
    private fb: FormBuilder,
    private actionsSubject: ActionsSubject,
    public authService: AuthService,
    private notificationService: NotificationService,
    private storageLocationService: StorageLocationService
  ) {
    super(store);
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.displayedColumns.includes('buyStock')) {
      this.dataSource.connect().subscribe((articleStorageLocations) => {
        for (const articleStorageLocation of (articleStorageLocations as unknown as StorageLocationArticle[])) {
          if (articleStorageLocation.id) {
            this.buyStockForm.removeControl(articleStorageLocation.id + '');

            const formGroup = this.fb.group({
              articleStorageLocation,
              amount: null,
            });

            if (articleStorageLocation.actualStock === 0) {
              formGroup.get('amount')?.disable();
            } else {
              formGroup.get('amount')?.enable();
            }

            console.log(articleStorageLocation.storageLocation.label, articleStorageLocation.actualStock);

            this.buyStockForm.addControl(articleStorageLocation.id + '', formGroup);
          }
        }

        console.log(articleStorageLocations, this.buyStockForm.controls);
      });

      this.actionsSubject.pipe(
        untilDestroyed(this),
        ofType(addItemsToCartSuccess)
      ).subscribe(() => this.buyStockForm.reset());
    }

    if (this.displayedColumns.includes('bookings')) {
      this.initialStorageLocations
        .pipe(
          combineLatestWith(this.selectedStorageLocation),
          filter(([storageLocations, selectedLocationID]) => !!selectedLocationID && !!storageLocations && storageLocations.length > 0),
          take(1),
          )
        .subscribe(([initialStorageLocations, selectedLocationID]) => {
          if (initialStorageLocations) {
            this.filteredStorageLocations.next(
              initialStorageLocations.filter(storageLocation => storageLocation.id !== selectedLocationID)
            );
          }
        });

      this.storageLocationFilterCtrl.valueChanges
        .pipe(
          untilDestroyed(this),
          debounceTime(500),
          filter(value => value !== this.storageLocationSearchTerm)
        )
        .subscribe(() => {
          this.filterStorageLocations();
        });

      this.dataSource.connect()
        .pipe(
          combineLatestWith(this.selectedArticles),
          filter(([storageLocationArticles, bookingArticles]) => bookingArticles !== null)
        )
        .subscribe(([storageLocationArticles, bookingArticles]) => {
          const oldBookingForm = this.bookingsForm;
        this.bookingsForm = this.fb.group({});
        console.log('Booking changed', storageLocationArticles, this.bookingsForm);

        for (const storageLocationArticle of (storageLocationArticles as unknown as StorageLocationArticle[])) {
          if (storageLocationArticle.id) {
            this.bookingsForm.removeControl(storageLocationArticle.id + '');
            let formGroup;
            let foundArticleInBookingsIndex = -1;

            if (bookingArticles) {
              foundArticleInBookingsIndex = bookingArticles.findIndex(bookingArticle => bookingArticle.storageArticle.id === storageLocationArticle.id);
            }

            console.log('Booking-Articles', bookingArticles, 'Found-Index:', foundArticleInBookingsIndex, storageLocationArticle.id);

            if (bookingArticles && foundArticleInBookingsIndex > -1) {
              formGroup = this.fb.group({
                storageLocationArticle,
                amount: [bookingArticles[foundArticleInBookingsIndex].amount],
                receivingStorageLocation: bookingArticles[foundArticleInBookingsIndex].receivingStorageArticle?.storageLocation.id,
                note: bookingArticles[foundArticleInBookingsIndex].note,
              });
            } else {
              formGroup = this.fb.group({
                storageLocationArticle,
                amount: [null],
                receivingStorageLocation: null,
                note: null,
              });
            }

            this.bookingsForm.addControl(storageLocationArticle.id + '', formGroup);
          }
        }

        for (const oldControlName in oldBookingForm.controls) {
          const oldControlGroup = oldBookingForm.get(oldControlName);

          console.log('Adding old controls:', oldControlName, oldControlGroup);

          if (oldControlGroup && (oldControlGroup.get('amount')?.value !== null)) {
            if (this.bookingsForm.get(oldControlName)) {
              this.bookingsForm.get(oldControlName)?.patchValue(oldControlGroup.value);
            } else {
              this.bookingsForm.addControl(oldControlName, oldControlGroup);
            }
          }
        }

        console.log(this.bookingsForm.controls);

        if (this.completed) {
          this.bookingsForm.disable();
        }
      });
    }

    this.takeStockInputs.pipe(
      untilDestroyed(this)
    )
      .subscribe(takeStockInputs => this.inputChanged.next(takeStockInputs));

    this.takeStockNotes.pipe(
      untilDestroyed(this)
    )
      .subscribe(takeStockNotes => this.notesChanged.next(takeStockNotes));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows     = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected () ?
      this.selection.clear () :
      this.dataSource.data.forEach ( row => this.selection.select ( row ) );
  }

  new() {
    this.addEntityClick.emit();
  }

  delete(entity: any) {
    this.deleteEntityClick.emit(entity);
  }

  entityClicked(event: any) {
    this.entityRowClick.emit(event);
  }

  bookStock() {
    if (this.bookingsForm.valid) {
      const bookingInputs = this.bookingsForm.value;
      const submitBookings: { storageLocationArticleId: number; amount: number; note?: string; receivingStorageLocation?: number }[] = [];

      this.bookingType
        .pipe(
          filter(type => !!type),
          take(1)
        )
        .subscribe(bookingType => {
          Object.keys(bookingInputs).forEach(storageLocationArticleId => {
            if (bookingInputs[storageLocationArticleId].amount
              && bookingInputs[storageLocationArticleId].amount > 0
            ) {
              if (bookingType !== BookingTypeEnum.MOVE || bookingInputs[storageLocationArticleId].receivingStorageLocation) {
                submitBookings.push({
                  storageLocationArticleId: parseInt(storageLocationArticleId, 10),
                  amount: bookingInputs[storageLocationArticleId].amount,
                  receivingStorageLocation: bookingInputs[storageLocationArticleId].receivingStorageLocation,
                  note: bookingInputs[storageLocationArticleId].note,
                });
              } else {
                this.notificationService.createNotification({
                  title: 'Fehlende Angaben',
                  text: 'Für jeden Artikel muss die Anzahl ' +
                    bookingType === BookingTypeEnum.MOVE ? 'und der empfangende Lagerort ' : ''
                    + 'angegeben werden.',
                  status: 'error',
                  dismissed: 0,
                });
              }
            }
          });

          if (submitBookings.length > 0) {
            this.submitBookingsClick.emit(submitBookings);

            this.bookingsForm.reset();
          } else {
            this.notificationService.createNotification({
              title: 'Keine Artikel ausgewählt',
              text: 'Es muss mindestens eine Einheit von einem Artikel ausgewählt werden.',
              status: 'error',
              dismissed: 0,
            });
          }
        });
    }
  }

  changeStockInputToNextIntervalStep(samplingStep: number, rowId: number) {
    const stockInput = this.buyStockForm.get([rowId, 'amount']);

    if (stockInput) {
      const targetNumberValue = parseInt(stockInput.value, 10);

      if (targetNumberValue % samplingStep !== 0) {
        stockInput.patchValue(calculateNextIntervalStep(stockInput.value, samplingStep));
      }
    }
  }

  buyStocks() {
    if (this.buyStockForm.valid) {
      const buyStockInputs = this.buyStockForm.value;
      const submitBuyStocks: { storageLocationArticleId: number; amount: number; }[] = [];

      Object.keys(buyStockInputs).forEach(storageLocationArticleId => {
        if (buyStockInputs[storageLocationArticleId].amount && buyStockInputs[storageLocationArticleId].amount > 0) {
          submitBuyStocks.push({
            storageLocationArticleId: parseInt(storageLocationArticleId, 10),
            amount: buyStockInputs[storageLocationArticleId].amount
          });
        }
      });

      if (submitBuyStocks.length > 0) {
        this.store.dispatch(addItemsToCart({items: submitBuyStocks}))
      } else {
        this.notificationService.createNotification({
          title: 'Kein Artikel ausgewählt',
          text: 'Es muss mindestens eine Einheit von einem Artikel ausgewählt werden.',
          status: 'error',
          dismissed: 0,
        })
      }
    }
  }

  buyStock(index: number) {
    const storageLocationArticle = this.dataSource.connect().value[index] as any;

    const item = { storageLocationArticleId: storageLocationArticle.id, amount: this.buyStockInputs[index] };
    console.log(item);

    // this.store.dispatch(addItemToCart({ item }));
  }

  private getChangedItemsInTrunk() {
    let index = 0;
    const changedItems: { trunkArticleId: number, amount: number, note?: string }[] = [];

    for (const takeInput of this.takeStockInputs.value) {
      console.log(takeInput, index);
      if (takeInput) {
        const trunkArticle = this.dataSource.connect().value[index] as any;

        const item: { trunkArticleId: number, amount: number, note?: string } = {
          trunkArticleId: trunkArticle.id,
          amount: this.takeStockInputs.value[index]
        };

        if (this.takeStockNotes.value[index]) {
          item.note = this.takeStockNotes.value[index];
        }

        changedItems.push(item);
      }

      index++;
    }

    return changedItems;
  }

  takeFromTrunk() {
    const changedItems = this.getChangedItemsInTrunk();

    this.takeStockInputs.next([]);
    this.takeStockNotes.next([]);

    this.store.dispatch(changeItemsInTrunk({ changedItems }));
  }

  takeAllFromTrunk() {
    let i = 0;
    const trunkArticles = this.dataSource.connect().value as any;

    for (const trunkArticle of trunkArticles) {
      this.takeStockInputs.value[i] = trunkArticle.availableStock;

      i++;
    }
  }

  closeTrunk() {
    this.closeTrunkClick.emit();

    const withdrawnStockChanges = this.getChangedItemsInTrunk();

    this.store.dispatch(closeTrunk({ withdrawnStockChanges }));
  }

  toggleExpand(element: any) {
    const elementIndex = this.expandedElements.findIndex(expandedElement => expandedElement.id === element.id);

    if (elementIndex > -1) {
      this.expandedElements.splice(elementIndex, 1);
      this.allRowsExpanded = false;
    } else {
      this.expandedElements.push(element);

      if (this.expandedElements.length === this.dataSource.connect().value.length) {
        this.allRowsExpanded = true;
      }
    }
  }

  toggleAllExpanded() {
    if (this.expandedElements.length === this.dataSource.connect().value.length) {
      this.expandedElements = [];
      this.allRowsExpanded = false;
    } else {
      this.expandedElements = [...this.dataSource.connect().value];
      this.allRowsExpanded = true;
    }
  }

  filterStorageLocations() {
    this.storageLocationSearchTerm = this.storageLocationFilterCtrl.value ?? '';

    this.storageLocationService.loadLocations('?page=1&limit=100&search=' + this.storageLocationSearchTerm.toLowerCase())
      .pipe(
        map(response => {
          return {
            ...response,
            items: response.items.filter(storageLocation => storageLocation.id !== this.selectedStorageLocation.value)
          }
        }),
      )
      .subscribe(
      res => {
        this.filteredStorageLocations.next(res.items);
      }
    );
  }
}
