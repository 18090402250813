import {Component} from "@angular/core";
import {ColumnConfig} from "../../reusable-components/data-table/column-config";
import {Trunk} from "@knust/api-interfaces";
import {Router} from "@angular/router";
import {getCompletedFilter} from "../+state/trunk.selectors";
import {changeCompletedFilter} from "../+state/trunk.actions";
import {AuthService} from "../../utility/services/auth.service";
import {Store} from "@ngrx/store";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {trunkColumnConfig, trunkCommandMap, trunkDisplayedColumns} from "../trunks-table";

@UntilDestroy()
@Component({
  selector: 'knust-trunks-index',
  templateUrl: './trunks-index.component.html',
  styleUrls: ['./trunks-index.component.scss'],
})
export class TrunksIndexComponent {
  displayedColumns = [...trunkDisplayedColumns];
  tableConfig: ColumnConfig[] = trunkColumnConfig;
  commandMap = trunkCommandMap;

  showCompleted = false;

  constructor(private router: Router, private authService: AuthService, private store: Store) {
    if (this.authService.isAdmin() || this.authService.isPurchasing()) {
      this.displayedColumns.push('createdByUser');
    }

    this.store.select(getCompletedFilter).pipe(
      untilDestroyed(this)
    ).subscribe(completedFilter => {
      console.log(completedFilter);
      this.showCompleted = completedFilter;
    });
  }

  navigateToTrunk(trunk: Trunk) {
    this.router.navigate([this.router.url, trunk.id])
  }

  setCompletedFilter() {
    this.store.dispatch(changeCompletedFilter());
  }
}
