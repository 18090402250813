import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";

import {UsersRoutingModule} from "./users-routing.module";
import {UsersComponent} from "./users.component";
import {StoreModule} from "@ngrx/store";
import * as fromUser from "./+state/user.reducer";
import {EffectsModule} from "@ngrx/effects";
import {UserEffects} from "./+state/user.effects";
import {EditUserFormComponent} from "./edit-user-form/edit-user-form.component";
import {ReactiveFormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatSelectModule} from "@angular/material/select";
import {EditUserDialogComponent} from "./edit-user-dialog/edit-user-dialog.component";
import {DataTableModule} from "../reusable-components/data-table/data-table.module";
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
  declarations: [
    UsersComponent,
    EditUserFormComponent,
    EditUserDialogComponent,
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    StoreModule.forFeature(fromUser.USER_FEATURE_KEY, fromUser.reducer),
    EffectsModule.forFeature([UserEffects]),
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    DataTableModule,
    MatDialogModule
  ],
})
export class UsersModule {}
