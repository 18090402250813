<h1 mat-dialog-title>Artikelcode scannen</h1>
<div mat-dialog-content>
  <div class="scanner-container">
    <div class="scanner-overlay" *ngIf="showOverlay">
      <button mat-icon-button (click)="torch = !torch"
              *ngIf="torchCompatible"
              class="scanner-overlay-torch-button">
        <mat-icon>{{torch ? 'flash_off' : 'flash_on'}}</mat-icon>
      </button>

      <div class="scanner-overlay-stroke"></div>
    </div>

    <zxing-scanner [formats]="allowedFormats"
                   [torch]="torch"
                   (deviceChange)="currentDevice = $event?.deviceId ?? undefined"
                   [device]="(selectedCamera | async) ?? undefined"
                   [enable]="cameras.length > 0"
                   (camerasFound)="foundCameras($event)"
                   (autostarted)="showOverlay = true"
                   (permissionResponse)="!$event ? permissionsDenied() : ''"
                   (camerasNotFound)="createErrorMessage()"
                   (scanError)="createErrorMessage()"
                   (torchCompatible)="torchCompatible = $event"
                   (scanSuccess)="scanSuccess($event)">
    </zxing-scanner>
  </div>

  <p>
    Für einen erfolgreichen Scan: Gerät gerade halten, möglichst wenig bewegen und auf ausreichende Belichtung achten.
  </p>

  <mat-form-field appearance="fill" class="camera-dropdown">
    <mat-label>Kamera</mat-label>
    <mat-select [value]="currentDevice" (selectionChange)="setSelectedCamera($event.value)">
      <mat-option *ngFor="let camera of cameras; index as i"
                  [value]="camera.deviceId">
        {{camera?.label}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="this.dialogRef.close()">Abbrechen</button>
</div>
