<knust-data-table [disableNewButton]="!bookingType || completed || (bookingType | async) !== BookingTypeEnum.ADD"
                  newButtonText="Noch nicht vorhandenen Artikel zubuchen"
                  [noDataText]="(filterForId | async) !== null
                  ? 'Es wurden keine Artikel für diesen Lagerort gefunden.'
                  : 'Bitte wählen Sie zunächst einen Buchungstyp und einen Lagerort aus.'"
                  (addEntityClick)="addStorageLocationArticle()"
                  [disableFilterblock]="(filterForId | async) === null"
                  [completed]="completed"
                  [selectedStorageLocation]="filterForId"
                  [initialStorageLocations]="storageLocations"
                  [bookingType]="bookingType"
                  [selectedArticles]="selectedArticles"
                  (submitBookingsClick)="submitBookingsClick.emit($event)"
                  [commandMap]="commandMap"
                  [columnConfig]="tableConfig"
                  [displayedColumns]="displayedColumns">
</knust-data-table>
