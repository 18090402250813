import {Injectable} from "@angular/core";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {Store} from "@ngrx/store";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";

import * as UserActions from "./user.actions";
import {getSelected, getUserQuery} from "./user.selectors";
import {onNavigation} from "../../+state/on-navigation.operator";
import {UserService} from "../../utility/services/user.service";
import {UsersComponent} from "../users.component";
import {TrashComponent} from "../../trash/trash.component";
import {getTrashState} from "../../+state/root.selectors";
import {StoreRootState} from "../../+state/root.reducer";

@Injectable()
export class UserEffects {
  userIndex$ = createEffect(() =>
    this
      .actions$.pipe(
      onNavigation(UsersComponent),
      map( () => UserActions.loadUsers())
    )
  );

  userTrash$ = createEffect(() =>
    this
      .actions$.pipe(
      onNavigation(TrashComponent),
      map( () => UserActions.loadUsers())
    )
  );

  selectUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.setSelectedUser),
        concatLatestFrom(() => this.store.select(getSelected)),
        switchMap(([action, selected]) => {
          if (!selected) {
            return this.userService.loadUsers().pipe(
              map(res => UserActions.loadUserDetailSuccess({ user: res.items[0] }))
            );
          }

          return of(UserActions.loadUserDetailUnneeded());
        }),
        catchError((error) => {
          return of(UserActions.loadUsersFailure({ error }));
        })
      )
  );

  loadUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.loadUsers),
        concatLatestFrom(() => [
          this.store.select(getUserQuery),
          this.store.select(getTrashState)
        ]),
        switchMap(([action, query, trashState]) => {
          let completeQuery = query;
          completeQuery += '&deleted=' + trashState;

          return this.userService.loadUsers('/' + completeQuery).pipe(
            tap(() => console.log('Store Load Complete')),
            map(res => UserActions.loadUsersSuccess({ user: res.items, total: res.meta.totalItems })),
          );
        }),
        catchError((error) => {
          return of(UserActions.loadUsersFailure({ error }));
        })
      )
  );

  setUserFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        UserActions.setUserPage,
        UserActions.setUserTerm,
        UserActions.setUserSort
      ),
      map(() => UserActions.loadUsers())
    )
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.createUser),
      switchMap((action) => {
        const data = {...action.user};

        return this.userService.saveUser(data).pipe(
          map(created =>
            UserActions.saveUserSuccess( { user: created, insert: true })
          ),
          catchError(error => {
            return of(UserActions.saveUserFailure({ error }));
          })
        )
      })
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      switchMap((action) => {
        return this.userService.saveUser(action.user).pipe(
          map(updated => {
              return UserActions.saveUserSuccess({user: updated, insert: false})
            }
          ),
          catchError(error => {
            return of(UserActions.saveUserFailure({error}));
          })
        )
      })
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteUser),
      switchMap((action) => {
        return this.userService.deleteUser(action.user).pipe(
          map(() =>
            UserActions.deleteUserSuccess( { id: action.user.id ?? 0 })
          ),
          catchError(error => {
            return of(UserActions.deleteUserFailure({ error }));
          })
        )
      })
    )
  );

  restoreUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.restoreUser),
      switchMap((action) => {
        return this.userService.restoreUser(action.element).pipe(
          map(response =>
            UserActions.restoreUserSuccess( { user: response })
          ),
          catchError(error => {
            return of(UserActions.restoreUserFailure({ error }));
          })
        )
      })
    )
  );

  constructor(private readonly actions$: Actions,
              private userService: UserService,
              private store: Store<StoreRootState>) {
  }
}
