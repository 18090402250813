import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {ArticlesIndexComponent} from "./articles-index/articles-index.component";
import {ArticlesDetailsComponent} from "./articles-details/articles-details.component";

const routes: Routes = [
  {
    path: '',
    component: ArticlesIndexComponent
  },
  {
    path: ':articleId',
    component: ArticlesDetailsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArticlesRoutingModule { }
