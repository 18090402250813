<h1 mat-dialog-title>{{'Kategorie ' + (data ? 'editieren' : 'erstellen')}}</h1>
<form mat-dialog-content [formGroup]="categoryForm" (keydown.enter)="save()">
  <mat-form-field appearance="fill">
    <mat-label>Name</mat-label>
    <input matInput formControlName="label">
  </mat-form-field>
</form>
<div mat-dialog-actions>
  <button mat-flat-button (click)="cancel()">Abbrechen</button>
  <button mat-flat-button color="primary" (click)="save()">Bestätigen</button>
</div>
