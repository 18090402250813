import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatedResponse} from "../interfaces/paginated-response";
import {VendorArticle} from "@knust/api-interfaces";
import {VENDOR_ARTICLES_URL} from "../constants/api.constants";
import {Store} from "@ngrx/store";

@Injectable({
  providedIn: 'root'
})
export class VendorArticleService {

  constructor(private http: HttpClient,
              private store: Store) {}

  loadVendorArticles(query?: string): Observable<PaginatedResponse<VendorArticle>> {
    return this.http.get<PaginatedResponse<VendorArticle>>(VENDOR_ARTICLES_URL + (query ?? ''))
  }

  loadVendorArticleDetails(id: number | string): Observable<VendorArticle> {
    return this.http.get<VendorArticle>(VENDOR_ARTICLES_URL + '/' + id);
  }

  saveVendorArticle(vendorArticle: VendorArticle | Omit<VendorArticle, 'id'>): Observable<VendorArticle> {
    return this.http.post<VendorArticle>(VENDOR_ARTICLES_URL, vendorArticle);
  }

  deleteVendorArticle(vendorArticle: VendorArticle) {
    return this.http.delete(VENDOR_ARTICLES_URL + '/' + vendorArticle.id);
  }

  restoreVendorArticle(vendorArticle: VendorArticle): Observable<VendorArticle> {
    return this.http.patch<VendorArticle>(VENDOR_ARTICLES_URL + '/' + vendorArticle.id, {});
  }
}
