import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {GetNestedObjectPipe} from "./get-nested-object.pipe";
import {GetNestedNamePipe} from "./get-nested-name.pipe";
import {GetUserByIdPipe} from "./get-user-by-id.pipe";


@NgModule({
  declarations: [
    GetNestedObjectPipe,
    GetNestedNamePipe,
    GetUserByIdPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    GetNestedObjectPipe,
    GetNestedNamePipe,
    GetUserByIdPipe,
    GetUserByIdPipe
  ]
})
export class PipesModule { }
